import moment from 'moment';
import { Milliseconds } from 'app/constants/Enumerators';

export const cnpjMask = new RegExp(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);

export const cnpjFormat = (cnpj) => cnpj?.replace(cnpjMask, '$1.$2.$3/$4-$5');

/**
 * e.g: 3000 -> 3.000
 */
export const numberMask = new RegExp(/\B(?=(\d{3})+(?!\d))/g);

export const moneyFormat = (currency) => (value) =>
  `${currency} ${value}`.replace('.', ',').replace(numberMask, '.');

export const durationFormat = (startDate, finishedAt) => {
  const startDuration = moment(startDate);
  const endDuration = moment(finishedAt);

  const diff = endDuration.diff(startDuration);
  const duration = moment.duration(diff);

  const {
    _data: { days, hours, minutes, seconds },
    _milliseconds: milliseconds,
  } = duration;

  const format = [];

  if (days > 0) {
    format.push(`${days}d`);
  }

  if (hours > 0) {
    format.push(`${hours}h`);
  }

  // eg: 1h 25s -> 1h 0m 25s
  // In this example it is possible
  // for the minute to be equal to zero,
  // but it is necessary to show it to keep the pattern
  if (milliseconds >= Milliseconds.ONE_MINUTE) {
    format.push(`${minutes}m`);
  }

  format.push(`${seconds}s`);

  return format.join(' ');
};
