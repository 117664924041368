import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18next';

// External libs
import XLSX from 'xlsx';

// External components
import { message, Modal, Spin } from 'antd';

// Services
import { downloadUpload } from 'app/services/UploadService';

// Utils
import { extractFileNameFromContentDisposition } from 'app/utils/string';

// Styles
import './ModalXlsx.scss';
import Luckysheet from '../Luckysheet';

function ModalXlsx({ _id, title, cnpj, uploadType, isVisible, onClose }) {
  // States
  const [fileName, setFileName] = useState(null);
  const [fetching, setFetching] = useState();
  const [spreedsheetData, setSpreedsheetData] = useState(null);
  const [spreedsheetOptions, setSpreedsheetOptions] = useState(null);

  useEffect(() => {
    if (!_id || !cnpj || !uploadType || !isVisible) {
      return;
    }

    downloadUploadFile();
  }, [cnpj, uploadType, isVisible]);

  useEffect(() => {
    if (!spreedsheetData) {
      return;
    }

    const documentName = getModalTitle();
    const luckysheetOptions = {
      container: 'luckysheet',
      data: spreedsheetData,
      sheetName: getModalTitle(),
      documentName: `Visualizar arquivo: "${documentName}"`,
    };

    setSpreedsheetOptions(luckysheetOptions);
  }, [spreedsheetData]);

  async function downloadUploadFile() {
    setFetching(true);

    try {
      const { data, headers } = await downloadUpload(_id);
      setFileName(
        extractFileNameFromContentDisposition(headers['content-disposition']),
      );

      const wb = XLSX.read(data, { type: 'array' });
      const aoa = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
        header: 1,
        defval: '',
      });

      setSpreedsheetData(aoa);
    } catch (error) {
      message.error(i18n.t('Ocorreu um erro ao carregar arquivo.'));
      onClose();
    } finally {
      setFetching(false);
    }
  }

  function getModalTitle() {
    return fileName ? `${title}: ${fileName}` : title;
  }

  function onCancel() {
    setSpreedsheetOptions(null);
    onClose && onClose();
  }

  return (
    <Modal
      title={getModalTitle()}
      width="100vw"
      style={{
        margin: 0,
        top: 0,
        padding: 0,
        maxWidth: '100vw',
        height: '100vh',
      }}
      className="modal-xlsx"
      visible={isVisible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
    >
      <Spin spinning={fetching} tip={i18n.t('Carregando arquivo...')}>
        {spreedsheetOptions && <Luckysheet options={spreedsheetOptions} />}
      </Spin>
    </Modal>
  );
}

ModalXlsx.propTypes = {
  _id: Proptypes.string,
  title: Proptypes.string,
  cnpj: Proptypes.string,
  uploadType: Proptypes.string,
  isVisible: Proptypes.bool.isRequired,
  onClose: Proptypes.func.isRequired,
};

ModalXlsx.defaultProps = {
  _id: '',
  title: '',
  cnpj: '',
  uploadType: '',
};

export default ModalXlsx;
