import { useContext } from 'react';

// Context
import FormContext from 'app/contexts/FormContext';

export default function useForm() {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('userForm must be used within a FormProvider');
  }

  return {
    ...context,
  };
}
