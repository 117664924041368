import Api from 'app/services/Api';
import { downloadFile } from 'app/utils/download';

export const resource = 'speds';

export function download({ id, cnpjMatriz }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/${id}/download`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function downloadAll({ cnpjMatriz, _ids }) {
  let url = `empresas/${cnpjMatriz}/${resource}/download-all`;
  if (_ids && _ids.length > 0) {
    url += `?_ids=${_ids.join('&_ids=')}`;
  }

  return Api.get(url, { responseType: 'arraybuffer' }).then(downloadFile);
}




// TODO: Is this function being used?
export function cancel(id) {
  return Api.post(`${resource}/${id}/cancel`);
}

export function getAll({ cnpjMatriz, ...params }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}`, { params });
}

/**
 * @deprecated
 */
export function downloadSpedById(id) {
  return Api.get(`${resource}/${id}/download`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

/**
 * @param {Object} params
 * @param {string} params.cnpj
 * @param {number} params.limit
 * @param {number} params.page
 * @return {Promise<>}
 */
export function getPendingSpedFiles(params) {
  return Api.get(`${resource}/pending/files`, { params });
}

/**
 * @deprecated
 * @param {Object} params
 * @param {string} params.cnpj
 * @param {string} params.file File name
 * @return {Promise<>}
 */
export function removePendingSpedFiles(params) {
  return Api.delete(`${resource}/pending/files`, { params });
}

/**
 * @deprecated
 * @param {Object} params
 * @param {string[]} params.cnpjs
 * @param {boolean} params.truncate
 * @param {string[]} params.ids
 * @return {Promise<>}
 */
export function removeProcessedSpeds(params) {
  return Api.delete(resource, { params });
}
