import React, { useContext, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18next';
import fileSaver from 'file-saver';

// External components
import { Card, message, Dropdown, Menu } from 'antd';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Icons
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import {
  FiDownloadCloud,
  FiMoreHorizontal,
  FiMoreVertical,
} from 'react-icons/fi';
import { HiOutlineExternalLink } from 'react-icons/hi';

// Services
import { getTemplates } from 'app/services/TemplatesService';

// Constants
import { mimesType } from 'app/constants/MimeTypesConstants';

// Services
import { getAuthToken } from 'app/services/Api';

// Components
import Uploader from 'app/components/Uploader';

// Styles
import './UploadCard.scss';
import Button from 'antd/es/button';

function UploadCard({
  title,
  buttonDownload,
  downloadFileName,
  onDownload,
  uploadType,
  ...uploaderProps
}) {
  const context = useContext(GlobalContext);
  const {
    downloadTemplateState: { setLoadingDownloadTemplate },
  } = context;

  // States
  const [visibleMenu, setVisibleMenu] = useState(false);

  async function onClickDownloadTemplate() {
    setLoadingDownloadTemplate(true);

    try {
      const data = await getTemplates({ nameTemplate: downloadFileName });
      const blob = new Blob([data], { type: mimesType.XLSX });
      fileSaver.saveAs(blob, `${downloadFileName}.xlsx`);
    } catch (err) {
      message.error(i18n.t('Erro ao tentar baixar o arquivo modelo'));
    } finally {
      setLoadingDownloadTemplate(false);
    }
  }

  function renderDropdown() {
    return (
      <Menu className="upload-card-menu" onClick={() => setVisibleMenu(false)}>
        <Menu.Item
          key="download-template"
          icon={<BsFileEarmarkSpreadsheet size={16} />}
          onClick={onClickDownloadTemplate}
        >
          {i18n.t('Baixar planilha modelo')}
        </Menu.Item>

        {onDownload && (
          <Menu.Item
            key="view"
            icon={<HiOutlineExternalLink size={16} />}
            onClick={() => onDownload({ uploadType })}
          >
            {i18n.t('Visualizar')}
          </Menu.Item>
        )}

        {onDownload && (
          <Menu.Item
            key="download"
            icon={<FiDownloadCloud size={16} />}
            onClick={() => onDownload({ uploadType, saveFile: true })}
          >
            {i18n.t('Baixar')}
          </Menu.Item>
        )}
      </Menu>
    );
  }

  function renderHeaderCard() {
    return (
      <>
        <span>{title}</span>

        {buttonDownload && (
          <div className="download-template">
            <Dropdown
              overlay={renderDropdown()}
              trigger={['click']}
              visible={visibleMenu}
              onVisibleChange={setVisibleMenu}
            >
              <Button>
                {visibleMenu ? (
                  <FiMoreVertical size={14} />
                ) : (
                  <FiMoreHorizontal size={14} />
                )}
              </Button>
            </Dropdown>
          </div>
        )}
      </>
    );
  }

  const headers = {
    Authorization: `Bearer ${getAuthToken()}`,
  };

  return (
    <Card className="upload-card" title={renderHeaderCard()}>
      <Uploader headers={headers} {...uploaderProps} />
    </Card>
  );
}

UploadCard.propTypes = {
  onDownload: Proptypes.func,
  uploadType: Proptypes.string,
  title: Proptypes.string.isRequired,
  uploadingUrl: Proptypes.string,
  fileName: Proptypes.string,
  maxFileSizeInMega: Proptypes.number,
  multiple: Proptypes.bool,
  showUploadList: Proptypes.bool,
  filesAccepted: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      mimeType: Proptypes.string.isRequired,
    }),
  ),
  buttonDownload: Proptypes.bool,
  downloadFileName: Proptypes.string,
};

UploadCard.defaultProps = {
  onDownload: null,
  uploadingUrl: process.env.REACT_APP_API_URL,
  fileName: 'file',
  maxFileSizeInMega: 10,
  multiple: true,
  showUploadList: true,
  uploadType: null,
  filesAccepted: [
    {
      label: '.xls',
      mimeType: 'application/vnd.ms-excel',
    },
    {
      label: '.xlsx',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  ],
  buttonDownload: false,
  downloadFileName: '',
};

export default UploadCard;
