import { useContext } from 'react';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

export default function useAuth() {
  const context = useContext(GlobalContext);
  const { permissionsState } = context;

  if (!permissionsState) {
    throw new Error('usePermissions must be used within a GlobalProvider');
  }

  return {
    ...permissionsState,
  };
}
