import React from 'react';
import Proptypes from 'prop-types';

// Components
import Table from 'app/components/Table';

// Styles
import './DynamicTable.scss';

export const ignoreObjectKeysDefault = [
  '__v',
  '_id',
  'id',
  'type',
  'msg',
  'startedAt',
  'finishedAt',
];

function DynamicTable({
  dataSource,
  ignoreObjectKeys,
  customColumns,
  columnsToCustomFormat,
  className,
  ...props
}) {
  const columns = dataSource?.[0] ? buildColumns(dataSource?.[0]) : [];

  function renderContent(data, prop) {
    // Formats content based on columnsToCustomFormat fn
    if (columnsToCustomFormat && columnsToCustomFormat[prop]) {
      return columnsToCustomFormat[prop](data);
    }

    if (Array.isArray(data)) {
      return data.join(', ');
    }

    return data;
  }

  function getColumnModel(prop) {
    return {
      title: prop,
      dataIndex: prop,
      key: prop,
      filter: true,
      className: `cell-${prop}`,
      render: (data) => renderContent(data, prop),
    };
  }

  /**
   * Remove property
   * @param {string} key Object key
   * @return {boolean}
   */
  function filterIgnoreKeys(key) {
    return !ignoreObjectKeys.includes(key);
  }

  /**
   * Build the columns based on the object model and ignore props
   *
   * @param {Object} model
   * @return {{dataIndex: *, title: *, key: *, render: function(*=): *}[]}
   *
   * @example
   * let columns = buildColumns({ city: 'São Paulo', country: 'Brazil' })
   * // Result:
   * // [
   * //   { title: 'city', dataIndex: 'city', key: 'city', render() }
   * //   { title: 'country', dataIndex: 'country', key: 'country', render() }
   * // ]
   * @example
   * // With ignoreObjectKeys = ['_id']
   * let columns = buildColumns({ _id: 1, city: 'São Paulo' })
   * // Result:
   * // [
   * //   { title: 'city', dataIndex: 'city', key: 'city', render() }
   * // ]
   */
  function buildColumns(model) {
    return [
      ...Object.keys(model).filter(filterIgnoreKeys).map(getColumnModel),
      ...customColumns,
    ];
  }

  const componentClasses = ['dynamic-table', className || ''].join(' ');

  return (
    <Table
      className={componentClasses}
      columns={columns}
      dataSource={dataSource}
      {...props}
    />
  );
}

DynamicTable.propTypes = {
  dataSource: Proptypes.arrayOf(Proptypes.shape({})),
  ignoreObjectKeys: Proptypes.arrayOf(Proptypes.string),
  className: Proptypes.string,
  customColumns: Proptypes.arrayOf(Proptypes.shape({})),
  columnsToCustomFormat: Proptypes.shape({}),
};

DynamicTable.defaultProps = {
  dataSource: [],
  customColumns: [],
  columnsToCustomFormat: null,
  ignoreObjectKeys: ignoreObjectKeysDefault,
  className: '',
};

export default DynamicTable;
