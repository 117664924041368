import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import { Link, useNavigate } from 'react-router-dom';

// External Components
import { Card, message, Spin, Button, Tag } from 'antd';

// Styles
import './Usuarios.scss';

// Constants
import { UserStatusConfig } from 'app/constants/AuthConstants';

// Service
import { getAll } from 'app/services/UsuariosService';

// Components
import SubHeader from 'app/components/Subheader';
import RowActionButton from 'app/components/RowActionButton';
import Table from 'app/components/Table';
import ModalRemoveUsuario from 'app/components/ModalRemoveUsuario';

// Hooks
import useAuth from 'app/hooks/useAuth/useAuth';

function Usuarios() {
  const navigate = useNavigate();
  const { isAdmin } = useAuth()

  // State
  const [currentUsuario, setCurrentUsuario] = useState(null);
  const [usuarios, setUsuarios] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [showModalRemoveUsuario, setShowModalRemoveUsuario] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchUsuarios();
  }, [currentPage]);

  async function fetchUsuarios() {
    setFetching(true);

    try {
      const params = { skip: currentPage - 1, limit: pageSize };
      const { data } = await getAll({ params });
      const { results, count } = data;

      setUsuarios(results || []);
      setTotalItems(count || 0);
    } catch (error) {
      message.error('Erro ao buscar usuários');
    } finally {
      setFetching(false);
    }
  }

  function handleEdit(usuario) {
    navigate(`/usuarios/editar/${usuario._id}`);
  }

  function handleCreate() {
    navigate('/usuarios/criar');
  }

  function toggleModalRemoveUsuario(status) {
    setShowModalRemoveUsuario(status);
  }

  async function refetchAfterUpdate() {
    if (currentPage === 1) {
      await fetchUsuarios();
    } else {
      // executa o fetch através do useState
      setCurrentPage(1);
    }
  }

  async function afterRemoveUsuario() {
    refetchAfterUpdate();
  }

  function openRemoveUsuario(usuario) {
    setCurrentUsuario(usuario);
    setShowModalRemoveUsuario(true);
  }

  function onChangeTable(paginate) {
    let page = paginate.current;

    if (paginate.pageSize !== pageSize) {
      page = 1;
    }

    setPageSize(paginate.pageSize);
    setCurrentPage(page);
  }

  function renderHeaderActions() {
    return (
      <Button
        className="create-user-button uppercase"
        htmlType="button"
        type="primary"
        onClick={handleCreate}
        disabled={!isAdmin}
      >
        {i18n.t('Criar usuário')}
      </Button>
    );
  }

  const columns = [
    {
      title: i18n.t('Nome'),
      dataIndex: 'name',
      key: 'name',
      width: 250,
      filter: true,
      render: (nome, row) => (
        <Link to={`/usuarios/editar/${row._id}`}>{nome}</Link>
      ),
    },
    {
      title: i18n.t('Email'),
      dataIndex: 'email',
      key: 'email',
      width: 250,
      filter: true,
    },
    {
      title: i18n.t('Permissão'),
      dataIndex: 'roles',
      key: 'roles',
      width: 150,
      render: (roles) => roles.map((role) => <Tag key={role}>{role}</Tag>),
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => {
        const { text, color } = UserStatusConfig[status];
        return <Tag color={color || 'default'}>{text || status}</Tag>;
      },
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      width: 50,
      fixed: 'right',
      render: (data, row) => (
        <RowActionButton
          disabledEdit={!isAdmin}
          disabledRemove={!isAdmin}
          onClickEdit={() => handleEdit(row)}
          onClickRemove={() => openRemoveUsuario(row)}
        />
      ),
    },
  ];

  function renderTotalItems() {
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} usuários', { totalItems })
        : i18n.t('{{totalItems}} usuário', { totalItems });

    return <span>{label}</span>;
  }

  return (
    <Card className="usuarios">
      <Spin spinning={fetching}>
        <SubHeader title={i18n.t('Usuários')} prefix={renderHeaderActions()} />

        <Table
          columns={columns}
          dataSource={usuarios}
          rowKey="_id"
          isLoading={fetching}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalItems,
            showTotal: renderTotalItems,
          }}
          onChange={onChangeTable}
        />

        <ModalRemoveUsuario
          usuario={currentUsuario}
          isVisible={showModalRemoveUsuario}
          toggleVisible={toggleModalRemoveUsuario}
          afterRemove={() => afterRemoveUsuario()}
        />
      </Spin>
    </Card>
  );
}

export default Usuarios;
