import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { message, Divider } from 'antd';

// Components
import UploadCard from 'app/components/UploadCard';
import FileScheduleTable from 'app/components/FileScheduleTable';

// Styles
import './CrudFiles.scss';

function CrudFiles({ matriz, type, getFiles, cancel, download, uploadConfig }) {
  // States
  const [files, setFiles] = useState([]);
  const [fetchingFiles, setFetchingFiles] = useState(false);
  // const [currentFiles, setCurrentFiles] = useState(null);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    if (matriz?.cnpj) {
      fetchFiles(getRequestParams(pageSize, currentPage));
    }
  }, [matriz]);

  async function fetchFiles(params) {
    setFetchingFiles(true);

    try {
      const { data } = await getFiles(params);
      setFiles(data?.results || []);
      setTotalItems(data?.count || 0);
    } catch (error) {
      message.error(i18n.t('Erro ao listar os arquivos'));
    } finally {
      setFetchingFiles(false);
    }
  }

  function getRequestParams(limit, current) {
    const skip = (current - 1) * limit;

    return {
      cnpjMatriz: matriz?.cnpj,
      type,
      limit,
      skip,
    };
  }

  async function afterUpdateItem() {
    setCurrentPage(1);
    // setSelectedRows([]);
    // setSelectedRowKeys([]);
    await fetchFiles(getRequestParams(pageSize, 1));
  }

  function getUploadConfig() {
    const defaultConfig = {
      title: i18n.t('Upload de arquivos'),
      uploadingUrl: '/upload',
      maxFileSizeInMega: 1024,
    };

    return {
      ...defaultConfig,
      ...uploadConfig,
      onSuccess: afterUpdateItem,
    };
  }

  // function renderTotalItems() {
  //   const label =
  //     totalItems > 1
  //       ? i18n.t('{{totalItems}} arquivos', {
  //           totalItems,
  //         })
  //       : i18n.t('{{totalItems}} arquivo', {
  //           totalItems,
  //         });

  //   return <span className="total-items">{label}</span>;
  // }

  function getRows() {
    const rows = [...files];

    // Add key in the rows
    rows?.forEach((row, index) => {
      row.key = index;
    });

    return rows;
  }

  const rowSelection = {
    // selectedRowKeys,
    onChange: (keysRows, rows) => {
      // setSelectedRowKeys(keysRows);
      // setSelectedRows(rows);
    },
  };

  async function onChangeTable(current, _pageSize) {
    setPageSize(_pageSize);
    setCurrentPage(current);

    await fetchFiles(getRequestParams(_pageSize, current));
  }

  return (
    <div className="crud-files">
      {uploadConfig && <UploadCard {...getUploadConfig()} />}

      <Divider />

      <FileScheduleTable
        files={files}
        cancel={cancel}
        download={download}
        loading={fetchingFiles}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalItems,
          onChange: onChangeTable,
        }}
        refetch={() => fetchFiles(getRequestParams(pageSize, currentPage))}
        onCancel={() => fetchFiles(getRequestParams(pageSize, currentPage))}
      />
    </div>
  );
}

CrudFiles.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
  }).isRequired,
  type: Proptypes.arrayOf(Proptypes.string),
  uploadConfig: Proptypes.shape({
    uploadingUrl: Proptypes.string,
    title: Proptypes.string,
    additionalData: Proptypes.object,
    maxFileSizeInMega: Proptypes.number,
    filesAccepted: Proptypes.arrayOf(
      Proptypes.shape({
        label: Proptypes.string,
        mimeType: Proptypes.string,
      }),
    ),
  }),
  getFiles: Proptypes.func.isRequired,
  cancel: Proptypes.func.isRequired,
  download: Proptypes.func.isRequired,
};

CrudFiles.defaultProps = {
  uploadConfig: null,
  type: null,
};

export default CrudFiles;
