import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import i18n from 'i18n';

// External components
import { Card, message } from 'antd';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import { removeProduto, updateProduto } from 'app/services/ProdutoService';

// Components
import SubHeader from 'app/components/Subheader';
import ProcessedProdutos from 'app/components/ProcessedProdutos';
import { productsKeyFactory } from 'app/components/ProcessedProdutos/ProcessedProdutos';
import { Drawer } from './Drawer';
import { RemoveModal } from './RemoveModal';

// Styles
import './EmpresaProdutos.scss';

function EmpresaProdutos() {
  const { empresa } = useEmpresa();

  const queryClient = useQueryClient();

  const [currentProduto, setCurrentProduto] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  function openDrawer(produto) {
    setCurrentProduto(produto);
    setIsDrawerVisible(true);
  }

  function closeDrawer() {
    setIsDrawerVisible(false);
  }

  function openRemoveModal(produto) {
    setCurrentProduto(produto);
    setIsModalVisible(true);
  }

  function closeRemoveModal() {
    setIsModalVisible(false);
  }

  function invalidateProdutosQuery() {
    const queryKey = productsKeyFactory.filteredFromCompany(
      empresa?.cnpjMatriz,
    );

    queryClient.invalidateQueries(queryKey);
  }

  return (
    <Card className="empresa-produtos">
      <SubHeader title={i18n.t('Produtos')} />

      <ProcessedProdutos
        matriz={empresa}
        onEditClick={openDrawer}
        onRemoveClick={openRemoveModal}
      />

      <RemoveModal
        visible={isModalVisible}
        produto={currentProduto}
        onClose={closeRemoveModal}
        onSubmit={removeProduto}
        onSubmitSuccess={invalidateProdutosQuery}
      />

      <Drawer
        visible={isDrawerVisible}
        produto={currentProduto}
        onSubmit={updateProduto}
        onSubmitSuccess={invalidateProdutosQuery}
        onClose={closeDrawer}
      />
    </Card>
  );
}

export default EmpresaProdutos;
