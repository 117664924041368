import React from 'react';
import Proptypes from 'prop-types';

// Icon
import { CgDanger } from 'react-icons/cg';

// External components
import { Button, Space, Tooltip } from 'antd';

// Styles
import './LinkButton.scss';

function LinkButton({ suffix, prefix, onClick, messageTooltip, ...props }) {
  return (
    <Button className="link-button" type="link" onClick={onClick} {...props}>
      <Tooltip title={messageTooltip}>
        <Space size={6}>
          {prefix}
          <CgDanger size={18} />
          {suffix}
        </Space>
      </Tooltip>
    </Button>
  );
}

LinkButton.propTypes = {
  prefix: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
    Proptypes.element,
  ]),
  suffix: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.number,
    Proptypes.element,
  ]),
  onClick: Proptypes.func,
  messageTooltip: Proptypes.string,
};

LinkButton.defaultProps = {
  prefix: null,
  suffix: null,
  onClick: () => null,
  messageTooltip: '',
};

export default LinkButton;
