import Api from 'app/services/Api';

const resource = 'imports/worksheet-templates';

export function getTemplates(config) {
  return Api.get(`${resource}/download`, {
    params: config,
    responseType: 'arraybuffer',
  }).then((response) => response.data);
}
