import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Card, Space } from 'antd';

// Components
import StatisticCard, { StatisticType } from 'app/components/StatisticCard';

// Styles
import './ApuracaoOverview.scss';

function ApuracaoOverview({
  overview,
  loading,
  showTotal,
  showComplemento,
  showRessarcimento,
  showAlertas,
}) {
  const { totalRessarcimento, totalComplemento, totalAlertas } = overview;

  return (
    <Card className="apuracao-overview">
      <Space size={16} align="start">
        {showTotal && (
          <StatisticCard
            title={i18n.t('Total')}
            type={
              totalRessarcimento - totalComplemento > 0
                ? StatisticType.DEFAULT
                : StatisticType.DANGER
            }
            value={totalRessarcimento - totalComplemento}
            prefix="R$"
            precision={2}
            groupSeparator="."
            decimalSeparator=","
            loading={loading}
          />
        )}

        {showRessarcimento && (
          <StatisticCard
            title={i18n.t('Ressarcimento')}
            type={StatisticType.SUCCESS}
            value={totalRessarcimento}
            prefix="R$"
            precision={2}
            groupSeparator="."
            decimalSeparator=","
            loading={loading}
          />
        )}

        {showComplemento && (
          <StatisticCard
            type={StatisticType.DANGER}
            title={i18n.t('Complemento')}
            value={totalComplemento}
            prefix="R$"
            precision={2}
            groupSeparator="."
            decimalSeparator=","
            loading={loading}
          />
        )}

        {showAlertas && (
          <StatisticCard
            type={StatisticType.WARNING}
            title={i18n.t('Alertas')}
            value={totalAlertas}
            loading={loading}
          />
        )}
      </Space>
    </Card>
  );
}

const totalTypes = [Proptypes.number, Proptypes.string];

ApuracaoOverview.propTypes = {
  overview: Proptypes.shape({
    totalRessarcimento: Proptypes.oneOfType(totalTypes),
    totalComplemento: Proptypes.oneOfType(totalTypes),
    totalAlertas: Proptypes.oneOfType(totalTypes),
  }),
  loading: Proptypes.bool,
  showRessarcimento: Proptypes.bool,
  showComplemento: Proptypes.bool,
  showTotal: Proptypes.bool,
  showAlertas: Proptypes.bool,
};

ApuracaoOverview.defaultProps = {
  overview: Proptypes.shape({
    totalRessarcimento: 0,
    totalComplemento: 0,
    totalAlertas: 0,
  }),
  loading: false,
  showRessarcimento: true,
  showComplemento: true,
  showTotal: true,
  showAlertas: true,
};

export default ApuracaoOverview;
