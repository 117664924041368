import React, { useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// External components
import { Button, message, Upload } from 'antd';

// Services
import { importarFiliais } from 'app/services/EmpresasService';

function UploadFiliais({
  cnpjMatriz,
  filesAccepted,
  disabled,
  onSuccess,
  onError,
  ...rest
}) {
  const mimeTypes = filesAccepted.map((file) => file.mimeType).join(', ');

  // States
  const [uploading, setUploading] = useState(false);

  async function requestImportarFiliais(uploadOptions) {
    const { file } = uploadOptions;

    const data = new FormData();
    data.append('file', file);

    if (cnpjMatriz) {
      data.append('cnpj', cnpjMatriz);
    }

    setUploading(true);

    try {
      await importarFiliais(data);
      onSuccess && onSuccess();
    } catch (error) {
      message.error(
        i18n.t(`Erro ao realizar upload das filiais: ${error.response.data}`, {
          nsSeparator: false,
        }),
      );
      onError && onError();
    } finally {
      setUploading(false);
    }
  }

  const componentClasses = [
    'upload-filiais',
    disabled ? 'disabled' : '',
    uploading ? 'uploading' : '',
  ].join(' ');

  return (
    <Upload
      className={componentClasses}
      accept={mimeTypes}
      disabled={uploading || disabled}
      customRequest={requestImportarFiliais}
      {...rest}
    >
      <Button
        className="upload-filiais-button uppercase"
        loading={uploading}
        disabled={disabled}
      >
        {i18n.t('Importar filiais')}
      </Button>
    </Upload>
  );
}

UploadFiliais.propTypes = {
  cnpjMatriz: Proptypes.string,
  disabled: Proptypes.bool,
  multiple: Proptypes.bool,
  showUploadList: Proptypes.bool,
  filesAccepted: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      mimeType: Proptypes.string.isRequired,
    }),
  ),
  onSuccess: Proptypes.func,
  onError: Proptypes.func,
};

UploadFiliais.defaultProps = {
  cnpjMatriz: '',
  disabled: false,
  multiple: false,
  showUploadList: false,
  filesAccepted: [
    {
      label: 'XLS',
      mimeType: 'application/vnd.ms-excel',
    },
    {
      label: 'XLSX',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    {
      label: 'CSV',
      mimeType: 'text/csv',
    },
  ],
  onSuccess: null,
  onError: null,
};

export default UploadFiliais;
