import * as React from 'react';

import { Card, Col, Divider, List, Row, Space, Spin, Table, Tag } from 'antd';
import i18n from 'i18n';
import { useQuery } from 'react-query';

import Subheader from 'app/components/Subheader';

// Services
import * as WorkerService from 'app/services/WorkerService';
import * as ReportService from 'app/services/ReportService';
import * as ImportService from 'app/services/ImportService';
import * as ProcessoService from 'app/services/ProcessoService';

import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'

// Enums
import {
  ImportStatus,
  ProcessoStatus,
  ReportStatus,
  WorkerState,
  WorkerStateTranslated,
} from 'app/constants/Enumerators';

// Utils
import { formatFullDate } from 'app/utils/date';

import './Processos.scss';

export const workersKeyFactory = {
  all: [{ scope: 'workers' }],
};

export const processingsKeyFactory = {
  all: [{ scope: 'processings' }],
  allFromType: (type) => [{ ...processingsKeyFactory.all[0], type }],
  filteredFromType: (type, status) => [
    { ...processingsKeyFactory.allFromType(type)[0], status },
  ],
};

const pendingReportsQueryKey = processingsKeyFactory.filteredFromType(
  'reports',
  ReportStatus.PENDENTE,
);
const pendingImportsQueryKey = processingsKeyFactory.filteredFromType(
  'imports',
  ImportStatus.PENDENTE,
);
const pendingProcessosQueryKey = processingsKeyFactory.filteredFromType(
  'processos',
  ProcessoStatus.PENDENTE,
);

const columns = [
  {
    title: i18n.t('Tipo'),
    dataIndex: 'type',
  },
  {
    title: i18n.t('Agendado por'),
    dataIndex: 'user',
    render: (user) => user?.name,
  },
  {
    title: i18n.t('Agendado em'),
    dataIndex: 'createdAt',
    render: formatFullDate,
  },
  {
    title: i18n.t('Empresa'),
    dataIndex: 'empresa',
    render: (empresa) => empresa.nomeFantasia,
  },
  {
    title: i18n.t('CNPJ Matriz'),
    dataIndex: 'cnpjMatriz',
    filter: true,
  },
];

const QUERY_REFETCH_INTERVAL = 1000 * 5; // 5 seconds
const QUERY_LIMIT = 15; // Number of records in the table

function Processos() {
  const workerQuery = useQuery(workersKeyFactory.all, WorkerService.getAll, {
    refetchInterval: QUERY_REFETCH_INTERVAL,
  });

  const pendingReportsQuery = useQuery(
    pendingReportsQueryKey,
    () =>
      ReportService.getAll({
        status: ReportStatus.PENDENTE,
        attachCompanyInfo: true,
        attachUserInfo: true,
        limit: QUERY_LIMIT,
      }),
    {
      refetchInterval: QUERY_REFETCH_INTERVAL,
    },
  );

  const pendingProcessosQuery = useQuery(
    pendingProcessosQueryKey,
    () =>
      ProcessoService.getAll({
        status: ProcessoStatus.PENDENTE,
        attachCompanyInfo: true,
        attachUserInfo: true,
        limit: QUERY_LIMIT,
      }),
    {
      refetchInterval: QUERY_REFETCH_INTERVAL,
    },
  );

  const pendingImportsQuery = useQuery(
    pendingImportsQueryKey,
    () =>
      ImportService.get({
        status: ImportStatus.PENDENTE,
        attachCompanyInfo: true,
        attachUserInfo: true,
        limit: QUERY_LIMIT,
      }),
    {
      refetchInterval: QUERY_REFETCH_INTERVAL,
    },
  );

  const isQueriesLoading =
    pendingReportsQuery.isLoading ||
    pendingProcessosQuery.isLoading ||
    pendingImportsQuery.isLoading;

  function getCPUUsage(healthCheckData) {
    return `${healthCheckData.cpuUsagePercent.toFixed(2)}%`;
  }

  function getWorkerStatus(state, current) {
    const lastPing = Math.floor((new Date() - new Date(current?.lastCheck)) / 1000)
    if (lastPing > 60) {
      return (
        <Tag
          color="error"
        >
          {WorkerStateTranslated.OFFLINE}
        </Tag>
      )
    }

    return (
      <Tag
        color={
          state === WorkerState.PROCESSING
            ? 'processing'
            : 'default'
        }
      >
        {WorkerStateTranslated[state]}
      </Tag>
    )
  }

  function getHostMemoryUsage(healthCheckData) {
    const hostMemoryUsageMb = healthCheckData.hostMemoryUsageMb.toFixed(2);
    const hostMemoryUsagePercent =
      healthCheckData.hostMemoryUsagePercent.toFixed(2);

    return `${hostMemoryUsageMb}MB (${hostMemoryUsagePercent}%)`;
  }

  function getProcessMemoryUsage(healthCheckData) {
    const processMemoryUsageMb =
      healthCheckData.processMemoryUsageMb.toFixed(2);
    const processMemoryUsagePercent =
      healthCheckData.processMemoryUsagePercent.toFixed(2);

    return `${processMemoryUsageMb}MB (${processMemoryUsagePercent}%)`;
  }

  return (
    <>
      <Subheader title={i18n.t('Workers')} />

      <Row gutter={[10, 10]}>
        {workerQuery.data?.data.map(
          ({ identifier, state, healthCheckData, stateData }) => (
            <Col span={8}>
              <Card
                title={identifier}
                extra={
                  getWorkerStatus(state, healthCheckData)
                }
                bordered={false}
              >
                <div className="worker-card__content">
                  {healthCheckData && (
                    <>
                      <span>
                        <span className="worker-card__prop">
                          {i18n.t('Última atualização')}:
                        </span>{' '}
                        {formatFullDate(healthCheckData?.lastCheck)}
                      </span>

                      <div className="worker-card__health-check">
                        <span>
                          <span className="worker-card__prop">
                            {i18n.t('CPU')}:
                          </span>{' '}
                          {getCPUUsage(healthCheckData)}
                        </span>
                        <span>
                          <span className="worker-card__prop">
                            {i18n.t('Memória do host')}:
                          </span>{' '}
                          {getHostMemoryUsage(healthCheckData)}
                        </span>
                        <span>
                          <span className="worker-card__prop">
                            {i18n.t('Memória do processo')}:
                          </span>{' '}
                          {getProcessMemoryUsage(healthCheckData)}
                        </span>
                      </div>
                    </>
                  )}

                  {stateData && (
                    <>
                      <span>
                        <span className="worker-card__prop">
                          {i18n.t('Executando')}
                        </span>
                      </span>
                      <span>
                        <span className="worker-card__prop">
                          {i18n.t('Contexto')}:
                        </span>{' '}
                        {stateData?.context}
                      </span>
                      <span>
                        <span className="worker-card__prop">
                          {i18n.t('Tipo do Contexto')}:
                        </span>{' '}
                        {stateData?.contextType}
                      </span>
                      {stateData?.contextSubType && (
                        <span>
                          <span className="worker-card__prop">
                            {i18n.t('Subtipo do Contexto')}:
                          </span>{' '}
                          {stateData?.contextSubType}
                        </span>
                      )}
                      <span>
                        <span className="worker-card__prop">
                          {i18n.t('Empresa')}:
                        </span>{' '}
                        {stateData?.company?.nomeFantasia}
                      </span>
                    </>
                  )}
                </div>
              </Card>
            </Col>
          ),
        )}
      </Row>

      <Divider />

      <Card className="processos">
        <Spin spinning={isQueriesLoading}>
          <Subheader title={i18n.t('Relatórios na fila')} />

          <Table
            columns={columns}
            dataSource={pendingReportsQuery.data?.data.results}
            rowKey="_id"
            pagination={false}
          />

          <Divider />

          <Subheader title={i18n.t('Processos na fila')} />

          <Table
            columns={columns}
            dataSource={pendingProcessosQuery.data?.data.results}
            rowKey="_id"
            pagination={false}
          />

          <Divider />

          <Subheader title={i18n.t('Importações na fila')} />

          <Table
            columns={columns}
            dataSource={pendingImportsQuery.data?.data.results}
            rowKey="_id"
            pagination={false}
          />
        </Spin>
      </Card>
    </>
  );
}

export default Processos;
