import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

// Hooks
import useAuth from 'app/hooks/useAuth';

function PrivateRoute() {
  const location = useLocation();
  const { signed } = useAuth();

  if (signed) {
    return <Outlet />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export default PrivateRoute;
