import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Form, Checkbox } from 'antd';

function CheckboxFilter({ formGroup, filter }) {
  const { id, label, required } = filter;

  return (
    <Form.Item
      name={[...formGroup, id]}
      valuePropName="checked"
      rules={[{ required, message: i18n.t('Campo obrigatório') }]}
    >
      <Checkbox>{label || id}</Checkbox>
    </Form.Item>
  );
}

CheckboxFilter.propTypes = {
  filter: Proptypes.shape({
    id: Proptypes.string.isRequired,
    type: Proptypes.string.isRequired,
    label: Proptypes.string,
    required: Proptypes.bool,
  }).isRequired,
  formGroup: Proptypes.arrayOf(Proptypes.string),
};

CheckboxFilter.defaultProps = {
  formGroup: [],
};

export default CheckboxFilter;
