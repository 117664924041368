import Api from 'app/services/Api';

const resource = 'imports';

export function getAll(params) {
  return Api.get(`${resource}`, { params });
}

export function getUploadTypes() {
  return Api.get(`${resource}/worksheets/types`);
}

export function downloadTemplate({ nameTemplate }) {
  return Api.get(`${resource}/worksheet-templates/download`, {
    params: { nameTemplate },
    responseType: 'arraybuffer',
  });
}

export function downloadUpload(_id) {
  return Api.get(`${resource}/${_id}/download`, {
    responseType: 'arraybuffer',
  });
}

export function cancelUpload(uploadId) {
  return Api.post(`${resource}/${uploadId}/cancel`);
}
