import i18n from 'i18next';

export const MESSAGE_MAX_COUNT = 5;

export const ViewApuracaoBy = {
  CNPJ: 'CNPJ',
  PRODUTO: 'PRODUTO',
};

export const ViewApuracaoByList = [
  {
    key: ViewApuracaoBy.CNPJ,
    label: i18n.t('CNPJ'),
  },
  {
    key: ViewApuracaoBy.PRODUTO,
    label: i18n.t('Produto'),
  },
];

export const ViewSpedBy = {
  PROCESSED: 'PROCESSED',
  PENDING: 'PENDING',
};

export const ViewSpedByList = [
  {
    key: ViewSpedBy.PROCESSED,
    label: i18n.t('Processados'),
  },
  {
    key: ViewSpedBy.PENDING,
    label: i18n.t('Pendentes'),
  },
];

export const ViewRegFiscalBy = {
  PROCESSED: 'PROCESSED',
  PENDING: 'PENDING',
};

export const ViewRegFiscalByList = [
  {
    key: ViewSpedBy.PROCESSED,
    label: i18n.t('Processados'),
  },
  {
    key: ViewSpedBy.PENDING,
    label: i18n.t('Pendentes'),
  },
];

export const ProcessStepStatus = {
  NAO_INICIADO: 'NAO_INICIADO',
  EM_ANDAMENTO: 'EM_ANDAMENTO',
  FINALIZADO: 'FINALIZADO',
  ERRO: 'ERRO',
  ABORTADO: 'ABORTADO',
};

export const Milliseconds = {
  ONE_MINUTE: 60000,
};

export const RegFiscalTypes = {
  ECF: 'ECF',
};

export const ReportStatus = {
  PENDENTE: 'PENDENTE',
  PROCESSANDO: 'PROCESSANDO',
  AGUARDANDO_CANCELAMENTO: 'AGUARDANDO_CANCELAMENTO',
  CONCLUIDO: 'CONCLUIDO',
  CANCELADO: 'CANCELADO',
  ERRO: 'ERRO',
};

export const ImportStatus = {
  PENDENTE: 'PENDENTE',
  PROCESSANDO: 'PROCESSANDO',
  AGUARDANDO_CANCELAMENTO: 'AGUARDANDO_CANCELAMENTO',
  CONCLUIDO: 'CONCLUIDO',
  CANCELADO: 'CANCELADO',
  ERRO: 'ERRO',
};

export const ProcessoStatus = {
  PENDENTE: 'PENDENTE',
  PROCESSANDO: 'PROCESSANDO',
  AGUARDANDO_CANCELAMENTO: 'AGUARDANDO_CANCELAMENTO',
  CONCLUIDO: 'CONCLUIDO',
  CANCELADO: 'CANCELADO',
  ERRO: 'ERRO',
};

export const WorkerState = {
  IDLE: 'IDLE',
  PROCESSING: 'PROCESSING',
};

export const WorkerStateTranslated = {
  IDLE: i18n.t('OCIOSO'),
  PROCESSING: i18n.t('PROCESSANDO'),
  OFFLINE: i18n.t('OFFLINE'),
};

export const ApuracaoStatus = {
  COM_ALERTAS: 'COM_ALERTAS',
};
