import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import PropTypes from 'prop-types';
import { Card, Spin, message } from 'antd';
import { getDoc } from 'app/services/DocService';
import { useParams } from 'react-router-dom';

// Componente CodeRenderer
const CodeRenderer = ({ node, inline, className, children, ...props }) => {
  const match = /language-(\w+)/.exec(className || '');
  return !inline && match ? (
    <SyntaxHighlighter
      {...props}
      style={oneLight}
      language={match[1]}
      PreTag="div"
    >
      {String(children).replace(/\n$/, '')}
    </SyntaxHighlighter>
  ) : (
    <code {...props} className={className}>
      {children}
    </code>
  );
};

CodeRenderer.propTypes = {
  node: PropTypes.object,
  inline: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

CodeRenderer.defaultProps = {
  node: null,
  inline: null,
  className: null,
  children: null,
};

function Documentacao() {
  const { type } = useParams();

  // State
  const [fetching, setFetching] = useState(false);
  const [docInfo, setDocInfo] = useState();

  useEffect(() => {
    fetchDoc();
  }, [type]);

  async function fetchDoc() {
    setFetching(true);

    try {
      const res = await getDoc({ type });
      setDocInfo(res.data);
    } catch (error) {
      message.error('Erro ao buscar documentação');
    } finally {
      setFetching(false);
    }
  }

  return (
    <Spin spinning={fetching} tip="Carregando documentação...">
      <Card className="documentacao">
        <div>
          <ReactMarkdown components={{ code: CodeRenderer }}>
            {docInfo}
          </ReactMarkdown>
        </div>
      </Card>
    </Spin>
  );
}

export default Documentacao;
