import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Styles
import './UploadScheduleTable.scss';

// External components
import { Button, Col, message, Row, Space, Tooltip } from 'antd';

// Enums
import { ScheduleStatusEnum } from 'app/constants/ScheduleTypesConstants';

// Icons
import { FiDownloadCloud } from 'react-icons/fi';
import {
  AiOutlineEye,
  AiOutlineStop,
  AiOutlineFileSearch,
} from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';

// Utils
import { formatFullDate } from 'app/utils/date';
import { downloadFile } from 'app/utils/download';

// Services
import { downloadUpload, cancelUpload } from 'app/services/UploadService';

// Components
import Table from 'app/components/Table';
import Subheader from 'app/components/Subheader';
import ScheduleStatusTag from 'app/components/ScheduleStatusTag';
import ModalDetalhesUpload from 'app/components/ModalDetalhesUpload';
import ModalXlsx from 'app/components/ModalXlsx';

function UploadScheduleTable({ uploads, refetch, loading, onCancel }) {
  const [upload, setUpload] = useState(null);
  const [showModalDetalhesUpload, setShowModalDetalhesUpload] = useState(false);
  const [showModalXlsx, setShowModalXlsx] = useState(false);
  const [modalXlsxConfig, setDownloadUploadConfig] = useState({
    _id: null,
    cnpj: null,
    uploadType: null,
  });

  function toggleModalDetalhesUpload(status) {
    setShowModalDetalhesUpload(status);
  }

  function onClickViewDetalhesUpload(currentUpload) {
    setUpload(currentUpload);
    toggleModalDetalhesUpload(true);
  }

  async function requestDownload(currentUpload) {
    try {
      const file = await downloadUpload(currentUpload._id);
      downloadFile(file);

      message.success(i18n.t('Arquivo baixado com sucesso'));
    } catch (error) {
      message.error(i18n.t('Erro ao baixar arquivo'));
    }
  }

  function viewUpload(currentUpload) {
    const { cnpjMatriz, params } = currentUpload;

    setDownloadUploadConfig({
      _id: currentUpload._id,
      cnpj: cnpjMatriz,
      uploadType: params?.type,
      title: params?.type,
    });

    setShowModalXlsx(true);
  }

  async function requestCancel(currentUpload) {
    try {
      await cancelUpload(currentUpload._id);
      message.success(i18n.t('Processamento de upload cancelado com sucesso'));
      onCancel?.();
    } catch (error) {
      message.error(i18n.t('Erro ao cancelar processamento de upload'));
    }
  }

  function renderMoreActions(row) {
    const canCancel = row.status === ScheduleStatusEnum.PENDENTE;

    return (
      <Space size={2}>
        <Tooltip title={i18n.t('Visualizar parâmetros')}>
          <Button
            className="view-detalhes-upload-button"
            type="link"
            size="small"
            onClick={() => onClickViewDetalhesUpload(row)}
          >
            <AiOutlineFileSearch size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Cancelar processamento')}>
          <Button
            className="process-cancel-button"
            type="link"
            size="small"
            disabled={!canCancel}
            onClick={() => requestCancel(row)}
          >
            <AiOutlineStop size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Visualizar planilha')}>
          <Button
            className="view-upload-button"
            type="link"
            size="small"
            onClick={() => viewUpload(row)}
          >
            <AiOutlineEye size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Baixar')}>
          <Button
            className="download-upload-button"
            type="link"
            size="small"
            onClick={() => requestDownload(row)}
          >
            <FiDownloadCloud size={18} />
          </Button>
        </Tooltip>
      </Space>
    );
  }

  const columns = [
    {
      title: i18n.t('Tipo de planilha'),
      dataIndex: 'type',
      key: 'type',
      render: (type, row) => (
        <Button
          className="link-text-button"
          htmlType="button"
          type="link"
          onClick={() => viewUpload(row)}
        >
          {row.params?.type}
        </Button>
      ),
    },
    {
      title: i18n.t('Arquivo a ser processado'),
      dataIndex: 'fileOriginalName',
      key: 'fileOriginalName',
    },
    {
      title: i18n.t('Atualizado em'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: formatFullDate,
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <ScheduleStatusTag status={status} />,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (data, row) => renderMoreActions(row),
    },
  ];

  return (
    <div className="report-schedule-table">
      <Subheader
        title={i18n.t('Processamentos')}
        prefix={
          <Button onClick={refetch}>
            <IoMdRefresh size={18} />
          </Button>
        }
      />

      <Row>
        <Col span={24}>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={uploads}
            isLoading={loading}
            scroll={{ x: '100%' }}
            data
          />
        </Col>
      </Row>

      <ModalDetalhesUpload
        upload={upload}
        visible={showModalDetalhesUpload}
        toggleVisible={toggleModalDetalhesUpload}
      />

      <ModalXlsx
        isVisible={showModalXlsx}
        onClose={() => setShowModalXlsx(false)}
        {...modalXlsxConfig}
      />
    </div>
  );
}

UploadScheduleTable.propTypes = {
  uploads: Proptypes.array,
  loading: Proptypes.bool,
  refetch: Proptypes.func,
  onCancel: Proptypes.func,
};

UploadScheduleTable.defaultProps = {
  uploads: [],
  loading: false,
  refetch: null,
  onCancel: null,
};

export default UploadScheduleTable;
