import i18n from 'i18n';

export const RolesEnum = {
  ADMIN: 'admin',
  USER: 'user',
};

export const UserStatusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};

export const UserStatusConfig = {
  [UserStatusEnum.ACTIVE]: {
    text: i18n.t('Ativo'),
    color: 'green',
  },
  [UserStatusEnum.INACTIVE]: {
    text: i18n.t('Inativo'),
    color: 'red',
  },
};
