import Api from 'app/services/Api';
import { downloadFile } from '../utils/download';

const resource = 'arquivos-digitais';

/**
 * @param {Object} params
 * @param {string} params.cnpj
 * @param {string} params.periodo
 * @param {string} params.cnpjMatriz
 * @return {Promise<>}
 */
export function getArquivoDigital({ cnpjMatriz, ...params }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}`, { params }).then(
    (response) => response.data,
  );
}

export function getDownloadArquivoDigital({ cnpjMatriz, ...params }, route) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/${route}`, {
    params,
    responseType: 'arraybuffer',
  }).then(downloadFile);
}
