import React, { useState, useEffect } from 'react';

// External components
import { Layout } from 'antd';

// Constants
import { CAT42_SIDEBAR_COLLAPSED } from 'app/constants/LocalStorageKeysConstants';

// Components
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

// Sidebar styles
import './Sidebar.scss';

const { Sider } = Layout;

export default function Sidebar() {
  // States
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const isCollapsed =
      window.localStorage.getItem(CAT42_SIDEBAR_COLLAPSED) === 'true';

    setCollapsed(isCollapsed);
  }, []);

  function handleToggleSidebar() {
    setCollapsed(!collapsed);
    window.localStorage.setItem(CAT42_SIDEBAR_COLLAPSED, !collapsed);
  }

  return (
    <Sider
      className="sidebar"
      width={260}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <SidebarHeader collapsed={collapsed} onClick={handleToggleSidebar} />
      <SidebarMenu collapsed={collapsed} />
    </Sider>
  );
}
