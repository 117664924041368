import Api from 'app/services/Api';

const resource = 'empresas';

export function getAll(config) {
  return Api.get(resource, config).then((response) => response.data);
}

export function getEmpresasRede(config) {
  return Api.get(`${resource}/rede`, config).then((response) => response.data);
}

export function get(id) {
  return Api.get(`${resource}/${id}`);
}

export function create(empresa) {
  return Api.post(resource, empresa);
}

export function edit({ _id, ...empresa }) {
  return Api.put(`${resource}/${_id}`, empresa);
}

export function remove(empresaId) {
  return Api.delete(`${resource}/${empresaId}`);
}

export function importarFiliais(payload) {
  return Api.post(`${resource}/importar-filiais`, payload);
}

export async function getProcesso({ empresaId, config }) {
  return Api.get(`${resource}/${empresaId}/processo`, config).then(
    (response) => response.data,
  );
}

export async function getEtapaProcesso({ empresaId, parentId }) {
  return Api.get(`${resource}/${empresaId}/processo`, { params: { parentId } });
}

/**
 * @param {Object} data
 * @param {string} data.cnpj - Matriz CNPJ
 * @param {*} data.file
 */
export function uploadFiliais(data) {
  return Api.post(`${resource}/upload`, data);
}
