import React, { useState } from 'react';
import i18n from 'i18n';

// External components
import { Button, Card, DatePicker, Form, Modal, notification } from 'antd';

// Styles
import './EmpresaSped.scss';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import SubHeader from 'app/components/Subheader';
import ProcessedSped from 'app/components/ProcessedSped';
import { ImportStatusEnum, ImportTypesEnum } from 'app/constants/ImportTypesConstants';
import { useMutation, useQuery, useQueryClient } from 'react-query';

// Services
import { get, triggerSpedReprocessing } from 'app/services/ImportService';

// Utils
import * as dateUtils from 'app/utils/date';

// TODO: Move the query logic to a separate hook
export const importsKeyFactory = {
  allFromCompany: (cnpjMatriz) => [{ scope: 'imports', cnpjMatriz }],
  filteredFromCompany: (cnpjMatriz, filters) => [
    { ...importsKeyFactory.allFromCompany(cnpjMatriz), ...filters },
  ],
};

const type = [ImportTypesEnum.SPED_REPROCESS];
const status = [ImportStatusEnum.PENDENTE, ImportStatusEnum.PROCESSANDO];
const limit = 1;

function EmpresaSped() {
  const [form] = Form.useForm();
  const { empresa } = useEmpresa();
  const { cnpjMatriz } = empresa || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const queryKey = importsKeyFactory.filteredFromCompany(cnpjMatriz, {
    type,
    limit,
    status,
  });


  const { data: result } = useQuery(
    queryKey,
    () => get({ cnpjMatriz, type, status, limit }),
    { enabled: !!cnpjMatriz, refetchOnWindowFocus: false },
  );

  const isReprocessing = result?.data?.count > 0;

  const reprocessingMutation = useMutation(
    (payload) => triggerSpedReprocessing({ cnpjMatriz, payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        notification.error({
          message: i18n.t('Erro ao iniciar reprocessamento'),
        });
      },
    },
  );

  function openModal() {
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  async function onModalConfirmation() {
    const periodoInicio = form.getFieldValue('periodoInicio');
    const periodoFim = form.getFieldValue('periodoFim');

    const payload = {}

    if (periodoInicio) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodoInicio);
    }
    if (periodoFim) {
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodoFim);
    }

    await reprocessingMutation.mutateAsync(payload);
    hideModal();
  }

  return (
    <Card className="empresa-sped">
      <SubHeader title={i18n.t('SPED')}
        prefix={
          <>
            <Button
              type="default"
              danger
              disabled={isReprocessing}
              className="uppercase"
              loading={reprocessingMutation.isLoading || isReprocessing}
              onClick={openModal}
            >
              {isReprocessing
                ? i18n.t('Reprocessando...')
                : i18n.t('Reprocessar SPED')}
            </Button>
            <Modal
              className="modal-confirmation"
              title={i18n.t('ATENÇÃO')}
              okText={i18n.t('Reprocessar SPED')}
              cancelText={i18n.t('Cancelar')}
              width={480}
              visible={isModalOpen}
              closable={!reprocessingMutation.isLoading}
              maskClosable={!reprocessingMutation.isLoading}
              confirmLoading={reprocessingMutation.isLoading}
              cancelButtonProps={{ disabled: reprocessingMutation.isLoading }}
              onOk={onModalConfirmation}
              onCancel={hideModal}
            >
              <Form form={form}>
                <div className="modal-content">
                  <p>
                    {i18n.t('Ao executar esse procedimento, os SPEDs serão reprocessados, e quaisquer retificações de cProd realizadas pelo processo RETIFICA_CPROD serão revertidas.')}
                  </p>
                  {/* <div style={{ marginTop: '24px' }}>

                    <p>Selecione o período, ou deixe vazio para restaurar todos os registros.</p>

                    <div style={{ marginBottom: '8px' }}>
                      <label htmlFor="periodoInicio">A partir de:</label>
                      <Form.Item name="periodoInicio" noStyle>
                        <DatePicker id="periodoInicio" picker="month" format="MM-YYYY" />
                      </Form.Item>
                    </div>

                    <div style={{ marginTop: '8px' }}>
                      <label htmlFor="periodoFim">Até:</label>
                      <Form.Item name="periodoFim" noStyle>
                        <DatePicker id="periodoFim" picker="month" format="MM-YYYY" />
                      </Form.Item>
                    </div>
                  </div> */}
                </div>
              </Form>

            </Modal>
          </>
        }
      />

      <ProcessedSped matriz={empresa} />
    </Card>
  );
}

export default EmpresaSped;
