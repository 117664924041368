import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Modal } from 'antd';

// Styles
import './ModalDetalhesApuracao.scss';

function ModalDetalhesApuracao({ apuracao, visible, toggleVisible }) {
  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-detalhes-apuracao"
      title={i18n.t('Detalhes do processo')}
      visible={visible}
      width={600}
      footer={null}
      onCancel={closeModal}
    >
      <div>
        <h4>{i18n.t('Parâmetros utilizados')}:</h4>
        <pre>{JSON.stringify(apuracao?.params, null, 2)}</pre>
      </div>
    </Modal>
  );
}

ModalDetalhesApuracao.propTypes = {
  visible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  apuracao: Proptypes.object,
};

ModalDetalhesApuracao.defaultProps = {
  apuracao: null,
};

export default ModalDetalhesApuracao;
