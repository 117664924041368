import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Icons
import { ImWarning } from 'react-icons/im';

// External components
import { message, Modal, Space } from 'antd';

// Styles
import './ModalRemoveFile.scss';

function ModalRemoveFile({
  files,
  params,
  isVisible,
  toggleVisible,
  afterRemove,
  removeFile,
}) {
  const [removing, setRemoving] = useState(false);

  async function handleRemove() {
    setRemoving(true);

    try {
      await removeFile({ files: files.map((row) => row.filePath), ...params });

      message.success(getRemoveSuccessMessage());

      afterRemove();
    } catch (error) {
      message.error(getRemoveFailureMessage());
    } finally {
      setRemoving(false);
      closeModal();
    }
  }

  function getRemoveSuccessMessage() {
    return files?.length > 1
      ? i18n.t('Os Arquivos foram removidos com sucesso')
      : i18n.t('Arquivo foi removido com sucesso');
  }

  function getRemoveFailureMessage() {
    return files?.length > 1
      ? i18n.t('Erro ao remover os arquivos')
      : i18n.t('Erro ao remover o arquivo');
  }

  function getRemoveConfirmationMessage() {
    if (files?.length === 1) {
      return i18n.t('Deseja realmente remover o arquivo {{nome}}?', {
        nome: files[0].filename,
      });
    }

    return i18n.t('Deseja realmente remover TODOS os arquivos?');
  }

  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-remove-file"
      title={i18n.t('Atenção')}
      okText={i18n.t('Remover')}
      cancelText={i18n.t('Cancelar')}
      width={550}
      visible={isVisible}
      closable={!removing}
      maskClosable={!removing}
      confirmLoading={removing}
      cancelButtonProps={{ disabled: removing }}
      onOk={handleRemove}
      onCancel={closeModal}
    >
      <Space size={13}>
        <ImWarning size={30} />
        {getRemoveConfirmationMessage()}
      </Space>
    </Modal>
  );
}

ModalRemoveFile.propTypes = {
  files: Proptypes.arrayOf(Proptypes.string),
  isVisible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  afterRemove: Proptypes.func.isRequired,
  removeFile: Proptypes.func.isRequired,
  params: Proptypes.shape({}),
};

ModalRemoveFile.defaultProps = {
  files: [],
  params: null,
};

export default ModalRemoveFile;
