import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';

// Icons
import { FiUser } from 'react-icons/fi';
import { AiOutlineLock } from 'react-icons/ai';

// External Components
import { Card, Checkbox, Form, Input, Row, Col, Button, message } from 'antd';

// Constants
import { CAT42_REMEMBER_LOGIN } from 'app/constants/LocalStorageKeysConstants';

// Hooks
import useForm from 'app/hooks/useForm';
import useAuth from 'app/hooks/useAuth';

// HOC
import withForm from 'app/hoc/withForm/withForm';

// Service
import { login } from 'app/services/AuthService';

// Style
import './Login.scss';

function Login() {
  const navigate = useNavigate();

  // Reference
  const emailInput = useRef();
  const passwordInput = useRef();

  // State
  const { form } = useForm();
  const { getAuth, setAuth } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const email = window.localStorage.getItem(CAT42_REMEMBER_LOGIN);

    if (auth?.token) {
      navigate('/');
    }

    if (email) {
      form.setFieldsValue({ email, remember: true });
      passwordInput.current?.focus();
    }
  }, []);

  async function handleSubmit() {
    const fields = form.getFieldsValue();
    setLoading(true);

    try {
      const { data } = await login(fields);
      setAuth(data);

      if (fields.remember) {
        window.localStorage.setItem(CAT42_REMEMBER_LOGIN, fields.email);
      }

      navigate('/');
    } catch (err) {
      const { data } = err.response

      if (data.message === 'User account is inactive') {
        message.error(i18n.t('Usuário está desativado, verifique com o administrador'))
      } else {
        message.error(i18n.t('Não foi possível efetuar o login'));
      }


    } finally {
      setLoading(false);
    }
  }

  function getTitle() {
    return (
      <div className="title-card">
        <span>Cat</span>
        <span>42</span>
      </div>
    );
  }

  return (
    <Form
      className="login-form"
      layout="vertical"
      form={form}
      onFinish={handleSubmit}
    >
      <Card title={getTitle()}>
        <Row gutter={16} align="top">
          <Col span={24}>
            <Form.Item
              name="email"
              label={i18n.t('Email')}
              required
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: i18n.t('Email é obrigatório'),
                },
              ]}
            >
              <Input
                ref={emailInput}
                autoFocus
                prefix={<FiUser size={16} color="#9a9a9a" />}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="password"
              label={i18n.t('Senha')}
              required
              rules={[
                { required: true, message: i18n.t('Senha é obrigatório') },
              ]}
            >
              <Input.Password
                ref={passwordInput}
                prefix={<AiOutlineLock size={16} color="#9a9a9a" />}
              />
            </Form.Item>
          </Col>

          <Col flex="auto">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox className="remember-checkbox">
                {i18n.t('Lembrar-me')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <Button
          className="submit-button uppercase"
          htmlType="submit"
          type="primary"
          loading={loading}
        >
          {i18n.t('Entrar')}
        </Button>
      </Card>
    </Form>
  );
}

export default withForm(Login);
