import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { Link, useNavigate } from 'react-router-dom';

// External components
import { Button, Card, message } from 'antd';

// Utils
import { cnpjFormat } from 'app/utils/masks';

// Services
import { getAll } from 'app/services/EmpresasService';

// Components
import SubHeader from 'app/components/Subheader';
import Table from 'app/components/Table';
import RowActionButton from 'app/components/RowActionButton';
import ModalRemoveEmpresa from 'app/components/ModalRemoveEmpresa';
import EmpresaDetailsPanel from 'app/components/EmpresaDetailsPanel';

// Styles
import './Empresas.scss';

function Empresas() {
  const navigate = useNavigate();

  // States
  const [empresas, setEmpresas] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [currentMatriz, setCurrentMatriz] = useState(null);
  const [showModalRemoveEmpresa, setshowModalRemoveEmpresa] = useState(false);
  const [showEmpresaPanel, setShowEmpresaPanel] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchMatrizes();
  }, [currentPage]);

  async function fetchMatrizes() {
    setFetching(true);

    const pagination = {
      skip: currentPage - 1,
      limit: pageSize,
    };

    try {
      const { results, count } = await getAll({
        params: { isMatriz: true, ...pagination },
      });

      setTotalItems(count);
      setEmpresas(results);
      setFetching(false);
    } catch (error) {
      message.error(i18n.t('Erro ao buscar empresas'));
    }
  }

  function handleCreate() {
    setShowEmpresaPanel(true);
  }

  function handleEdit(matriz) {
    navigate(`empresa/${matriz._id}`);
  }

  function openRemoveMatriz(matriz) {
    setCurrentMatriz(matriz);
    toggleModalRemoveEmpresa(true);
  }

  function toggleModalRemoveEmpresa(status) {
    setshowModalRemoveEmpresa(status);
  }

  async function refetchAfterUpdate() {
    if (currentPage === 1) {
      await fetchMatrizes();
    } else {
      // executa o fetch através do useState
      setCurrentPage(1);
    }
  }

  function afterRemove() {
    refetchAfterUpdate();
  }

  function onSave() {
    setShowEmpresaPanel(false);
    refetchAfterUpdate();
  }

  async function onChangeTable(paginate) {
    let page = paginate.current;

    if (paginate.pageSize !== pageSize) {
      page = 1;
    }

    setPageSize(paginate.pageSize);
    setCurrentPage(page);
  }

  function renderHeaderActions() {
    return (
      <Button
        className="create-user-button uppercase"
        type="primary"
        onClick={handleCreate}
      >
        {i18n.t('Criar empresa')}
      </Button>
    );
  }

  const columns = [
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 130,
      filter: true,
      render: (cnpj, row) => (
        <Link
          to={{
            pathname: `/empresa/${row._id}`,
            state: { empresa: row },
          }}
        >
          {cnpjFormat(cnpj)}
        </Link>
      ),
    },
    {
      title: i18n.t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      width: 250,
      filter: true,
    },
    {
      title: i18n.t('Nome fantasia'),
      dataIndex: 'nomeFantasia',
      key: 'nomeFantasia',
      width: 250,
      filter: true,
    },
    {
      title: i18n.t('Filiais'),
      dataIndex: 'qtdFiliais',
      key: 'qtdFiliais',
      width: 100,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (data, row) => (
        <RowActionButton
          disabledRemove={row.qtdFiliais > 0}
          onClickEdit={() => handleEdit(row)}
          onClickRemove={() => openRemoveMatriz(row)}
        />
      ),
    },
  ];

  function renderTotalItems() {
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} empresas', { totalItems })
        : i18n.t('{{totalItems}} empresa', { totalItems });

    return <span>{label}</span>;
  }

  return (
    <Card className="empresas">
      <SubHeader title={i18n.t('Empresas')} prefix={renderHeaderActions()} />

      <Table
        rowKey="_id"
        columns={columns}
        dataSource={empresas}
        scroll={{ x: 1300 }}
        isLoading={fetching}
        pagination={{
          current: currentPage,
          pageSize,
          total: totalItems,
          showTotal: renderTotalItems,
        }}
        onChange={onChangeTable}
      />

      <EmpresaDetailsPanel
        visible={showEmpresaPanel}
        fetchMatriz={() => fetchMatrizes()}
        onSave={onSave}
        onClose={() => setShowEmpresaPanel(false)}
      />

      <ModalRemoveEmpresa
        empresa={currentMatriz}
        isVisible={showModalRemoveEmpresa}
        toggleVisible={toggleModalRemoveEmpresa}
        afterRemove={afterRemove}
      />
    </Card>
  );
}

export default Empresas;
