import React from 'react';
import Proptypes from 'prop-types';

// Icons
import { FaBars } from 'react-icons/fa';

// External components
import { Button } from 'antd';

// Styles
import './SidebarHeader.scss';

function SidebarHeader({ className, collapsed, onClick, ...rest }) {
  const componentClasses = [
    'sidebar-header',
    className || '',
    collapsed ? 'collapsed' : '',
  ].join(' ');
  let style;
  if (process.env.NODE_ENV !== 'production') {
    style = { backgroundColor: "#59667a" }
  }

  return (
    <header className={componentClasses} {...rest} style={style}>
      <a href="/" className="brand">
        AUTO CAT42
      </a>

      <Button className="sidebar-toggle-button" type="text" onClick={onClick}>
        <FaBars size={20} />
      </Button>
    </header>
  );
}

SidebarHeader.propTypes = {
  className: Proptypes.string,
  collapsed: Proptypes.bool,
  onClick: Proptypes.func,
};

SidebarHeader.defaultProps = {
  className: '',
  collapsed: false,
  onClick: () => null,
};

export default SidebarHeader;
