import { defaultStyle, headerStyle } from './cellStyles';

function calculateColumnlen(data = [[], []]) {
  const WIDTH_BY_CHAR = 8;
  const [header, firstRow] = data.slice(0, 2);
  const columnlen = {};

  const calculate = (col, colIndex) => {
    const currentWidth = col.length * WIDTH_BY_CHAR;
    const lastWidth = columnlen[colIndex];

    if (!lastWidth) {
      columnlen[colIndex] = currentWidth;
      return;
    }

    if (lastWidth < currentWidth) {
      columnlen[colIndex] = currentWidth;
    }
  };

  header.forEach(calculate);
  firstRow.forEach(calculate);

  return columnlen;
}

function buildCelldataFromArr(arr) {
  return arr
    .map((row, rowIndex) => {
      const cols = row.map((col, colIndex) => {
        const isHeaderRow = rowIndex === 0;

        const v = {
          ...(isHeaderRow ? headerStyle : defaultStyle),
          m: col,
          v: col,
        };

        return {
          r: rowIndex,
          c: colIndex,
          v,
        };
      });

      return cols;
    })
    .flatMap((x) => [...x]);
}

export function buildLuckysheetOptions(config) {
  const {
    containerId,
    columnlen,
    rowlen,
    documentName,
    sheetName,
    data,
  } = config;

  const rows = buildCelldataFromArr(data);

  const options = {
    container: containerId,
    plugins: [],
    title: documentName,
    data: [
      {
        name: sheetName,
        config: {
          columnlen: columnlen || calculateColumnlen(data),
          rowlen,
        },
        row: rows.length,
        column: rows?.[0]?.length || 0,
        celldata: rows,
      },
    ],
  };

  return options;
}
