import Api from 'app/services/Api';
import { downloadFile } from 'app/utils/download';

export const resource = 'imports';

export function get(params) {
  return Api.get(resource, { params });
}

export function cancel(id) {
  return Api.post(`${resource}/${id}/cancel`);
}

export function dowloadInvalids(id) {
  return Api.get(`${resource}/${id}/download-invalids`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function triggerRegFiscaisReprocessing({ cnpjMatriz, payload }) {
  return Api.post(`${resource}/reverte-retificacao/${cnpjMatriz}`, payload);
}

export function triggerXmlReprocessing({ cnpjMatriz, payload }) {
  return Api.post(`${resource}/regs-fiscais/reprocess/${cnpjMatriz}`, payload);
}

export function triggerSpedReprocessing({ cnpjMatriz, payload }) {
  return Api.post(`${resource}/speds/reprocess/${cnpjMatriz}`, payload);
}
