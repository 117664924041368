import React from 'react';
import i18n from 'i18n';

// External components
import { Alert, Card, Divider } from 'antd';

// Styles
import './ImportRegistroFiscal.scss';

// Constants
import { ImportTypesEnum } from 'app/constants/ImportTypesConstants';

// Services
import {
  get,
  resource,
  cancel,
  dowloadInvalids,
} from 'app/services/ImportService';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import SubHeader from 'app/components/Subheader';
import CrudFiles from 'app/components/CrudFiles';
import { FileTypes } from 'app/components/Uploader';

function ImportSpeds() {
  const { empresa } = useEmpresa();

  return (
    <Card className="import-registros-fiscais">
      <SubHeader title={i18n.t('Importar registros fiscais')} />

      <Alert
        message="Antes de importar os xmls dos registros fiscais, verifique se o arquivo está na extensão permitida (.xml ou .zip) e com tamanho inferior à 1GB"
        type="warning"
        showIcon
      />

      <Divider />

      {empresa && (
        <CrudFiles
          matriz={empresa}
          type={[ImportTypesEnum.REG_FISCAL, ImportTypesEnum.REG_FISCAL_REPROCESS]}
          getFiles={get}
          cancel={cancel}
          download={dowloadInvalids}
          uploadConfig={{
            title: i18n.t('Upload de registros fiscais'),
            uploadingUrl: `${resource}/regs-fiscais`,
            additionalData: { cnpjMatriz: empresa?.cnpj },
            maxFileSizeInMega: 10240,
            filesAccepted: [
              FileTypes.xml,
              // FileTypes.rar,
              FileTypes.zip,
              FileTypes.withoutMimeType,
            ],
          }}
        />
      )}
    </Card>
  );
}

export default ImportSpeds;
