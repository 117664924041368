import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import i18n from 'i18n';

// External components
import { message, Spin } from 'antd';

// Components
import Luckysheet from 'app/components/Luckysheet';

// Utils
import { cnpjFormat } from 'app/utils/masks';

// Services
import { getFicha3 } from 'app/services/ApuracaoService';

// Config
import { ColumnWidth } from './sheet.config';

// Styles
import './Ficha3.scss';

function Ficha3() {
  const queryParams = useParams();
  const [searchParams] = useSearchParams();

  const cnpjMatriz = searchParams.get('cnpjMatriz');

  const [spreedsheetData, setSpreedsheetData] = useState(null);
  const [spreedsheetOptions, setSpreedsheetOptions] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchFicha3({ ...queryParams, cnpjMatriz });
  }, []);

  useEffect(() => {
    if (!spreedsheetData) {
      return;
    }

    const { cProd, cnpj, periodo } = queryParams;

    const documentName = [
      i18n.t('Ficha 3'),
      `CNPJ: ${cnpjFormat(cnpj)}`,
      `cProd: ${cProd}`,
      `Período: ${periodo}`,
    ].join(' | ');

    const luckysheetOptions = {
      container: 'luckysheet',
      data: spreedsheetData,
      columnlen: ColumnWidth,
      rowlen: { 0: 60 },
      sheetName: 'Ficha3',
      documentName,
    };

    setSpreedsheetOptions(luckysheetOptions);
  }, [spreedsheetData]);

  async function fetchFicha3(params) {
    setFetching(true);

    try {
      const { data } = await getFicha3(params);
      setSpreedsheetData(data);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar ficha 3'));
    } finally {
      setFetching(false);
    }
  }

  return (
    <div className="ficha3">
      <Spin spinning={fetching} tip={i18n.t('Carregando ficha 3...')}>
        {spreedsheetOptions && <Luckysheet options={spreedsheetOptions} />}
      </Spin>
    </div>
  );
}

export default Ficha3;
