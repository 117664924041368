import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Modal } from 'antd';

// Styles
import './ModalDetalhesRelatorio.scss';

function ModalDetalhesRelatorio({ report, visible, toggleVisible }) {
  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-detalhes-relatorio"
      title={i18n.t('Detalhes do relatório de {{type}}', {
        type: report?.type,
      })}
      visible={visible}
      width={600}
      footer={null}
      onCancel={closeModal}
    >
      <div>
        <h4>{i18n.t('Parâmetros utilizados')}:</h4>
        <pre>{JSON.stringify(report?.params, null, 2)}</pre>
      </div>
    </Modal>
  );
}

ModalDetalhesRelatorio.propTypes = {
  visible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  report: Proptypes.object,
};

ModalDetalhesRelatorio.defaultProps = {
  report: null,
};

export default ModalDetalhesRelatorio;
