import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { useQuery } from 'react-query';
import i18n from 'i18n';
import debounce from 'lodash.debounce';

// External components
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  DatePicker,
} from 'antd';

// Services
import { getArqDig1050 } from 'app/services/ArqDig1050Service';
import { getProdutos } from 'app/services/ProdutoService';

// Components
import Table from 'app/components/Table';
import SelectEmpresa from 'app/components/Select/SelectEmpresa';

// Query Key Factory
import { arqDig1050KeyFactory } from 'app/features/Empresa/EmpresaArqDig1050/queryKeyFactory';

// Utils
import { formatProcessoRangeDate, formatPeriodo } from 'app/utils/date';

// Styles
import './ArqDig1050Table.scss';
import { cnpjFormat } from 'app/utils/masks';

const { RangePicker } = DatePicker;

function ArqDig1050Table({ matriz }) {
  const cnpjMatriz = matriz?.cnpjMatriz;

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Filters
  const [cProdFilter, setCProdFilter] = useState();
  const [cnpjFilter, setCnpjFilter] = useState();
  const [periodoFilter, setPeriodoFilter] = useState();

  // CPROD Select States
  const [productSearch, setProductSearch] = useState('');
  const [productSearchData, setProductSearchData] = useState([]);
  const [isSearchingProduct, setIsSearchingProduct] = useState(false);

  const produtoBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 7,
  };

  const cnpjsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 7,
  };

  const periodoBreakpoints = {
    xs: 24,
    sm: 24,
    md: 16,
    lg: 6,
  };

  const actionsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 8,
    lg: 4,
  };

  const queryKey = arqDig1050KeyFactory.filteredFromRede(cnpjMatriz, {
    currentPage,
    pageSize,
    cProdFilter,
    cnpjFilter,
    periodoFilter,
  });

  const skip = (currentPage - 1) * pageSize;

  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      getArqDig1050({
        cnpjMatriz,
        cnpjs: cnpjFilter,
        cProd: cProdFilter,
        periodoInicio: periodoFilter?.inicio,
        periodoFim: periodoFilter?.fim,
        skip,
        limit: pageSize,
      }),
    {
      enabled: !!cnpjMatriz,
      refetchOnWindowFocus: false,
      select: (result) => result?.data,
    },
  );

  const columns = [
    {
      title: i18n.t('CNPJ'),
      dataIndex: ['extraFields', 'cnpj'],
      key: 'cnpj',
      render: (cnpj) => cnpj && cnpjFormat(cnpj),
    },
    {
      title: i18n.t('CPROD'),
      dataIndex: ['extraFields', 'cProd'],
      key: 'cProd',
    },
    {
      title: i18n.t('Período'),
      dataIndex: ['extraFields', 'periodo'],
      key: 'periodo',
      className: 'first-letter-capitalize',
      render: (periodo) => periodo && formatPeriodo(periodo),
    },
    {
      title: i18n.t('Quantidade Inicial'),
      dataIndex: 'qtdIni',
      key: 'qtdIni',
    },
    {
      title: i18n.t('Quantidade Final'),
      dataIndex: 'qtdFim',
      key: 'qtdFim',
    },
    {
      title: i18n.t('ICMS Total Inicial'),
      dataIndex: 'icmsTotIni',
      key: 'icmsTotIni',
    },
    {
      title: i18n.t('ICMS Total Final'),
      dataIndex: 'icmsTotFim',
      key: 'icmsTotFim',
    },
  ];

  function onChangeTable(paginate) {
    setPageSize(paginate.pageSize);
    setCurrentPage(paginate.current);
  }

  function renderTotalItems() {
    const totalItems = data?.count;
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} produtos processados', {
            totalItems,
          })
        : i18n.t('{{totalItems}} produto processado', {
            totalItems,
          });

    return <span>{label}</span>;
  }

  function filterResults({ cProd, cnpj, periodo }) {
    const [periodoInicio, periodoFim] = periodo || [];

    if (periodoInicio && periodoFim) {
      setPeriodoFilter({
        inicio: formatProcessoRangeDate(periodoInicio),
        fim: formatProcessoRangeDate(periodoFim),
      });
    } else {
      setPeriodoFilter(undefined);
    }

    setCProdFilter(cProd);
    setCnpjFilter(cnpj);
  }

  const debounceTimeout = 500;
  const debouncedFetchProducts = debounce(async (value, callback) => {
    setIsSearchingProduct(true);

    try {
      const { data: result } = await getProdutos({
        value,
        cnpjs: [cnpjMatriz],
        cnpjMatriz,
      });

      callback(result?.[0]?.data);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar produtos'));
      callback([]);
    } finally {
      setIsSearchingProduct(false);
    }
  }, debounceTimeout);

  function handleProductSearch(newValue) {
    if (!newValue) {
      setProductSearchData([]);
      return;
    }

    debouncedFetchProducts(newValue, setProductSearchData);
  }

  return (
    <div className="processed-arqdig1050">
      <Form onFinish={filterResults}>
        <Row gutter={10}>
          <Col {...produtoBreakpoints}>
            <Form.Item name="cProd">
              <Select
                showSearch
                allowClear
                placeholder={i18n.t('Selecione um produto')}
                value={productSearch}
                loading={isSearchingProduct}
                options={productSearchData.map((d) => ({
                  value: d.cProd,
                  label: d.desc,
                }))}
                notFoundContent={i18n.t(
                  'Não foi encontrado produtos com este nome ou cProd',
                )}
                onSearch={handleProductSearch}
                onChange={(newValue) => setProductSearch(newValue)}
              />
            </Form.Item>
          </Col>

          <Col {...cnpjsBreakpoints}>
            <Form.Item name="cnpj">
              <SelectEmpresa allowSelectAll cnpjMatriz={cnpjMatriz} />
            </Form.Item>
          </Col>

          <Col {...periodoBreakpoints}>
            <Form.Item name="periodo" noStyle>
              <RangePicker
                picker="month"
                format="MM-YYYY"
                placeholder={[
                  i18n.t('Data de início'),
                  i18n.t('Data de término'),
                ]}
                disabled={isLoading}
              />
            </Form.Item>
          </Col>

          <Col {...actionsBreakpoints}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="button-filtrar uppercase"
              disabled={isLoading}
            >
              {i18n.t('Filtrar')}
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Row>
        <Col flex="auto">
          <Table
            columns={columns}
            dataSource={data?.results}
            loading={isLoading}
            scroll={{ x: '130rem' }}
            pagination={{
              current: currentPage,
              pageSize,
              total: data?.count,
              showTotal: renderTotalItems,
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </div>
  );
}

ArqDig1050Table.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    cnpjMatriz: Proptypes.string,
    filiais: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  isTabActive: Proptypes.bool,
  onEditClick: Proptypes.func,
  onRemoveClick: Proptypes.func,
};

ArqDig1050Table.defaultProps = {
  matriz: null,
  isTabActive: false,
  onEditClick: () => {},
  onRemoveClick: () => {},
};

export default ArqDig1050Table;
