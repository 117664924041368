import React from 'react';
import ReactDOM from 'react-dom';

// Application configs
import 'i18n';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/locale/pt_BR';
import { ConfigProvider, message } from 'antd';

// React query config
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Router config
import Routes from 'app/routes';

// Constants
import { MESSAGE_MAX_COUNT } from 'app/constants/Enumerators';

// Application core styles
import 'antd/dist/antd.css';
import 'app/styles/index.scss';

// Set default locale
moment.locale('pt-br');

// Global message config
message.config({
  maxCount: MESSAGE_MAX_COUNT,
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={locale}>
      <QueryClientProvider client={queryClient}>
        <Routes />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
