import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Form, Select } from 'antd';
import InfoIconWithPopover from '../InfoIconWithPopover/InfoIconWithPopover';

function TagSelect({ formGroup, filter, placeholder }) {
  const { id, label, required, info } = filter;

  const normalize = (value) => (value.length ? value.map(item => item.replace(/^\s+|\s+$/g, '')).join(',').split(',') : []);

  // Verifica se customPopoverText possui um valor antes de renderizar o ícone e o Popover
  const renderInfoIconWithPopover = info ? (
    <InfoIconWithPopover content={info} />
  ) : null;

  return (
    <Form.Item
      name={[...formGroup, id]}
      label={
        label || id ? (
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {renderInfoIconWithPopover}
            {label || id}
          </span>
        ) : null
      }
      rules={[{ required, message: i18n.t('Campo obrigatório') }]}
      normalize={normalize}
    >
      <Select mode="tags" maxTagCount="responsive" placeholder={placeholder} />
    </Form.Item>
  );
}

TagSelect.propTypes = {
  filter: Proptypes.shape({
    id: Proptypes.string,
    type: Proptypes.string.isRequired,
    label: Proptypes.string,
    info: Proptypes.string,
    required: Proptypes.bool,
  }).isRequired,
  formGroup: Proptypes.arrayOf(Proptypes.string),
  placeholder: Proptypes.string
};

TagSelect.defaultProps = {
  formGroup: [],
  placeholder: '',
};

export default TagSelect;
