import React from 'react';
import i18n from 'i18n';

// External components
import { Alert, Card, Divider } from 'antd';

// Styles
import './ImportArquivosDigitais.scss';

// Constants
import { ImportTypesEnum } from 'app/constants/ImportTypesConstants';

// Services
import {
  get,
  resource,
  cancel,
  dowloadInvalids,
} from 'app/services/ImportService';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// ComponentsPP
import SubHeader from 'app/components/Subheader';
import CrudFiles from 'app/components/CrudFiles';
import { FileTypes } from 'app/components/Uploader';

function ImportSpeds() {
  const { empresa } = useEmpresa();

  return (
    <Card className="import-arquivos-digitais">
      <SubHeader title={i18n.t('Importar arquivos digitais')} />

      <Alert
        message="Importe arquivos digitais já entregues, para considerar os dados de saldos nas apurações futuras."
        type="info"
        showIcon
      />

      <Divider />

      {empresa && (
        <CrudFiles
          matriz={empresa}
          type={ImportTypesEnum.ARQUIVO_DIGITAL}
          getFiles={get}
          cancel={cancel}
          download={dowloadInvalids}
          uploadConfig={{
            title: i18n.t('Upload de arquivos digitais'),
            uploadingUrl: `${resource}/arquivos-digitais`,
            additionalData: { cnpjMatriz: empresa?.cnpj },
            maxFileSizeInMega: 10240,
            filesAccepted: [
              FileTypes.xml,
              // FileTypes.rar,
              FileTypes.zip,
              FileTypes.withoutMimeType,
            ],
          }}
        />
      )}
    </Card>
  );
}

export default ImportSpeds;
