import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import i18n from 'i18n';

// External components
import { Button, Card, Form, Modal, notification, DatePicker, Alert, Divider } from 'antd';
import { ImWarning } from 'react-icons/im';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import { resetArqDig1050 } from 'app/services/ArqDig1050Service';

// Utils
import * as dateUtils from 'app/utils/date';

// Components
import SubHeader from 'app/components/Subheader';
import ArqDig1050Table from 'app/components/ArqDig1050Table';
import SelectEmpresa from 'app/components/Select/SelectEmpresa/SelectEmpresa';
import SelectProduto from 'app/components/Select/SelectProduto/SelectProduto';

// Query Key Factory
import { arqDig1050KeyFactory } from './queryKeyFactory';

// Styles
import './EmpresaArqDig1050.scss';

const { RangePicker } = DatePicker;

function EmpresaArqDig1050() {
  const [form] = Form.useForm();
  const [buttonOkDisabled, setButtonOkDisabled] = useState(true);

  const { empresa } = useEmpresa();
  const cnpjMatriz = empresa?.cnpjMatriz;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const deleteAllMutation = useMutation(
    (payload) => resetArqDig1050({ cnpjMatriz, payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          arqDig1050KeyFactory.allFromRede(cnpjMatriz),
        );
      },
      onError: () => {
        notification.error({
          message: i18n.t('Erro ao apagar arqDig1050'),
        });
      },
    },
  );

  function openModal() {
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  async function onModalConfirmation() {
    const periodo = form.getFieldValue('periodo');
    const produtos = form.getFieldValue('produtos');
    const cnpjs = form.getFieldValue('cnpjs');
    const payload = {};

    if (periodo?.length === 2) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodo[0]);
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodo[1]);
    }

    if (cnpjs?.length) {
      payload.cnpjs = cnpjs
    }

    if (produtos?.length) {
      payload.cProds = produtos
    }

    await deleteAllMutation.mutateAsync(payload);

    form.resetFields();

    notification.info({
      message: i18n.t('Registros apagados com sucesso.'),
    });

    hideModal();
  }

  return (
    <Card className="empresa-arqdig1050">
      <SubHeader
        title={i18n.t('Arquivo Digital: Registro 1050')}
        prefix={
          <>
            <Button
              type="default"
              danger
              className="uppercase"
              loading={deleteAllMutation.isLoading}
              onClick={openModal}
            >
              {i18n.t('Resetar registro 1050')}
            </Button>
            <Modal
              className="modal-confirmation"
              title={i18n.t('ATENÇÃO')}
              okText={i18n.t('Resetar registro 1050')}
              cancelText={i18n.t('Cancelar')}
              width={450}
              visible={isModalOpen}
              closable={!deleteAllMutation.isLoading}
              maskClosable={!deleteAllMutation.isLoading}
              confirmLoading={deleteAllMutation.isLoading}
              cancelButtonProps={{ disabled: deleteAllMutation.isLoading }}
              onOk={onModalConfirmation}
              okButtonProps={{ disabled: buttonOkDisabled }}
              onCancel={hideModal}
            >
              <Form
                form={form}
                onFieldsChange={() =>
                  setButtonOkDisabled(
                    form.getFieldValue('periodo')?.length !== 2,
                  )
                }
              >
                <p>
                  Deseja apagar os dados de estoque dos registro 1050 importados via arquivo digital ?
                </p>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="periodo">Selecione o período:</label>
                  <Form.Item name="periodo" noStyle>
                    <RangePicker
                      picker="month"
                      format="MM-YYYY"
                      placeholder={[
                        i18n.t('Data de início'),
                        i18n.t('Data de término'),
                      ]}
                    />
                  </Form.Item>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="cnpjs">Selecione os CNPJs:</label>
                  <Form.Item name="cnpjs"
                    required
                    rules={[
                      {
                        required: true,
                        message: i18n.t('Filtro de CNPJ é obrigatório'),
                      },
                    ]}>
                    <SelectEmpresa
                      allowSelectAll
                      selectAll
                      cnpjMatriz={empresa?.cnpj}
                    />
                  </Form.Item>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="produtos">Selecione os cProds (caso nenhum cProd seja informado, todos serão apagados):</label>
                  <Form.Item name="produtos">
                    <SelectProduto cnpjs={[empresa?.cnpj]} />
                  </Form.Item>
                </div>
              </Form>
            </Modal>
          </>
        }
      />


      <Alert
        message="Aqui você pode visualizar e apagar os dados do reg1050 dos arquivos digitais importados."
        type="info"
        showIcon
      />

      <Divider />

      <ArqDig1050Table matriz={empresa} />
    </Card>
  );
}

export default EmpresaArqDig1050;
