import React, { useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// Icons
import { FaEdit, FaEllipsisH, FaEllipsisV, FaTrashAlt } from 'react-icons/fa';

// External components
import { Button, Dropdown, Menu, Space } from 'antd';

// RowActionMenu styles
import './RowActionButton.scss';

export default function RowActionButton({
  visibleEdit,
  disabledEdit,
  visibleRemove,
  disabledRemove,
  onClickEdit,
  onClickRemove,
  children,
}) {
  // States
  const [visible, setVisible] = useState(false);

  const actions = [
    {
      key: 'Editar',
      icon: <FaEdit size={18} />,
      label: i18n.t('Editar'),
      className: 'menu-item-edit',
      disabled: disabledEdit,
      visible: visibleEdit,
      action: handleEdit,
    },
    {
      key: 'Remover',
      icon: <FaTrashAlt size={16} />,
      label: i18n.t('Remover'),
      className: 'menu-item-remove',
      disabled: disabledRemove,
      visible: visibleRemove,
      action: handleRemove,
    },
  ];

  function handleEdit() {
    onClickEdit && onClickEdit();
    handleMenuToggle(false);
  }

  function handleRemove() {
    onClickRemove && onClickRemove();
    handleMenuToggle(false);
  }

  function handleMenuToggle(status) {
    setVisible(status);
  }

  function renderMenuItem(action) {
    const {
      key,
      component,
      className,
      disabled,
      icon,
      label,
      visible: visibleItem,
      action: actionOnClick,
    } = action;

    if (!visibleItem) {
      return null;
    }

    if (component) {
      return (
        <Menu.Item key={key} className={className}>
          {component}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item
        key={key}
        className={className}
        disabled={disabled}
        onClick={actionOnClick}
      >
        <Space size={13}>
          {icon}
          {label}
        </Space>
      </Menu.Item>
    );
  }

  function renderRowActionMenu() {
    return (
      <Menu className="row-action-menu">
        {actions.map(renderMenuItem)}
        {children}
      </Menu>
    );
  }

  return (
    <div className="row-action-button-wrapper">
      <Dropdown
        className="row-action-button"
        overlay={renderRowActionMenu()}
        trigger={['click']}
        placement="bottomRight"
        visible={visible}
        onVisibleChange={handleMenuToggle}
      >
        <Button type="text">
          {visible ? <FaEllipsisH /> : <FaEllipsisV />}
        </Button>
      </Dropdown>
    </div>
  );
}

RowActionButton.propTypes = {
  visibleEdit: Proptypes.bool,
  disabledEdit: Proptypes.bool,
  visibleRemove: Proptypes.bool,
  disabledRemove: Proptypes.bool,
  onClickEdit: Proptypes.func,
  onClickRemove: Proptypes.func,
  children: Proptypes.element,
};

RowActionButton.defaultProps = {
  visibleEdit: true,
  visibleRemove: true,
  disabledEdit: false,
  disabledRemove: false,
  onClickEdit: null,
  onClickRemove: null,
  children: null,
};
