import * as React from 'react';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import {
  Button,
  Form,
  Row,
  Drawer as AntdDrawer,
  Input,
  Col,
  message,
} from 'antd';
import i18n from 'i18n';
import PropTypes from 'prop-types';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

export function Drawer({
  visible,
  produto,
  onSubmit,
  onSubmitSuccess,
  onClose,
}) {
  const { empresa } = useEmpresa();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(produto || {});
  }, [produto]);

  const submitMutation = useMutation(onSubmit, {
    onSuccess: () => {
      message.success(i18n.t('Produto atualizado com sucesso!'));
      onSubmitSuccess?.();
    },
    onError: () => message.error(i18n.t('Erro ao atualizar produto!')),
  });

  async function handleSubmit() {
    const fields = form.getFieldsValue();

    submitMutation.mutate({
      cnpjMatriz: empresa.cnpjMatriz,
      ...fields,
    });
  }

  function renderFooter() {
    return (
      <Row justify="end">
        <Button
          className="save-button uppercase"
          type="primary"
          disabled={submitMutation.isLoading}
          htmlType="submit"
          onClick={handleSubmit}
        >
          {i18n.t('Salvar')}
        </Button>
      </Row>
    );
  }

  return (
    <Form
      name="product-detais-panel"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <AntdDrawer
        className="product-details-panel-drawer"
        title={i18n.t('Informações do produto')}
        placement="right"
        width={400}
        visible={visible}
        getContainer={false}
        footer={renderFooter()}
        onClose={onClose}
      >
        <Row gutter={16}>
          <Form.Item name="_id" hidden>
            <Input />
          </Form.Item>

          <Col span={24}>
            <Form.Item name="cProd" label={i18n.t('CPROD')}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="aliquotaInterna"
              label={i18n.t('Alíquota Interna')}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="aliquotaReducao"
              label={i18n.t('Alíquota Redução')}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="cest" label={i18n.t('CEST')}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="desc" label={i18n.t('Descrição')}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="ncm" label={i18n.t('NCM')}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="unidadeMedida" label={i18n.t('Unidade Medida')}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </AntdDrawer>
    </Form>
  );
}

Drawer.propTypes = {
  visible: PropTypes.bool,
  produto: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

Drawer.defaultProps = {
  visible: false,
  produto: null,
  onSubmit: () => {},
  onSubmitSuccess: () => {},
  onClose: () => {},
};
