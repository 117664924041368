import React, { useState } from 'react';
import Proptypes from 'prop-types';

// External components
import { Tag } from 'antd';

// Styles
import './TagFilter.scss';

function TagFilter({ title, tags, onChange }) {
  // Current selected tags
  const [selectedTags, setSelectedTags] = useState([]);

  function handleChangeTags(tag, checked) {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);

    setSelectedTags(nextSelectedTags);
    onChange(nextSelectedTags);
  }

  return (
    <div className="tag-filter">
      <span className="title">{title} </span>
      {tags.map((tag) => (
        <Tag.CheckableTag
          key={tag.value}
          checked={selectedTags.indexOf(tag.value) > -1}
          onChange={(checked) => {
            handleChangeTags(tag.value, checked);
          }}
        >
          {tag.label}
        </Tag.CheckableTag>
      ))}
    </div>
  );
}

TagFilter.propTypes = {
  tags: Proptypes.arrayOf(
    Proptypes.shape({ title: Proptypes.string, value: Proptypes.string }),
  ).isRequired,
  title: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
};

export default TagFilter;
