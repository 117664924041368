import React, { useEffect } from 'react';
import i18n from 'i18n';
import Proptypes from 'prop-types';

// External components
import { Select } from 'antd';

// Styles
import './SelectAlertaProcesso.scss';

function SelectAlertaProcesso({ items, onSelect }) {
  useEffect(() => {
    onSelect(items[0]);
  }, []);

  const className = 'select-alerta-processo';

  const options = items?.map((item) => ({
    value: item,
    label: item,
  }));

  const selectProps = {
    options,
    className,
    dropdownMatchSelectWidth: false,
    placeholder: i18n.t('Selecione o tipo de alerta'),
    onSelect,
    defaultValue: items[0],
  };

  return <Select {...selectProps} />;
}

SelectAlertaProcesso.propTypes = {
  items: Proptypes.arrayOf(Proptypes.string),
  onSelect: Proptypes.func,
};

SelectAlertaProcesso.defaultProps = {
  items: [],
  onSelect: () => null,
};

export default SelectAlertaProcesso;
