import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';

// Assets
import './EmpresaReports.scss';

// External Components
import {
  Card,
  Col,
  Row,
  Divider,
  Button,
  Form,
  message,
  Grid,
  Select,
  Spin,
  Alert,
  Modal,
  Tooltip
} from 'antd';

// Utils
import { formatProcessoRangeDate } from 'app/utils/date';

// Services
import { getAll, getReportTypes, schedule } from 'app/services/ReportService';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import SelectEmpresa from 'app/components/Select/SelectEmpresa';
import ReportScheduleTable from 'app/components/ReportScheduleTable';
import SubHeader from 'app/components/Subheader';
import FilterGroup from 'app/components/FilterGroup';
import { FiInfo } from 'react-icons/fi';

const { useBreakpoint } = Grid;
const { Option } = Select;

function EmpresaReports() {
  const { empresa } = useEmpresa();
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const [form] = Form.useForm();
  const [reports, setReports] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [fetchingReports, setFetchingReports] = useState(false);
  const [fetchingReportTypes, setFetchingReportTypes] = useState(false);
  const [reportTypeFilters, setReportTypeFilters] = useState();
  const [modalInfo, setModalInfo] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const hideModal = () => {
    setIsModalOpen(false);
  };

  const selectedReportType = Form.useWatch('reportType', form);

  const hasFiliais = empresa?.filiais?.length > 0;

  const cnpjsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
  };

  const periodBreakpoints = {
    xs: 24,
    sm: 24,
    md: hasFiliais ? 6 : 12,
  };

  const actionsBreakpoints = {
    xs: 24,
    sm: 24,
    md: hasFiliais ? 6 : 12,
    flex: breakpoint.md ? '20rem' : 'auto',
  };

  useEffect(() => {
    const currentReportType = reportTypes.find(
      ({ type }) => type === selectedReportType,
    );

    // Limpar filter anterior
    form.setFieldsValue({ filters: null });

    setReportTypeFilters(currentReportType?.filters);

    setModalInfo(currentReportType?.info);
  }, [reportTypes, selectedReportType]);

  useEffect(() => {
    if (empresa?.cnpj) {
      updateRoute(getFieldValues());
    }

    if (empresa?.cnpj && !reportTypes.length) {
      fetchReportTypes();
    }

    if (empresa?.cnpj && !reports.length) {
      fetchReports();
    }
  }, [empresa]);

  useEffect(() => {
    setFieldsByQueryParams();
  }, []);

  async function fetchReportTypes() {
    setFetchingReportTypes(true);

    try {
      const { data } = await getReportTypes({ cnpj: empresa.cnpj });
      setReportTypes(data);
      setFetchingReportTypes(false);

      if (!selectedReportType && data.length) {
        form.setFieldsValue({ reportType: data[0].type });
      }
    } catch (error) {
      message.error(i18n.t('Erro ao carregas tipos de relatórios'));
    }
  }

  async function fetchReports() {
    setFetchingReports(true);

    try {
      const { data } = await getAll({
        cnpjMatriz: empresa.cnpj,
      });
      setReports(data.results);
      setFetchingReports(false);
    } catch (error) {
      message.error(i18n.t('Erro ao carregas os relatórios agendados'));
    }
  }

  async function onFinish() {
    const payload = getFieldValues();

    updateRoute(payload);

    try {
      await schedule(payload);
      message.success(i18n.t('Geração de relatório agendado com sucesso'));
      await fetchReports();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message ||
        i18n.t('Erro ao agendar geração de relatório.');

      message.error(errorMessage);
    }
  }

  function getReportTypeFilters() {
    const currentFilters = form.getFieldValue('filters') || {};
    const filters = {};

    Object.keys(currentFilters).forEach((key) => {
      const value = currentFilters[key];

      if (!value) {
        return;
      }

      // TODO Pensar em um forma mais dinâmica (rangepicker)
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        moment.isMoment(value[0])
      ) {
        filters[key] = [
          formatProcessoRangeDate(value[0]),
          formatProcessoRangeDate(value[1]),
        ];

        return;
      }

      if (value) {
        filters[key] = value;
      }
    });

    return filters;
  }

  function getFieldValues() {
    return {
      cnpjMatriz: empresa.cnpj,
      cnpjs: hasFiliais ? form.getFieldValue('cnpjs') : [empresa.cnpj],
      type: selectedReportType,
      ...getReportTypeFilters(),
    };
  }

  function updateRoute(params) {
    if (!params) {
      return;
    }

    const { cnpjs, type } = params;
    const queryParams = {};

    if (hasFiliais && cnpjs) {
      queryParams.cnpjs = cnpjs;
    }

    if (type) {
      queryParams.reportType = type;
    }

    setSearchParams(queryParams);
  }

  function setFieldsByQueryParams() {
    const cnpjs = searchParams.getAll('cnpjs');
    const reportType = searchParams.get('reportType');

    let cnpjsByQuery;

    if (cnpjs?.length) {
      cnpjsByQuery = cnpjs;
    }

    form.setFieldsValue({
      cnpjs: cnpjsByQuery,
      reportType,
    });
  }

  return (
    <Card className="empresa-reports">
      <Spin spinning={fetchingReportTypes}>
        <SubHeader title={i18n.t('Relatórios')} />
  
        <Alert
          message="Ao gerar um relatório de um tipo que já foi gerado, apenas a última versão é mantida."
          type="info"
          showIcon
        />
  
        <Divider />
  
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row className="filter-options" gutter={[16, 24]}>
            {hasFiliais && (
              <Col {...cnpjsBreakpoints}>
                <Form.Item
                  name="cnpjs"
                  required
                  rules={[
                    {
                      required: true,
                      message: i18n.t('Filtro de CNPJ é obrigatório'),
                    },
                  ]}
                >
                  <SelectEmpresa
                    allowSelectAll
                    selectAll
                    cnpjMatriz={empresa?.cnpj}
                  />
                </Form.Item>
              </Col>
            )}
  
            <Col {...periodBreakpoints}>
              <Form.Item
                name="reportType"
                rules={[
                  {
                    required: true,
                    message: i18n.t('Tipo de relatório obrigatório'),
                  },
                ]}
              >
                <Select placeholder={i18n.t('Selecione o tipo de relatório')}>
                  {reportTypes?.map(({ type }) => (
                    <Option key={type}>{type}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
  
            <Col {...actionsBreakpoints}>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="btn-executar-processo uppercase"
              >
                {i18n.t('Gerar relatório')}
              </Button>
            </Col>
  
            <Col>
              <Tooltip title={i18n.t('Informações')}>
                <Button
                  type="primary"
                  onClick={() => openModal()}
                  disabled={!modalInfo}
                >
                  <FiInfo size={18} />
                </Button>
              </Tooltip>
            </Col>
          </Row>

          {reportTypeFilters?.length > 0 && (
              <Row>
                <Divider />
                <Col span={24}>
                  <FilterGroup
                    formGroup={['filters']}
                    parentType={selectedReportType}
                    filters={reportTypeFilters}
                  />
                </Col>
              </Row>
            )}
        </Form>
  
        <Modal
          footer={null}
          title={selectedReportType}
          visible={isModalOpen}
          onOk={hideModal}
          onCancel={hideModal}
          width={1000}
        >
          <div>
            <ReactMarkdown>{modalInfo}</ReactMarkdown>
          </div>
        </Modal>
  
        <Divider />
  
        <ReportScheduleTable
          reports={reports}
          loading={fetchingReports}
          refetch={() => fetchReports()}
          onCancel={() => fetchReports()}
        />
      </Spin>
    </Card>
  );
}

export default EmpresaReports;
