import React, { useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// External components
import { Modal, Form, DatePicker } from 'antd';

// Styles
import './ModalReset.scss';
import SelectEmpresa from '../Select/SelectEmpresa';
import SelectProduto from '../Select/SelectProduto';

const { RangePicker } = DatePicker;

const ModalReset = ({
  isVisible,
  onOk,
  onCancel,
  isLoading,
  empresa,
  okText,
  questionText,
  hasCprods
}) => {
  const [form] = Form.useForm();
  const [buttonOkDisabled, setButtonOkDisabled] = useState(true);

  const handleOk = () => {
    onOk(form);
  };

  return (
    <Modal
      className="modal-confirmation"
      title={i18n.t('ATENÇÃO')}
      okText={okText}
      cancelText={i18n.t('Cancelar')}
      width={450}
      visible={isVisible}
      closable={!isLoading}
      maskClosable={!isLoading}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={handleOk}
      okButtonProps={{ disabled: buttonOkDisabled }}
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFieldsChange={() =>
          setButtonOkDisabled(form.getFieldValue('periodo')?.length !== 2)
        }
      >
        <p>{questionText}</p>

        <div style={{ marginBottom: '24px' }}>
          <label htmlFor="periodo">Selecione o período:</label>
          <Form.Item name="periodo" noStyle>
            <RangePicker
              picker="month"
              format="MM-YYYY"
              placeholder={[
                i18n.t('Data de início'),
                i18n.t('Data de término'),
              ]}
            />
          </Form.Item>
        </div>


        <div style={{ marginBottom: '24px' }}>
          <label htmlFor="cnpjs">Selecione os CNPJs:</label>
          <Form.Item name="cnpjs"
            required
            rules={[
              {
                required: true,
                message: i18n.t('Filtro de CNPJ é obrigatório'),
              },
            ]}>
            <SelectEmpresa
              allowSelectAll
              selectAll
              cnpjMatriz={empresa?.cnpj}
            />
          </Form.Item>
        </div>

        {hasCprods && (
          <div style={{ marginBottom: '24px' }}>
            <label htmlFor="produtos">Selecione os cProds (caso nenhum cProd seja informado, todos serão apagados):</label>
            <Form.Item name="produtos">
              <SelectProduto cnpjs={[empresa?.cnpj]} />
            </Form.Item>
          </div>
        )}

      </Form>
    </Modal>
  );
};

ModalReset.propTypes = {
  empresa: Proptypes.shape({
    cnpj: Proptypes.string.isRequired,
  }).isRequired,
  isLoading: Proptypes.bool.isRequired,
  isVisible: Proptypes.bool.isRequired,
  hasCprods: Proptypes.bool.isRequired,
  onOk: Proptypes.func.isRequired,
  onCancel: Proptypes.func.isRequired,
  okText: Proptypes.string.isRequired,
  questionText: Proptypes.string.isRequired,
};

ModalReset.defaultProps = {
};

export default ModalReset;

