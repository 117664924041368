import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Col, Row, Space, Typography } from 'antd';
import { BiFilterAlt } from 'react-icons/bi';

// Assets
import './FilterGroup.scss';

// Components
import CheckboxFilter from './CheckboxFilter';
import TagSelectFilter from './TagSelectFilter';
import RangePickerFilter from './RangePickerFilter';
import StringFilter from './StringFilter';
import SwitchFilter from './SwitchFilter';

function FilterGroup({ formGroup, filters, parentType }) {
  const FilterType = {
    text: {
      Component: StringFilter,
    },
    checkbox: {
      Component: CheckboxFilter,
    },
    switch: {
      Component: SwitchFilter,
    },
    rangePicker: {
      Component: RangePickerFilter,
    },
    tagSelect: {
      Component: TagSelectFilter,
    },
  };

  function renderFilter(filter) {
    const { id, type } = filter;

    if (!FilterType[type]) {
      return undefined;
    }

    const { Component } = FilterType[type];

    return (
      <Col xs={24} sm={24} md={12} lg={8} key={`${parentType}-${id}-${type}`}>
        <Component formGroup={formGroup} filter={filter} />
      </Col>
    );
  }

  return (
    <Row className="filter-group">
      <Col span={24}>
        <Space size={8} align="center" className="filter-group__header">
          <BiFilterAlt size={16} />
          <Typography.Title level={5}>{i18n.t('Filtros adicionais')}</Typography.Title>
        </Space>
      </Col>

      <Col span={24}>
        <Row gutter={[24, 12]} align="bottom">
          {filters.map(renderFilter)}
        </Row>
      </Col>
    </Row>
  );
}

FilterGroup.propTypes = {
  filters: Proptypes.arrayOf(
    Proptypes.shape({
      id: Proptypes.string.isRequired,
      type: Proptypes.string.isRequired,
      label: Proptypes.string,
      required: Proptypes.bool,
    }),
  ),
  parentType: Proptypes.string.isRequired,
  formGroup: Proptypes.arrayOf(Proptypes.string),
};

FilterGroup.defaultProps = {
  filters: [],
  formGroup: [],
};

export default FilterGroup;
