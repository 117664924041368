import React, { useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// Icons
import { ImWarning } from 'react-icons/im';

// External components
import { message, Modal, Space } from 'antd';

// Hooks
import useAuth from 'app/hooks/useAuth';

// Services
import { remove } from 'app/services/UsuariosService';

// Styles
import './ModalRemoveUsuario.scss';

function ModalRemoveUsuario({
  usuario,
  isVisible,
  toggleVisible,
  afterRemove,
}) {
  const [removing, setRemoving] = useState(false);
  const { getAuth, logout } = useAuth();

  const auth = getAuth();
  const deleteYourself = usuario?._id === auth?.user?._id;

  async function handleRemoveUsuario() {
    const { _id, name } = usuario;

    setRemoving(true);

    try {
      await remove(_id);

      message.success(
        i18n.t('O usuário {{name}} foi removido com sucesso', {
          name,
        }),
      );

      if (deleteYourself) {
        logout();
      } else {
        afterRemove();
        closeModal();
      }
    } catch (error) {
      message.error(i18n.t('Erro ao remover {{name}}', { name }));
    } finally {
      setRemoving(false);
    }
  }

  function closeModal() {
    toggleVisible(false);
  }

  function renderTextWarning() {
    if (deleteYourself) {
      return i18n.t(
        'Deseja realmente remover o seu usuário? Sua sessão será finalizada!',
      );
    }

    return i18n.t('Deseja realmente remover a usuário {{nome}}?', {
      nome: usuario?.name,
    });
  }

  return (
    <Modal
      className="modal-remove-usuario"
      title={i18n.t('Atenção')}
      okText={i18n.t('Remover')}
      cancelText={i18n.t('Cancelar')}
      width={350}
      visible={isVisible}
      closable={!removing}
      maskClosable={!removing}
      confirmLoading={removing}
      cancelButtonProps={{ disabled: removing }}
      onOk={handleRemoveUsuario}
      onCancel={closeModal}
    >
      <Space size={13}>
        <ImWarning size={30} />
        {renderTextWarning()}
      </Space>
    </Modal>
  );
}

ModalRemoveUsuario.propTypes = {
  usuario: Proptypes.shape({
    _id: Proptypes.string.isRequired,
    name: Proptypes.string.isRequired,
  }),
  isVisible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  afterRemove: Proptypes.func.isRequired,
};

ModalRemoveUsuario.defaultProps = {
  usuario: {},
};

export default ModalRemoveUsuario;
