import React, { useEffect } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';

// External components
import { Spin } from 'antd';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

function EmpresaRoute() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchEmpresa, fetching } = useEmpresa();

  useEffect(() => {
    if (!id) {
      navigate(-1);
      return;
    }

    fetchEmpresa(id);
  }, [id]);

  return (
    <Spin spinning={fetching}>
      <Outlet />
    </Spin>
  );
}

export default EmpresaRoute;
