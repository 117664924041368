import React, { createContext, useState } from 'react';
import Proptypes from 'prop-types';
import { RolesEnum } from 'app/constants/AuthConstants';

export const GlobalContext = createContext();

function GlobalProvider({ children }) {
  const [auth, setAuth] = useState(null);
  const [permissions, setPermissions] = useState([
    { _id: '04060205-baee-41e2-be28-ed94ddc7c521', nome: RolesEnum.USER },
    { _id: 'caff8cfa-30a6-4252-979a-17b0a92c416d', nome: RolesEnum.ADMIN }]);
  const [loadingDownloadTemplate, setLoadingDownloadTemplate] = useState(false);
  const [groupsSelected, setGroupsSelected] = useState([]);

  const GlobalState = {
    authState: { auth, setAuth },
    permissionsState: { permissions, setPermissions },
    downloadTemplateState: {
      loadingDownloadTemplate,
      setLoadingDownloadTemplate,
    },
    groupsSelectedState: { groupsSelected, setGroupsSelected },
  };

  return (
    <GlobalContext.Provider value={GlobalState}>
      {children}
    </GlobalContext.Provider>
  );
}

export default GlobalProvider;

GlobalProvider.propTypes = {
  children: Proptypes.element.isRequired,
};
