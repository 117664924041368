import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Proptypes from 'prop-types';
import i18n from 'i18n';
import moment from 'moment';

// Icons
import { FiDownloadCloud } from 'react-icons/fi';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';
// import { FaTrashAlt } from 'react-icons/fa';

// External components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  Input,
  message,
  Row,
  Space,
  Tooltip,
} from 'antd';

// Utils
import * as dateUtils from 'app/utils/date';
import { cnpjFormat } from 'app/utils/masks';

// Services
import {
  getAll,
  download,
  removeProcessedRegFiscais,
  downloadFilteredRegFiscais,
} from 'app/services/RegFiscalService';

// Components
import Table from 'app/components/Table';
import SelectEmpresa from 'app/components/Select/SelectEmpresa';
import ModalRemoveFile from 'app/components/ModalRemoveFile';
import ModalViewJson from 'app/components/ModalViewJson';

// Styles
import './ProcessedRegFiscal.scss';
import { RegFiscalTypes } from 'app/constants/Enumerators';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

function ProcessedRegFiscal({ matriz, isTabActive }) {
  const [form] = Form.useForm();
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const [dataSource, setDataSource] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [currentFileNames] = useState(null);
  const [isVisibleModalRemove, setIsVisibleModalRemove] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [registroFiscal, setRegistroFiscal] = useState(null);
  const [showModalDetalhes, setShowModalDetalhes] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const hasFiliais = matriz?.filiais?.length > 0;

  const cnpjsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: 7,
  };

  const chaveBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: hasFiliais ? 7 : 10,
  };

  const numDocBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: hasFiliais ? 7 : 10,
  };

  const periodoBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: hasFiliais ? 6 : 10,
  };

  const actionsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 4,
    flex: breakpoint.lg ? '20rem' : 'auto',
  };

  useEffect(() => {
    // Make the first request if it is a company without branches
    if (matriz?.cnpj && !hasFiliais) {
      fetchRegFiscais(getRequestParams(pageSize, currentPage));
    }
  }, [matriz]);

  useEffect(() => {
    setFieldsByQueryParams();
  }, []);

  useEffect(() => {
    if (isTabActive && matriz?.cnpj) {
      updateRoute(getRequestParams(pageSize, 1));
    }
  }, [isTabActive]);

  async function fetchRegFiscais(params) {
    setFetching(true);

    try {
      const { data } = await getAll(params);
      const { results, count } = data;

      setDataSource(results || []);
      setTotalItems(count || 0);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar registros fiscais'));
    } finally {
      setFetching(false);
    }
  }

  async function deleteRegFiscais(params) {
    setFetching(true);
    try {
      await removeProcessedRegFiscais(params);
    } catch (error) {
      throw new Error(error);
    } finally {
      setFetching(false);
    }
  }

  async function afterUpdateItem() {
    setCurrentPage(1);
    setSelectedRows([]);
    await fetchRegFiscais(getRequestParams(pageSize, 1));
  }

  function getRequestParams(limit, skip) {
    const cnpjs = form.getFieldValue('cnpjs');
    const periodo = form.getFieldValue('periodo');
    const chave = form.getFieldValue('chave');
    const numDoc = form.getFieldValue('numDoc');

    const payload = {
      cnpjs: hasFiliais ? cnpjs : [matriz.cnpj],
      cnpjMatriz: matriz.cnpj,
      chaves: chave,
      numDoc,
      limit,
      skip: skip - 1,
    };

    if (periodo?.length === 2) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodo[0]);
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodo[1]);
    }

    return payload;
  }

  async function onFinish(values, isDownload = false) {
    setCurrentPage(1);
    const params = getRequestParams(pageSize, 1);
    updateRoute(params);

    if (isDownload) {
      setFetching(true);
      try {
        await downloadFilteredRegFiscais(params);
      } catch (error) {
        message.error(i18n.t('Erro ao realizar o download dos xmls.'));
      } finally {
        setFetching(false);
      }
    } else {
      await fetchRegFiscais(params);
    }
  }

  async function downloadRegFiscal(id) {
    try {
      await download({ id, cnpjMatriz: matriz.cnpj });
    } catch (error) {
      message.error(i18n.t('Erro ao realizar o download do registro fiscal.'));
    }
  }

  function toggleModalDetalhes(status) {
    setShowModalDetalhes(status);
  }

  function onClickViewDetalhes(current) {
    setRegistroFiscal(current);
    toggleModalDetalhes(true);
  }

  function renderMoreActions(_, row) {
    const shouldDisableDownload = shouldDisableRegFiscalDownload(row);

    return (
      <Space size={2}>
        <Tooltip title={i18n.t('Visualizar')}>
          <Button
            className="view-detalhes-button"
            type="link"
            size="small"
            onClick={() => onClickViewDetalhes(row)}
          >
            <AiOutlineFileSearch size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Baixar')}>
          <Button
            className="download-regFiscal-button"
            type="link"
            size="small"
            disabled={shouldDisableDownload}
            onClick={() => downloadRegFiscal(row._id)}
          >
            <FiDownloadCloud size={18} />
          </Button>
        </Tooltip>
      </Space>
    );
  }

  function shouldDisableRegFiscalDownload(row) {
    // ECF não tem XML, então o download deve ser desabilitado
    return row.extraFields.tipo === RegFiscalTypes.ECF;
  }

  async function copyToClipboard(text) {
    await navigator.clipboard.writeText(text);
    message.success(i18n.t('Copiado para a área de transferência'));
  }

  const columns = [
    {
      title: i18n.t('Chave ou Num. doc.'),
      dataIndex: 'chave',
      width: 500,
      filter: true,
      render: (chave, row) => (
        <div className="chave-column-container">
          <Button
            className="link-text-button"
            type="link"
            disabled={shouldDisableRegFiscalDownload(row)}
            onClick={() => downloadRegFiscal(row.id)}
          >
            {chave || row.nrRegistroFiscal}
          </Button>
          <Button
            type="text"
            onClick={() => copyToClipboard(chave || row.nrRegistroFiscal)}
          >
            <BiCopy size={18} />
          </Button>
        </div>
      ),
    },
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'extraFields',
      render: (extraFields) =>
        extraFields?.empresaCnpj && cnpjFormat(extraFields.empresaCnpj),
    },
    {
      title: i18n.t('Tipo'),
      dataIndex: ['extraFields', 'tipo'],
    },
    {
      title: i18n.t('Tipo da emissão'),
      dataIndex: ['extraFields', 'tipoEmissao'],
    },
    {
      title: i18n.t('Data de emissão'),
      dataIndex: ['extraFields', 'dataEmissao'],
      render: dateUtils.formatDate,
    },
    {
      key: 'actions',
      title: i18n.t('Baixar'),
      width: 110,
      fixed: 'right',
      render: renderMoreActions,
    },
  ];

  function onChangeTable(paginate) {
    setPageSize(paginate.pageSize);
    setCurrentPage(paginate.current);
    fetchRegFiscais(getRequestParams(paginate.pageSize, paginate.current));
  }

  function updateRoute(params) {
    if (!isTabActive || !params) {
      return;
    }

    const { chave, periodoInicio, periodoFim, cnpjs } = params;
    const queryParams = {};

    if (chave) {
      queryParams.chaves = chave;
    }

    if (periodoInicio) {
      queryParams.periodoInicio = periodoInicio;
    }

    if (periodoFim) {
      queryParams.periodoFim = periodoFim;
    }

    if (hasFiliais && cnpjs) {
      queryParams.cnpjs = cnpjs;
    }

    setSearchParams(queryParams);
  }

  function setFieldsByQueryParams() {
    const chave = searchParams.get('chave');
    const periodoInicio = searchParams.get('periodoInicio');
    const periodoFim = searchParams.get('periodoFim');
    const cnpjs = searchParams.getAll('cnpjs');

    let cnpjsByQuery;
    let periodoByQuery;

    if (periodoInicio && periodoFim) {
      periodoByQuery = [
        moment(periodoInicio, 'MM-YYYY'),
        moment(periodoFim, 'MM-YYYY'),
      ];
    }

    if (cnpjs) {
      typeof cnpjs === 'string'
        ? (cnpjsByQuery = [cnpjs])
        : (cnpjsByQuery = [...cnpjs]);
    }

    form.setFieldsValue({
      periodo: periodoByQuery,
      chave,
      cnpjs: cnpjsByQuery,
    });
  }

  const rowSelection = {
    onChange: (keysRows, rows) => {
      setSelectedRows(rows);
    },
  };

  function renderTotalItems() {
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} registros fiscais processados', {
          totalItems,
        })
        : i18n.t('{{totalItems}} registro fiscal processado', {
          totalItems,
        });

    return <span>{label}</span>;
  }

  // function openModalRemoveMultipleFiles() {
  //   const files = selectedRows.map((row) => row.fileName);

  //   setCurrentFileNames(files);
  //   toggleModalRemove(true);
  // }

  // function openModalRemoveAllFiles() {
  //   setCurrentFileNames([]);
  //   toggleModalRemove(true);
  // }

  function toggleModalRemove(status) {
    setIsVisibleModalRemove(status);
  }

  function removeFile() {
    if (!selectedRows.length) {
      return removeAllFiles();
    }

    const ids = selectedRows.map((row) => row.id);
    return deleteRegFiscais({ ids });
  }

  async function removeAllFiles() {
    const cnpjs = form.getFieldValue('cnpjs') || [matriz.cnpj];

    return deleteRegFiscais({ truncate: true, cnpjs });
  }

  // function renderDeleteSelectedButton() {
  //   return (
  //     <Row className="row-remove-row">
  //       <Col span={24}>
  //         {selectedRows.length > 0 ? (
  //           <Tooltip title="Remover apenas os registros fiscais selecionados.">
  //             <Button
  //               className="btn-remove-row"
  //               icon={<FaTrashAlt size={16} />}
  //               onClick={openModalRemoveMultipleFiles}
  //             >
  //               {i18n.t('Remover selecionados')}
  //             </Button>
  //           </Tooltip>
  //         ) : (
  //           <Tooltip title="Remover todos os registros fiscais de todos os CNPJs do filtro.">
  //             <Button
  //               className="btn-remove-row"
  //               icon={<FaTrashAlt size={16} />}
  //               onClick={openModalRemoveAllFiles}
  //               disabled={!dataSource?.length}
  //             >
  //               {i18n.t('Remover TODOS')}
  //             </Button>
  //           </Tooltip>
  //         )}
  //       </Col>
  //     </Row>
  //   );
  // }

  return (
    <div className="processed-reg-fiscal">
      <Form form={form} onFinish={onFinish}>
        <Row className="filter-options" gutter={[16, 16]}>
          {hasFiliais && (
            <Col {...cnpjsBreakpoints}>
              <Form.Item
                name="cnpjs"
              >
                <SelectEmpresa
                  allowSelectAll
                  selectAll
                  cnpjMatriz={matriz.cnpj}
                  onFetch={() =>
                    fetchRegFiscais(getRequestParams(pageSize, currentPage))
                  }
                />
              </Form.Item>
            </Col>
          )}

          <Col {...chaveBreakpoints} style={{ zIndex: 999 }}>
            <Form.Item name="chave" noStyle>
              <Input.TextArea
                placeholder={i18n.t('Insira uma lista de chaves separadas por virgula')}
                allowClear
                className="custom-text-area"
              />
            </Form.Item>
          </Col>

          <Col {...numDocBreakpoints}>
            <Form.Item name="numDoc" noStyle>
              <Input
                placeholder={i18n.t('Insira número do documento do registro fiscal')}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col {...periodoBreakpoints}>
            <Form.Item name="periodo" noStyle>
              <RangePicker
                picker="month"
                format="MM-YYYY"
                placeholder={[
                  i18n.t('Data de início'),
                  i18n.t('Data de término'),
                ]}
              />
            </Form.Item>
          </Col>

          <Col {...actionsBreakpoints}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="button-filtrar uppercase"
            >
              {i18n.t('Filtrar')}
            </Button>
          </Col>

          <Col {...actionsBreakpoints}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="button-filtrar uppercase"
              onClick={() => onFinish(null, true)}
              loading={fetching}
            >
              {i18n.t('Baixar por chaves')}
            </Button>
          </Col>
        </Row>

        <Divider />

        {/* {renderDeleteSelectedButton()} */}

        <Row>
          <Col flex="auto">
            <Table
              rowSelection={rowSelection}
              rowKey="id"
              columns={columns}
              dataSource={dataSource}
              loading={fetching}
              scroll={{ x: '130rem' }}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalItems,
                showTotal: renderTotalItems,
              }}
              onChange={onChangeTable}
            />
          </Col>
        </Row>

        <ModalRemoveFile
          files={currentFileNames}
          params={{ cnpj: matriz?.cnpj }}
          isVisible={isVisibleModalRemove}
          toggleVisible={toggleModalRemove}
          afterRemove={afterUpdateItem}
          removeFile={removeFile}
        />

        <ModalViewJson
          item={registroFiscal}
          visible={showModalDetalhes}
          toggleVisible={toggleModalDetalhes}
        />
      </Form>
    </div>
  );
}

ProcessedRegFiscal.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    filiais: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  isTabActive: Proptypes.bool,
};

ProcessedRegFiscal.defaultProps = {
  matriz: null,
  isTabActive: false,
};

export default ProcessedRegFiscal;
