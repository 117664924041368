import React from 'react';
import Proptypes from 'prop-types';

// External components
import { Input } from 'antd';

function InputCNPJ({ className, disabled, ...rest }) {
  const componentClasses = ['input-cnpj', className || ''].join(' ');

  // const numberMask = new RegExp(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/);

  // function formatter(value) {
  //   return value.replace(numberMask, '$1.$2.$3/$4-$5');
  // }

  // function parser(value) {
  //   return value.replace(/\D/g, '').substr(0, 14);
  // }

  return (
    <Input
      className={componentClasses}
      // formatter={formatter}
      // parser={parser}
      disabled={disabled}
      {...rest}
      type="number"
    />
  );
}

InputCNPJ.propTypes = {
  className: Proptypes.string,
  disabled: Proptypes.bool,
};

InputCNPJ.defaultProps = {
  className: '',
  disabled: false,
};

export default InputCNPJ;
