import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Col, Form, Input, Row } from 'antd';

// Utils
import { isMatriz } from 'app/utils/cnpj';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import InputCNPJ from 'app/components/InputCNPJ';
import SubHeader from 'app/components/Subheader';
import TabDetalhesActions from 'app/features/Empresa/EmpresaDetalhes/TabDetalhesActions';

// Styles
import './FormEmpresa.scss';

export const FormType = {
  MATRIZ: 'MATRIZ',
  FILIAL: 'FILIAL',
};

// function FormEmpresa({ empresa, requestCreate, requestEdit, saving }) {
function FormEmpresa({ onClickCreateFilial, onSuccessFiliaisImport }) {
  const { empresa, fetching, saving, requestEdit, requestCreate } =
    useEmpresa();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(empresa);
  }, [empresa]);

  function onSave() {
    const fields = form.getFieldsValue();

    if (fields.cnpj) {
      fields.isMatriz = isMatriz(fields.cnpj);
    }

    fields._id ? requestEdit(fields) : requestCreate(fields);
  }

  return (
    <Form name="form-empresa" form={form} layout="vertical" onFinish={onSave}>
      <SubHeader
        title={i18n.t('Detalhes')}
        prefix={
          <TabDetalhesActions
            empresa={empresa}
            fetching={fetching}
            saving={saving}
            onSave={onSave}
            onClickCreateFilial={onClickCreateFilial}
            onSuccessFiliaisImport={onSuccessFiliaisImport}
          />
        }
      />

      <Row gutter={16}>
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <Col span={5} xs={24}>
          <Form.Item
            name="cnpj"
            label={i18n.t('CNPJ')}
            required
            rules={[{ required: true, message: i18n.t('CNPJ é obrigatório') }]}
          >
            <InputCNPJ disabled={!!empresa._id} />
          </Form.Item>
        </Col>

        <Col span={8} xs={24}>
          <Form.Item
            name="nome"
            label={i18n.t('Nome')}
            required
            rules={[{ required: true, message: i18n.t('Nome é obrigatório') }]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8} xs={24}>
          <Form.Item name="nomeFantasia" label={i18n.t('Nome fantasia')}>
            <Input disabled={!!empresa._id} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="discordWebhook" label={i18n.t('Discord Webhook URL')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

FormEmpresa.propTypes = {
  onClickCreateFilial: Proptypes.func.isRequired,
  onSuccessFiliaisImport: Proptypes.func.isRequired,
};

export default FormEmpresa;
