export const ControleEstoqueColumn = {
  NUMERO_ORDEM: 0,
  DATA: 1,
  CHAVE: 2,
  TIPO_EMISSAO: 3,
  TIPO_DOCUMENTO: 4,
  SERIE: 5,
  NUMERO_DOCUMENTO: 6,
  CODIGO_REMETENTE_DESTINATARIO: 7,
  CFOP: 8,
  NUMERO_ITEM_DOCUMENTO: 9,
  QUANTIDADE_ENTRADA: 10,
  VALOR_TOTAL_ICMS_SUPORTADO: 11,
  QUANTIDADE_SAIDA: 12,
  VALOR_UNITARIO_ICMS_SUPORTADO: 13,
  ENQUADRAMENTO_LEGAL_1: 14,
  ENQUADRAMENTO_LEGAL_2: 15,
  ENQUADRAMENTO_LEGAL_3: 16,
  ENQUADRAMENTO_LEGAL_4: 17,
  ENQUADRAMENTO_LEGAL_0: 18,
  ICMS_EFETIVO_SAIDA: 19,
  ICMS_EFETIVO_ENTRADA: 20,
  QUANTIDADE: 21,
  VALOR_UNITARIO_SALDO: 22,
  VALOR_TOTAL_SALDO: 23,
  VALOR_RESSARCIMENTO: 24,
  VALOR_COMPLEMENTO: 25,
  ICMS_CREDITO_OPERACAO_PROPRIA: 26,
};

export const ColumnWidth = {
  [ControleEstoqueColumn.NUMERO_ORDEM]: 80,
  [ControleEstoqueColumn.DATA]: 80,
  [ControleEstoqueColumn.CHAVE]: 330,
  [ControleEstoqueColumn.TIPO_EMISSAO]: 110,
  [ControleEstoqueColumn.TIPO_DOCUMENTO]: 125,
  [ControleEstoqueColumn.SERIE]: 80,
  [ControleEstoqueColumn.NUMERO_DOCUMENTO]: 150,
  [ControleEstoqueColumn.CODIGO_REMETENTE_DESTINATARIO]: 140,
  [ControleEstoqueColumn.CFOP]: 60,
  [ControleEstoqueColumn.NUMERO_ITEM_DOCUMENTO]: 110,
  [ControleEstoqueColumn.QUANTIDADE_ENTRADA]: 80,
  [ControleEstoqueColumn.VALOR_TOTAL_ICMS_SUPORTADO]: 210,
  [ControleEstoqueColumn.QUANTIDADE_SAIDA]: 80,
  [ControleEstoqueColumn.ENQUADRAMENTO_LEGAL_1]: 195,
  [ControleEstoqueColumn.ENQUADRAMENTO_LEGAL_2]: 195,
  [ControleEstoqueColumn.ENQUADRAMENTO_LEGAL_3]: 223,
  [ControleEstoqueColumn.ENQUADRAMENTO_LEGAL_4]: 165,
  [ControleEstoqueColumn.ENQUADRAMENTO_LEGAL_0]: 216,
  [ControleEstoqueColumn.ICMS_EFETIVO_SAIDA]: 155,
  [ControleEstoqueColumn.ICMS_EFETIVO_ENTRADA]: 157,
  [ControleEstoqueColumn.QUANTIDADE]: 120,
  [ControleEstoqueColumn.VALOR_UNITARIO_SALDO]: 125,
  [ControleEstoqueColumn.VALOR_TOTAL_SALDO]: 125,
  [ControleEstoqueColumn.VALOR_RESSARCIMENTO]: 180,
  [ControleEstoqueColumn.VALOR_COMPLEMENTO]: 180,
  [ControleEstoqueColumn.ICMS_CREDITO_OPERACAO_PROPRIA]: 230,
};
