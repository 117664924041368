import Api from 'app/services/Api';
import { downloadFile } from 'app/utils/download';

export const resource = 'registros-fiscais';

export function download({ id, cnpjMatriz }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/${id}/download`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function downloadFilteredRegFiscais({ cnpjMatriz, ...params }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/downloadList`, {
    params,
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

// TODO: Is this function being used?
export function cancel(id) {
  return Api.post(`${resource}/${id}/cancel`);
}

export function getAll({ cnpjMatriz, ...params }) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}`, { params });
}

// TODO: Is this function being used?
/**
 * @param {Object} params
 * @param {string[]} params.cnpjs
 * @param {boolean} params.allCnpjs
 * @param {string} params.chave
 * @param {string=} params.dataEmissaoInicio
 * @param {string=} params.dataEmissaoFim
 * @param {number} params.limit
 * @param {number} params.page
 * @return {Promise<>}
 */
export function getRegFiscais(params) {
  return Api.get(`${resource}`, { params });
}

/**
 * @deprecated
 */
export async function downloadRegFiscalById(id) {
  return Api.get(`${resource}/${id}/download`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

/**
 * @deprecated
 * @param {Object} params
 * @param {string} params.cnpj
 * @param {number} params.limit
 * @param {number} params.page
 * @return {Promise<>}
 */
export function getPendingRegFiscalFiles(params) {
  return Api.get(`${resource}/pending/files`, { params });
}

/**
 * @deprecated
 * @param {Object} params
 * @param {string} params.cnpj
 * @param {string} params.file File name
 * @return {Promise<>}
 */
export function removePendingRegFiscalFiles(params) {
  return Api.delete(`${resource}/pending/files`, { params });
}

/**
 * @deprecated
 */
export function removeProcessedRegFiscais(params) {
  return Api.delete(resource, { params });
}
