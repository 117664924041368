import React from 'react';
import { Outlet } from 'react-router-dom';

// External components
import { Layout as AntdLayout } from 'antd';

// Context
import EmpresaProvider from 'app/contexts/EmpresaContext';

// Styles
import './Layout.scss';

// Components
import Header from 'app/components/Header';
import Sidebar from 'app/components/Sidebar';

const { Content } = AntdLayout;

function Layout() {
  return (
    <EmpresaProvider>
      <AntdLayout className="layout">
        <Sidebar />

        <AntdLayout>
          <Header />

          <Content className="page-body">
            <div className="page-content">
              <Outlet />
            </div>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </EmpresaProvider>
  );
}

export default Layout;
