function getFileName({ headers }) {
  return headers['content-disposition']?.split('filename=')?.pop() || '';
}

function getFile(response) {
  const { data } = response;
  const name = getFileName(response);
  const type = 'application/octet-stream';

  return {
    name,
    file: new File([data], name, { type }),
  };
}

export function downloadFile(response) {
  const { file, name } = getFile(response);

  const objectUrl = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = objectUrl;
  link.download = name;
  link.click();
}
