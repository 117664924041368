import Api from './Api';

const resource = 'processos';

export function getTypes() {
  return Api.get(`${resource}/types`);
}

export function getFlags() {
  return Api.get(`${resource}/flags`);
}

export function getAll(params) {
  return Api.get(`${resource}`, { params });
}

export function schedule(payload) {
  return Api.post(`${resource}/apuracao/schedule`, payload);
}

export function cancel(processoId) {
  return Api.post(`${resource}/${processoId}/cancel`);
}
