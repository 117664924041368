import React, { memo } from 'react';
import Proptypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// Icons
import { MdArrowBack } from 'react-icons/md';

// External components
import { Button } from 'antd';

// BackButton styles
import './BackButton.scss';

function BackButton({ onClick }) {
  const navigate = useNavigate();

  const handleClick = () => {
    onClick && onClick();
    navigate(-1);
  };

  return (
    <Button className="back-button" onClick={handleClick}>
      <MdArrowBack size={18} />
    </Button>
  );
}

BackButton.propTypes = {
  onClick: Proptypes.func,
};

BackButton.defaultProps = {
  onClick: null,
};

export default memo(BackButton);
