import { useContext } from 'react';

// Context
import { EmpresaContext } from 'app/contexts/EmpresaContext/EmpresaContext';

export default function useEmpresa() {
  const context = useContext(EmpresaContext);

  if (!context) {
    throw new Error('useEmpresa must be used within a EmpresaProvider');
  }

  return {
    ...context,
  };
}
