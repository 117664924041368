// ReprocessModal.js

import React from 'react';
import { Modal, Form, DatePicker } from 'antd';
import i18n from 'i18n';
import PropTypes from 'prop-types';


function ReprocessModal({
  title,
  visible,
  isLoading,
  onCancel,
  onConfirmation,
  form,
  confirmationText,
}) {
  return (
    <Modal
      className="modal-confirmation"
      title={i18n.t('ATENÇÃO')}
      okText={title}
      cancelText={i18n.t('Cancelar')}
      width={480}
      visible={visible}
      closable={!isLoading}
      maskClosable={!isLoading}
      confirmLoading={isLoading}
      cancelButtonProps={{ disabled: isLoading }}
      onOk={onConfirmation}
      onCancel={onCancel}
    >
      <Form form={form}>
        <div className="modal-content">
          <p style={{ textAlign: 'justify' }}>
            {confirmationText}
          </p>
          <div style={{ marginTop: '24px' }}>
            <p>{i18n.t('Selecione o período, ou deixe vazio para restaurar todos os registros.')}</p>
            <div style={{ marginBottom: '8px' }}>
              <label htmlFor="periodoInicio">{i18n.t('A partir de:')}</label>
              <Form.Item name="periodoInicio" noStyle>
                <DatePicker id="periodoInicio" picker="month" format="MM-YYYY" />
              </Form.Item>
            </div>
            <div style={{ marginTop: '8px' }}>
              <label htmlFor="periodoFim">{i18n.t('Até:')}</label>
              <Form.Item name="periodoFim" noStyle>
                <DatePicker id="periodoFim" picker="month" format="MM-YYYY" />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

ReprocessModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirmation: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  confirmationText: PropTypes.string.isRequired,
};

export default ReprocessModal;
