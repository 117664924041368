import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Icons
import { BiDownload } from 'react-icons/bi';

// External components
import { Modal, Input, Spin, Row, Button, Space } from 'antd';

// Styles
import './ModalArquivoDigital.scss';

function ModalArquivoDigital({
  cnpj,
  periodo,
  isVisible,
  fetching,
  toggleVisible,
  download,
  onFetch,
}) {
  const { empresa } = useEmpresa();

  // States
  const [arquivoDigital, setArquivoDigital] = useState(null);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    fetchArquivoDigital({ cnpj, periodo, cnpjMatriz: empresa.cnpjMatriz });
  }, [isVisible]);

  async function fetchArquivoDigital(params) {
    const data = await onFetch(params);
    setArquivoDigital(data);
  }

  function closeModal() {
    setArquivoDigital(null);
    toggleVisible(false);
  }

  function renderFooter() {
    if (!download) {
      return null;
    }

    return (
      <Row justify="end">
        <Button
          className="download-button uppercase"
          type="primary"
          disabled={fetching}
          onClick={() => download({ cnpj, periodo })}
        >
          <Space size={12} align="center">
            <BiDownload size={18} />
            <span>{i18n.t('Baixar')}</span>
          </Space>
        </Button>
      </Row>
    );
  }

  return (
    <Modal
      className="modal-arquivo-digital"
      title={i18n.t('Detalhes do arquivo digital')}
      visible={isVisible}
      width={900}
      footer={renderFooter()}
      onCancel={closeModal}
    >
      <Spin spinning={fetching}>
        <Input.TextArea
          autoSize={{ maxRows: 30 }}
          value={arquivoDigital?.raw}
          bordered={false}
          readOnly
        />
      </Spin>
    </Modal>
  );
}

ModalArquivoDigital.propTypes = {
  cnpj: Proptypes.string,
  periodo: Proptypes.string,
  isVisible: Proptypes.bool.isRequired,
  fetching: Proptypes.bool,
  toggleVisible: Proptypes.func.isRequired,
  download: Proptypes.func,
  onFetch: Proptypes.func,
};

ModalArquivoDigital.defaultProps = {
  cnpj: null,
  periodo: null,
  fetching: false,
  download: null,
  onFetch: null,
};

export default ModalArquivoDigital;
