export const ScheduleStatusEnum = {
  PENDENTE: 'PENDENTE',
  PROCESSANDO: 'PROCESSANDO',
  CONCLUIDO: 'CONCLUIDO',
  CANCELADO: 'CANCELADO',
  ERRO: 'ERRO',
};

export const ScheduleStatusConfig = {
  [ScheduleStatusEnum.PENDENTE]: {
    color: 'warning',
  },
  [ScheduleStatusEnum.PROCESSANDO]: {
    color: 'processing',
  },
  [ScheduleStatusEnum.CONCLUIDO]: {
    color: 'success',
  },
  [ScheduleStatusEnum.CANCELADO]: {
    color: 'default',
  },
  [ScheduleStatusEnum.ERRO]: {
    color: 'error',
  },
};
