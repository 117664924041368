import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Form, DatePicker } from 'antd';

const { RangePicker } = DatePicker;

function RangepickerFilter({ formGroup, filter }) {
  const { id, label, required } = filter;

  return (
    <Form.Item
      name={[...formGroup, id]}
      label={label || id}
      rules={[{ required, message: i18n.t('Campo obrigatório') }]}
    >
      <RangePicker
        picker="month"
        format="MM-YYYY"
        placeholder={[i18n.t('Data de início'), i18n.t('Data de término')]}
      />
    </Form.Item>
  );
}

RangepickerFilter.propTypes = {
  filter: Proptypes.shape({
    id: Proptypes.string.isRequired,
    type: Proptypes.string.isRequired,
    label: Proptypes.string,
    required: Proptypes.bool,
  }).isRequired,
  formGroup: Proptypes.arrayOf(Proptypes.string),
};

RangepickerFilter.defaultProps = {
  formGroup: [],
};

export default RangepickerFilter;
