import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Icons
import { AiOutlineCloseCircle } from 'react-icons/ai';

// External components
import { Modal, Tree } from 'antd';

// Styles
import './ModalErrosArquivoDigital.scss';

const { DirectoryTree } = Tree;

export function buildArquivoDigitalTree(arquivoDigital) {
  const { regErrors } = arquivoDigital || {};

  return Object.keys(regErrors).map((bloco, blocoIndex) => ({
    title: <b>{i18n.t('Bloco {{ bloco }}', { bloco })}</b>,
    key: `${blocoIndex}-${bloco}`,
    children: regErrors[bloco].map((blocoErro, blocoErroIndex) => ({
      title: blocoErro.raw,
      key: `${blocoIndex}-${blocoErroIndex}-${blocoErro.raw}`,
      children: blocoErro.errorsMsg.map((errorMsg, errorMsgIndex) => ({
        title: errorMsg,
        key: `${blocoIndex}-${blocoErroIndex}-${errorMsgIndex}-${errorMsg}`,
        isLeaf: true,
        icon: <AiOutlineCloseCircle />,
      })),
    })),
  }));
}

function ModalErrosArquivoDigital({
  arquivoDigital,
  isVisible,
  toggleVisible,
}) {
  // States
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    if (!arquivoDigital) {
      return;
    }

    setTreeData(buildArquivoDigitalTree(arquivoDigital));
  }, [arquivoDigital]);

  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-erros-arquivo-digital"
      title={i18n.t('Detalhes dos erros do arquivo digital')}
      visible={isVisible}
      width={900}
      footer={null}
      onCancel={closeModal}
    >
      <DirectoryTree treeData={treeData} height={550} />
    </Modal>
  );
}

ModalErrosArquivoDigital.propTypes = {
  arquivoDigital: Proptypes.shape({
    regErrors: Proptypes.shape({}),
    raw: Proptypes.string,
  }),
  isVisible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
};

ModalErrosArquivoDigital.defaultProps = {
  arquivoDigital: {
    raw: '',
    viewNotepad: false,
  },
};

export default ModalErrosArquivoDigital;
