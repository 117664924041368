import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiCopy } from 'react-icons/bi';

// External components
import { Button, Space, Popover } from 'antd';
import Title from 'antd/lib/typography/Title';

// Styles
import './EmpresaSubheader.scss';

// Utils
import { cnpjFormat } from 'app/utils/masks';

// Components
import OpenGoogleDrive from 'app/components/OpenGoogleDrive';

export const POPOVER_TIMEOUT = 1000;

function EmpresaSubheader({ matriz }) {
  const { cnpj, nome, nomeFantasia, googleDriveId } = matriz;
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, POPOVER_TIMEOUT);
  };

  return (
    <div className="empresa-subheader">
      <Space size={12}>
        <Title level={5} className="text-align-left">
          {nome || nomeFantasia}
        </Title>

        {googleDriveId && <OpenGoogleDrive googleDriveId={googleDriveId} />}
      </Space>

      <div className="matriz-cnpj">
        <CopyToClipboard text={cnpj} onCopy={onCopy}>
          <Popover
            content={i18n.t('CNPJ copiado')}
            placement="right"
            visible={copied}
          >
            <Button className="copy-cnpj-button" htmlType="button" type="link">
              <Space size={2}>
                {cnpjFormat(cnpj)} <BiCopy color="#1890ff" />
              </Space>
            </Button>
          </Popover>
        </CopyToClipboard>
      </div>
    </div>
  );
}

EmpresaSubheader.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    nome: Proptypes.string,
    nomeFantasia: Proptypes.string,
    googleDriveId: Proptypes.string,
  }).isRequired,
};

export default EmpresaSubheader;
