import React from 'react';
import PropTypes from 'prop-types';
import { MdInfoOutline } from 'react-icons/md';
import { Popover } from 'antd';

// Componente de ícone reutilizável com Popover
function InfoIconWithPopover({ color, size, content }) {
  return (
    <Popover content={content}>
      <MdInfoOutline style={{ color, fontSize: size, cursor: 'pointer', marginRight: '5px' }} />
    </Popover>
  );
}

InfoIconWithPopover.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  content: PropTypes.node, // Conteúdo do Popover
};

InfoIconWithPopover.defaultProps = {
  color: '#1890ff', // Cor padrão
  size: '20px',     // Tamanho padrão
  content: 'Informação adicional', // Conteúdo padrão do Popover
};

export default InfoIconWithPopover;
