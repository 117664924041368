import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, matchRoutes } from 'react-router-dom';
import Proptypes from 'prop-types';

// External components
import { Menu } from 'antd';

// Icons
import { BiBuilding } from 'react-icons/bi';

// Hooks
import useAuth from 'app/hooks/useAuth';
import useEmpresa from 'app/hooks/useEmpresa';

// Routes
import { routes, adminMenu, empresaMenu, rootMenu } from './SidebarRoutes';

// Styles
import './SidebarMenu.scss';

function SidebarMenu({ collapsed, className, ...rest }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin } = useAuth();
  const { empresa } = useEmpresa();

  // States
  const [currentPage, setCurrentPage] = useState(null);

  let itemsMenu = rootMenu;

  if (isAdmin) {
    itemsMenu = [...adminMenu, ...itemsMenu];
  }

  if (empresa) {
    const empresaItem = [
      {
        key: empresa.cnpj,
        label: empresa.nome,
        icon: <BiBuilding size={16} />,
        children: empresaMenu,
      },
    ];

    itemsMenu = [...itemsMenu, ...empresaItem];
  }

  useEffect(() => {
    // Selected route ou nested route based in the `route.key`
    const matchRoute = matchRoutes(routes, location);

    if (!matchRoute?.length) {
      console.warn('Route not found', location.pathname);
      return;
    }

    const [{ route }] = matchRoute;

    setCurrentPage(route.key);
  }, [location]);

  function onClick({ key, domEvent }) {
    const { path, context } = routes.find((route) => route.key === key);

    const cmdClick = domEvent.metaKey;
    const ctrlClick = domEvent.ctrlKey;
    const middleClick = domEvent.button === 1;

    const shouldOpenInANewTab = cmdClick || ctrlClick || middleClick;

    if (shouldOpenInANewTab) {
      const url = path.replace(':id', empresa._id);
      window.open(url);
      return;
    }

    let route = path;

    if (context === 'empresa') {
      route = route.replace(':id', empresa._id);
    }

    navigate(route);
  }

  const componentClasses = [
    'sidebar-menu',
    className || '',
    collapsed ? 'collapsed' : '',
  ].join(' ');

  return (
    <Menu
      mode="inline"
      className={componentClasses}
      items={itemsMenu}
      selectedKeys={[currentPage]}
      onClick={onClick}
      {...rest}
    />
  );
}

SidebarMenu.propTypes = {
  collapsed: Proptypes.bool,
  className: Proptypes.string,
};

SidebarMenu.defaultProps = {
  collapsed: false,
  className: '',
};

export default SidebarMenu;
