import React from 'react';
import Proptypes from 'prop-types';
import Title from 'antd/lib/typography/Title';

// Components
import BackButton from 'app/components/BackButton';

// SubHeader styles
import './Subheader.scss';

export default function Subheader({
  title,
  subtitle,
  level,
  divider,
  prefix,
  prefix2,
  backButtonVisible,
}) {
  return (
    <header className="subheader">
      {backButtonVisible && <BackButton />}

      <Title level={4} className="subheader__title">
        {title}
      </Title>

      {subtitle && (
        <>
          <span className="subheader__divider">{divider}</span>

          <Title level={level} className="subheader__subtitle">
            {subtitle}
          </Title>
        </>
      )}

      <div className="subheader__actions">
        <div className="subheader__actions__left">{prefix}</div>
        <div className="subheader__actions__right">{prefix2}</div>
      </div>
    </header>
  );
}

Subheader.propTypes = {
  title: Proptypes.oneOfType([Proptypes.string, Proptypes.element]).isRequired,
  subtitle: Proptypes.string,
  divider: Proptypes.string,
  prefix: Proptypes.element,
  prefix2: Proptypes.element,
  level: Proptypes.number,
  backButtonVisible: Proptypes.bool,
};

Subheader.defaultProps = {
  subtitle: '',
  divider: '-',
  prefix: null,
  prefix2: null,
  level: 5,
  backButtonVisible: false,
};
