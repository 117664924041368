import Api from 'app/services/Api';

// Utils
import { downloadFile } from 'app/utils/download';

const resource = 'apuracao';

export function resultadosApuracao(params) {
  return Api.get(`empresas/${params.cnpjMatriz}/${resource}/resultados`, {
    params,
  });
}

export function resultadosApuracaoTotal({
  cnpjs,
  produtos,
  periodoInicio,
  periodoFim,
  cnpjMatriz,
}) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/resultados/total`, {
    params: { cnpjs, produtos, periodoInicio, periodoFim },
  });
}

export function downloadResultados({
  cnpjs,
  produtos,
  periodoInicio,
  periodoFim,
  page,
  limit,
  visaoPor,
  groups,
  cnpjMatriz,
}) {
  return Api.get(`empresas/${cnpjMatriz}/${resource}/resultados/download`, {
    params: {
      cnpjs,
      produtos,
      periodoInicio,
      periodoFim,
      page,
      limit,
      visaoPor,
      groups,
    },
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

/**
 * @param {Object} params
 * @param {Object} params.cnpj
 * @param {Object} params.cProd Código do produto
 * @param {Object} params.periodo
 * @return {Promise<any>}
 */
export function downloadFicha3(params) {
  return Api.get(`empresas/${params.cnpjMatriz}/${resource}/ficha3/download`, {
    params,
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function getFicha3(params) {
  return Api.get(`empresas/${params.cnpjMatriz}/${resource}/ficha3`, {
    params,
  });
}

export function downloadFicha3Unificadas(params) {
  return Api.get(`empresas/${params.cnpjMatriz}/${resource}/ficha3/unificadas`, {
    params,
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function resetApuracao({ cnpjMatriz, payload }) {
  return Api.delete(`empresas/${cnpjMatriz}/${resource}/reset`, {
    params: { ...payload },
  });
}
