import React from 'react';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import UserMenu from 'app/components/UserMenu';
import MatrizDetailsSubHeader from 'app/components/EmpresaSubheader';

// Header styles
import './Header.scss';

export default function Header() {
  const { empresa } = useEmpresa();
  let style;
  if (process.env.NODE_ENV !== 'production') {
    style = { backgroundColor: "#59667a", color: "#FFFFFF", }
  }

  return (
    <header className="header" style={style}>
      {empresa && <MatrizDetailsSubHeader matriz={empresa} />}

      <div className="header__right">
        <UserMenu />
      </div>
    </header>
  );
}
