import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18next';

// Icons
import { FiUploadCloud } from 'react-icons/fi';

// External components
import { Divider, Space, Upload, message } from 'antd';

// Uploader styles
import './Uploader.scss';

const { Dragger } = Upload;

export const UPLOAD_STATUS = {
  DONE: 'done',
  ERROR: 'error',
  SUCCESS: 'success',
  REMOVED: 'removed',
  UPLOADING: 'uploading',
};

export const FileTypes = {
  rar: {
    label: '.rar',
    mimeType: 'application/x-rar-compressed, application/octet-stream',
  },
  zip: {
    label: '.zip',
    mimeType:
      'application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip',
  },
  xlsx: {
    label: '.xlsx',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  },
  txt: {
    label: '.txt',
    mimeType: 'text/*',
  },
  xml: {
    label: '.xml',
    mimeType: 'application/xml, text/xml',
  },
  // Some files sent by the empresa are coming without mimetype (e.g.: .rar)
  withoutMimeType: {
    label: '',
    mimeType: '',
  },
};

export default function Uploader({
  uploadingUrl,
  fileName,
  maxFileSizeInMega,
  multiple,
  showUploadList,
  filesAccepted,
  headers,
  additionalData,
  onSuccess,
}) {
  const mimeTypes = filesAccepted.map((file) => file.mimeType).join(', ');

  const fileSizeFormatted = (size) => `${size.toFixed(0)}mb`;

  async function beforeUpload(file) {
    const isFileSmaller = file.size / 1024 / 1024 < maxFileSizeInMega;

    if (!isFileSmaller) {
      const fileSize = fileSizeFormatted(maxFileSizeInMega);

      await message.error(i18n.t(`Arquivo deve ser menor que ${fileSize}`));
    }

    return isFileSmaller;
  }

  function onChange(info) {
    const { status } = info.file;

    if (status !== UPLOAD_STATUS.UPLOADING) {
      console.info(info.file, info.fileList);
    }

    if (status === UPLOAD_STATUS.DONE) {
      onSuccess && onSuccess(info);

      return message.success(
        `Upload do arquivo ${info.file.name} realizado com sucesso`,
      );
    }

    if (status === UPLOAD_STATUS.ERROR) {
      return message.error(`Erro ao subir o arquivo ${info.file.name}.`);
    }

    return info;
  }

  function filesLabel() {
    if (!filesAccepted.length) {
      return i18n.t('All files');
    }

    return filesAccepted.map((file) => file.label).join(', ');
  }

  const action = `${process.env.REACT_APP_API_URL}/${uploadingUrl}`;

  return (
    <div className="uploader">
      <Dragger
        name={fileName}
        action={action}
        accept={mimeTypes}
        multiple={multiple}
        showUploadList={showUploadList}
        beforeUpload={beforeUpload}
        headers={headers}
        onChange={onChange}
        data={() => ({ ...additionalData })}
      >
        <Space className="uploader__content" size={12} direction="vertical">
          <div className="icon">
            <FiUploadCloud size={22} />
          </div>
          <span>{i18n.t('Clique ou arraste o arquivo para esta área')}</span>
        </Space>
      </Dragger>

      <div className="uploader__info">
        <span className="property">{i18n.t('Arquivos suportados')}:</span>
        <span className="value">{filesLabel()}</span>

        <Divider type="vertical" />

        <span className="property">{i18n.t('Tamanho máximo')}:</span>
        <span className="value">{fileSizeFormatted(maxFileSizeInMega)}</span>
      </div>
    </div>
  );
}

Uploader.propTypes = {
  uploadingUrl: Proptypes.string,
  fileName: Proptypes.string,
  maxFileSizeInMega: Proptypes.number,
  multiple: Proptypes.bool,
  showUploadList: Proptypes.bool,
  filesAccepted: Proptypes.arrayOf(
    Proptypes.shape({
      label: Proptypes.string,
      mimeType: Proptypes.string.isRequired,
    }),
  ),
  headers: Proptypes.object, // eslint-disable-line react/forbid-prop-types
  additionalData: Proptypes.object, // eslint-disable-line react/forbid-prop-types
  onSuccess: Proptypes.func,
};

Uploader.defaultProps = {
  uploadingUrl: process.env.REACT_APP_API_URL,
  fileName: 'file',
  maxFileSizeInMega: 10,
  multiple: true,
  showUploadList: true,
  filesAccepted: [
    {
      label: '.xls',
      mimeType: 'application/vnd.ms-excel',
    },
    {
      label: '.xlsx',
      mimeType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  ],
  headers: {},
  additionalData: {},
  onSuccess: () => null,
};
