import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import i18n from 'i18n';

// External components
import {
  Button,
  Card,
  Form,
  Modal,
  notification,
  DatePicker,
  Alert,
  Divider,
} from 'antd';
import { ImWarning } from 'react-icons/im';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import { resetEstoque } from 'app/services/EstoqueService';

// Utils
import * as dateUtils from 'app/utils/date';

// Components
import SubHeader from 'app/components/Subheader';
import EstoqueTable from 'app/components/EstoqueTable';
import SelectEmpresa from 'app/components/Select/SelectEmpresa/SelectEmpresa';
import SelectProduto from 'app/components/Select/SelectProduto/SelectProduto';

// Query Key Factory
import { estoqueKeyFactory } from './queryKeyFactory';

// Styles
import './EmpresaEstoque.scss';

const { RangePicker } = DatePicker;

function EmpresaEstoque() {
  const [form] = Form.useForm();
  const [buttonOkDisabled, setButtonOkDisabled] = useState(true);

  const { empresa } = useEmpresa();
  const cnpjMatriz = empresa?.cnpjMatriz;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const deleteAllMutation = useMutation(
    (payload) => resetEstoque({ cnpjMatriz, payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          estoqueKeyFactory.allFromRede(cnpjMatriz),
        );
      },
      onError: () => {
        notification.error({
          message: i18n.t('Erro ao apagar estoque'),
        });
      },
    },
  );

  function openModal() {
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  async function onModalConfirmation() {
    const periodo = form.getFieldValue('periodo');
    const produtos = form.getFieldValue('produtos');
    const cnpjs = form.getFieldValue('cnpjs');
    const payload = {};

    if (periodo?.length === 2) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodo[0]);
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodo[1]);
    }

    if (cnpjs?.length) {
      payload.cnpjs = cnpjs
    }

    if (produtos?.length) {
      payload.cProds = produtos
    }

    await deleteAllMutation.mutateAsync(payload);

    form.resetFields();

    notification.info({
      message: i18n.t('Registros apagados com sucesso.'),
    });

    hideModal();
  }

  return (
    <Card className="empresa-estoque">
      <SubHeader
        title={i18n.t('Estoque importado')}
        prefix={
          <>
            <Button
              type="default"
              danger
              className="uppercase"
              loading={deleteAllMutation.isLoading}
              onClick={openModal}
            >
              {i18n.t('Resetar estoque')}
            </Button>

            <Modal
              className="modal-confirmation"
              title={i18n.t('ATENÇÃO')}
              okText={i18n.t('Resetar estoque')}
              cancelText={i18n.t('Cancelar')}
              width={450}
              visible={isModalOpen}
              closable={!deleteAllMutation.isLoading}
              maskClosable={!deleteAllMutation.isLoading}
              confirmLoading={deleteAllMutation.isLoading}
              cancelButtonProps={{ disabled: deleteAllMutation.isLoading }}
              onOk={onModalConfirmation}
              okButtonProps={{ disabled: buttonOkDisabled }}
              onCancel={hideModal}
            >
              <Form
                form={form}
                onFieldsChange={() =>
                  setButtonOkDisabled(form.getFieldValue('periodo')?.length !== 2)
                }
              >
                <p>
                  Deseja apagar os dados de estoque importados via planilha?
                </p>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="periodo">Selecione o período:</label>
                  <Form.Item name="periodo" noStyle>
                    <RangePicker
                      picker="month"
                      format="MM-YYYY"
                      placeholder={[
                        i18n.t('Data de início'),
                        i18n.t('Data de término'),
                      ]}
                    />
                  </Form.Item>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="cnpjs">Selecione os CNPJs:</label>
                  <Form.Item name="cnpjs"
                    required
                    rules={[
                      {
                        required: true,
                        message: i18n.t('Filtro de CNPJ é obrigatório'),
                      },
                    ]}>
                    <SelectEmpresa
                      allowSelectAll
                      selectAll
                      cnpjMatriz={empresa?.cnpj}
                    />
                  </Form.Item>
                </div>

                <div style={{ marginBottom: '24px' }}>
                  <label htmlFor="produtos">Selecione os cProds (caso nenhum cProd seja informado, todos serão apagados):</label>
                  <Form.Item name="produtos">
                    <SelectProduto cnpjs={[empresa?.cnpj]} />
                  </Form.Item>
                </div>
              </Form>


            </Modal>
          </>
        }
      />

      <Alert
        message="Aqui você pode visualizar e apagar os estoques e icms total inicial importados via planilha."
        type="info"
        showIcon
      />

      <Divider />

      <EstoqueTable matriz={empresa} />
    </Card>
  );
}

export default EmpresaEstoque;
