import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Styles
import './ReportScheduleTable.scss';

// External components
import { Button, Col, message, Modal, Row, Space, Tooltip } from 'antd';

// Enums
import { ScheduleStatusEnum } from 'app/constants/ScheduleTypesConstants';

// Icons
import { FiDownloadCloud } from 'react-icons/fi';
import { AiOutlineFileSearch, AiOutlineStop } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';
import { VscDebugRerun } from 'react-icons/vsc';

// Utils
import { formatFullDate } from 'app/utils/date';

// Services
import { download, reschedule, cancelReport } from 'app/services/ReportService';

// Components
import ModalDetalhesRelatorio from 'app/components/ModalDetalhesRelatorio';
import ReportScheduleStatusTag from 'app/components/ScheduleStatusTag';
import Table from 'app/components/Table';
import Subheader from 'app/components/Subheader';
import { ImWarning } from 'react-icons/im';

const { PENDENTE, PROCESSANDO, CONCLUIDO, CANCELADO } = ScheduleStatusEnum;

function Actions({
  row,
  onClickViewDetalhesRelatorio,
  requestReschedule,
  requestCancel,
  requestDownload,
}) {
  const [isDownloading, setIsDownloading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const canDownload = row.status === CONCLUIDO;
  const canReschedule = [CONCLUIDO, CANCELADO].includes(row.status);
  const canCancel = [PENDENTE, PROCESSANDO].includes(row.status);

  async function manageDownload() {
    setIsDownloading(true);
    await requestDownload(row);
    setIsDownloading(false);
  }

  function openModal() {
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsModalOpen(false);
  }

  return (
    <Space size={2}>
      <Tooltip title={i18n.t('Visualizar detalhes')}>
        <Button
          className="view-detalhes-relatorio-button"
          type="link"
          size="small"
          onClick={() => onClickViewDetalhesRelatorio(row)}
        >
          <AiOutlineFileSearch size={18} />
        </Button>
      </Tooltip>

      <Tooltip title={i18n.t('Reagendar processamento')}>
        <Button
          className="process-reschedule-button"
          type="link"
          size="small"
          disabled={!canReschedule}
          onClick={() => requestReschedule(row)}
        >
          <VscDebugRerun size={18} />
        </Button>
      </Tooltip>

      <Tooltip title={i18n.t('Cancelar processamento')}>
        <Button
          className="process-cancel-button"
          type="link"
          size="small"
          disabled={!canCancel}
          onClick={() => openModal(row)}
        >
          <AiOutlineStop size={18} />
        </Button>
      </Tooltip>
      <Modal
        className="modal-confirmation"
        title={i18n.t('Atenção')}
        okText={i18n.t('Sim')}
        cancelText={i18n.t('Não')}
        width={450}
        visible={isModalOpen}
        onOk={() => {
          requestCancel(row);
          hideModal();
        }}
        onCancel={hideModal}
      >
        <Space size={13}>
          <ImWarning size={30} />
          {i18n.t('Você realmente deseja cancelar esse processo?')}
        </Space>
      </Modal>

      <Tooltip title={i18n.t('Baixar')}>
        <Button
          className="download-relatorio-button"
          type="link"
          size="small"
          disabled={!canDownload || isDownloading}
          loading={isDownloading}
          onClick={manageDownload}
        >
          {!isDownloading && <FiDownloadCloud size={18} />}
        </Button>
      </Tooltip>
    </Space>
  );
}

function ReportScheduleTable({ reports, loading, refetch, onCancel }) {
  const [report, setReport] = useState(null);
  const [showModalDetalhesRelatorio, setShowModalDetalhesRelatorio] =
    useState(false);

  function toggleModalDetalhesRelatorio(status) {
    setShowModalDetalhesRelatorio(status);
  }

  function onClickViewDetalhesRelatorio(currentReport) {
    setReport(currentReport);
    toggleModalDetalhesRelatorio(true);
  }

  async function requestReschedule(currentReport) {
    try {
      await reschedule(currentReport._id);
      message.success(i18n.t('Reagendamento realizado com sucesso'));
      refetch?.();
    } catch (error) {
      message.error(i18n.t('Erro ao realizar reagendamento do relatório'));
    }
  }

  async function requestCancel(currentReport) {
    try {
      await cancelReport(currentReport._id);
      message.success(
        i18n.t('Processamento do relatório cancelado com sucesso'),
      );
      onCancel?.();
    } catch (error) {
      message.error(i18n.t('Erro ao cancelar processamento de relatório'));
    }
  }

  async function requestDownload(currentReport) {
    try {
      await download(currentReport._id);
      message.success(i18n.t('Arquivo baixado com sucesso'));
    } catch (error) {
      message.error(i18n.t('Erro ao baixar arquivo'));
    }
  }

  const columns = [
    {
      title: i18n.t('Tipo'),
      dataIndex: 'type',
      key: 'type',
      render: (type, row) => (
        <Button
          className="tipo-relatorio-button link-text-button"
          htmlType="button"
          type="link"
          onClick={() => onClickViewDetalhesRelatorio(row)}
        >
          {type}
        </Button>
      ),
    },
    {
      title: i18n.t('Atualizado em'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: formatFullDate,
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <ReportScheduleStatusTag status={status} />,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (data, row) => (
        <Actions
          row={row}
          onClickViewDetalhesRelatorio={onClickViewDetalhesRelatorio}
          requestReschedule={requestReschedule}
          requestCancel={requestCancel}
          requestDownload={requestDownload}
        />
      ),
    },
  ];

  return (
    <div className="report-schedule-table">
      <Subheader
        title={i18n.t('Processamentos')}
        prefix={
          <Button onClick={refetch}>
            <IoMdRefresh size={18} />
          </Button>
        }
      />

      <Row>
        <Col span={24}>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={reports}
            isLoading={loading}
            scroll={{ x: '100%' }}
            data
          />
        </Col>
      </Row>

      <ModalDetalhesRelatorio
        report={report}
        visible={showModalDetalhesRelatorio}
        toggleVisible={toggleModalDetalhesRelatorio}
      />
    </div>
  );
}

ReportScheduleTable.propTypes = {
  reports: Proptypes.array,
  loading: Proptypes.bool,
  refetch: Proptypes.func,
  onCancel: Proptypes.func,
};

ReportScheduleTable.defaultProps = {
  reports: [],
  loading: false,
  refetch: null,
  onCancel: null,
};

Actions.propTypes = {
  row: Proptypes.object,
  onClickViewDetalhesRelatorio: Proptypes.func,
  requestReschedule: Proptypes.func,
  requestCancel: Proptypes.func,
  requestDownload: Proptypes.func,
};

Actions.defaultProps = {
  row: () => {},
  onClickViewDetalhesRelatorio: () => {},
  requestReschedule: () => {},
  requestCancel: () => {},
  requestDownload: () => {},
};

export default ReportScheduleTable;
