import React from 'react';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';

// Icons
import { BiUser, BiLogOut } from 'react-icons/bi';
import { BsCaretDown } from 'react-icons/bs';

// External components
import { Dropdown, Menu } from 'antd';

// UserMenu styles
import './UserMenu.scss';

// Hooks
import useAuth from 'app/hooks/useAuth';

export default function UserMenu() {
  const navigate = useNavigate();
  const { getAuth, logout } = useAuth();

  const auth = getAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const onClick = ({ key }) => {
    if (key === 'logout') {
      handleLogout();
    }
  };

  const renderUserMenuDropdown = () => {
    const items = [
      {
        key: 'logout',
        label: i18n.t('Logout'),
        icon: <BiLogOut size={20} />,
      },
    ];

    return <Menu items={items} onClick={onClick} />;
  };

  return (
    <Dropdown
      overlay={renderUserMenuDropdown}
      overlayClassName="user-menu-dropdown"
      placement="bottomRight"
      trigger={['click']}
    >
      <div className="user-menu">
        <div className="user-menu__icon">
          <BiUser size={22} />
        </div>

        <div className="user-menu__detail">
          <span className="user-menu__name">{auth?.user?.name}</span>

          <span className="user-menu__user-type">
            {auth?.user?.email} <BsCaretDown />
          </span>
        </div>
      </div>
    </Dropdown>
  );
}
