import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Button, Col, DatePicker, Form, Row, Grid } from 'antd';

// Enums
import { ViewApuracaoBy } from 'app/constants/Enumerators';

// Components
import SelectProduto from 'app/components/Select/SelectProduto';
import SelectEmpresa from 'app/components/Select/SelectEmpresa';

// Styles
import './ApuracaoFilterOptions.scss';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

function ApuracaoFilterOptions({
  matriz,
  onFetchEmpresas,
  fetching,
  viewApuracaoBy,
}) {
  const breakpoint = useBreakpoint();
  const [hasFiliais] = useState(matriz?.filiais?.length > 0);

  useEffect(() => {
    // Se não tiver filiais devemos carregar os resultados
    // sem aguardar a request do <SelectEmpresas />
    if (!hasFiliais) {
      onFetchEmpresas();
    }
  }, [hasFiliais]);

  function getBreakpoints() {
    if (viewApuracaoBy === ViewApuracaoBy.PRODUTO) {
      const produtoBreakpoints = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: hasFiliais ? 7 : 12,
      };

      const cnpjsBreakpoints = {
        xs: 24,
        sm: 24,
        md: 24,
        lg: 7,
      };

      const periodoBreakpoints = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: hasFiliais ? 6 : 8,
      };

      const actionsBreakpoints = {
        xs: 24,
        sm: 24,
        md: 12,
        lg: 4,
      };

      return {
        produtoBreakpoints,
        cnpjsBreakpoints,
        periodoBreakpoints,
        actionsBreakpoints,
      };
    }

    const cnpjsBreakpoints = {
      xs: 24,
      sm: 24,
      md: 10,
    };

    const periodoBreakpoints = {
      xs: 24,
      sm: 24,
      md: hasFiliais ? 10 : 12,
    };

    const actionsBreakpoints = {
      xs: 24,
      sm: 24,
      md: hasFiliais ? 4 : 6,
      flex: breakpoint.md ? '20rem' : 'auto',
    };

    return {
      cnpjsBreakpoints,
      periodoBreakpoints,
      actionsBreakpoints,
    };
  }

  const {
    produtoBreakpoints,
    cnpjsBreakpoints,
    periodoBreakpoints,
    actionsBreakpoints,
  } = getBreakpoints();

  return (
    <Row className="apuracao-filter-options" gutter={[16, 16]}>
      {viewApuracaoBy === ViewApuracaoBy.PRODUTO && (
        <Col {...produtoBreakpoints}>
          <Form.Item name="produtos">
            <SelectProduto cnpjs={[matriz.cnpj]} />
          </Form.Item>
        </Col>
      )}

      {hasFiliais && (
        <Col {...cnpjsBreakpoints}>
          <Form.Item
            name="cnpjs"
            required
            rules={[
              { required: true, message: i18n.t('Campo CNPJ é obrigatório') },
            ]}
          >
            <SelectEmpresa
              cnpjMatriz={matriz.cnpj}
              allowSelectAll
              selectAll
              disabled={fetching}
              onFetch={onFetchEmpresas}
            />
          </Form.Item>
        </Col>
      )}

      <Col {...periodoBreakpoints}>
        <Form.Item name="periodo" noStyle>
          <RangePicker
            picker="month"
            format="MM-YYYY"
            placeholder={[i18n.t('Data de início'), i18n.t('Data de término')]}
            disabled={fetching}
          />
        </Form.Item>
      </Col>

      <Col {...actionsBreakpoints}>
        <Button
          block
          type="primary"
          htmlType="submit"
          className="button-filtrar uppercase"
          disabled={fetching}
        >
          {i18n.t('Filtrar')}
        </Button>
      </Col>
    </Row>
  );
}

ApuracaoFilterOptions.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    filiais: Proptypes.arrayOf(Proptypes.shape({})),
  }).isRequired,
  viewApuracaoBy: Proptypes.oneOf(Object.keys(ViewApuracaoBy)).isRequired,
  onFetchEmpresas: Proptypes.func,
  fetching: Proptypes.bool,
};

ApuracaoFilterOptions.defaultProps = {
  onFetchEmpresas: () => null,
  fetching: false,
};

export default ApuracaoFilterOptions;
