import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n';
import moment from 'moment';

// Styles
import './EmpresaAgendarApuracao.scss';

// External Components
import {
  Card,
  Col,
  Row,
  DatePicker,
  Divider,
  Button,
  Checkbox,
  Form,
  message,
  Grid,
  Spin,
  Alert,
} from 'antd';
import Title from 'antd/lib/typography/Title';

// Constants
import { ImportTypesEnum } from 'app/constants/ImportTypesConstants';

// Utils
import { formatProcessoRangeDate } from 'app/utils/date';

// Services
import { getAll, getFlags, schedule } from 'app/services/ProcessoService';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import SelectEmpresa from 'app/components/Select/SelectEmpresa';
import ApuracaoScheduleTable from 'app/components/ApuracaoScheduleTable';
import SubHeader from 'app/components/Subheader';
import TagSelect from 'app/components/FilterGroup/TagSelectFilter';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

function EmpresaAgendarApuracao() {
  const { empresa } = useEmpresa();
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const [form] = Form.useForm();
  const [flags, setFlags] = useState([]);
  const [apuracoes, setApuracoes] = useState([]);
  const [fetchingFlags, setFetchingFlags] = useState(false);
  const [fetchingApuracoes, setFetchingApuracoes] = useState(false);
  const [isCnpjsFilled, setIsCnpjsFilled] = useState(false);
  const [isPeriodFilled, setIsPeriodFilled] = useState(false);
  const [formTouched, setFormTouched] = useState(false);

  const hasFiliais = empresa?.filiais?.length > 0;

  const cnpjsBreakpoints = {
    xs: 24, // Em telas extra pequenas, ocupará a largura total
    sm: 12, // Em telas pequenas, ocupará metade da largura
    md: 6,  // Em telas médias, ocupará um quarto da largura
  };

  const periodBreakpoints = {
    xs: 24, // Em telas extra pequenas, ocupará a largura total
    sm: 12, // Em telas pequenas, ocupará metade da largura
    md: 6,  // Em telas médias, ocupará um quarto da largura
  };

  const actionsBreakpoints = {
    xs: 24, // Em telas extra pequenas, ocupará a largura total
    sm: 12, // Em telas pequenas, ocupará metade da largura
    md: 6,  // Em telas médias, ocupará um quarto da largura
  };

  const produtoBreakpoints = {
    xs: 24, // Em telas extra pequenas, ocupará a largura total
    sm: 12, // Em telas pequenas, ocupará metade da largura
    md: 6,  // Em telas médias, ocupará um quarto da largura
  };

  useEffect(() => {
    if (empresa?.cnpj) {
      updateRoute(getFieldValues());
    }

    if (empresa?.cnpj && !flags?.length) {
      fetchFlags();
    }

    if (empresa?.cnpj && !apuracoes?.length) {
      fetchApuracoes();
    }
  }, [empresa]);

  useEffect(() => {
    setFieldsByQueryParams();
  }, []);

  useEffect(() => {
    const cnpjsValue = form.getFieldValue('cnpjs');
    const periodValue = form.getFieldValue('period');

    setIsCnpjsFilled(!!cnpjsValue);
    setIsPeriodFilled(!!periodValue);
  }, [form]);

  async function fetchFlags() {
    setFetchingFlags(true);

    try {
      const { data } = await getFlags();
      setFlags(data);
      setFetchingFlags(false);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar as flags do processo'));
    }
  }

  async function fetchApuracoes() {
    setFetchingApuracoes(true);

    try {
      const { data } = await getAll({
        cnpjMatriz: empresa.cnpj,
        type: ImportTypesEnum.APURACAO,
      });
      setApuracoes(data.results);
      setFetchingApuracoes(false);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar as apurações'));
    }
  }

  async function onFinish() {
    const payload = getFieldValues();

    updateRoute(payload);

    try {
      await schedule(payload);
      message.success(i18n.t('Processo de apuração iniciado com sucesso'));
      await fetchApuracoes();
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || i18n.t('Erro ao agendar apuraçao.');

      message.error(
        errorMessage || i18n.t('Erro ao iniciar processo de apuração.'),
      );
    }
  }

  function getFieldValues() {
    const cnpjs = form.getFieldValue('cnpjs');

    const payload = {
      cnpjMatriz: empresa.cnpj,
      cnpjs: hasFiliais ? cnpjs : [empresa.cnpj],
      flags: {},
    };

    Object.keys(flags)?.forEach((flag) => {
      payload.flags[flag] = !!form.getFieldValue(flag);
    });

    const period = form.getFieldValue('period');

    const cProds = form.getFieldValue('cProds');
    payload.cProds = cProds ? Object.values(cProds)[0] : []

    if (period?.length === 2) {
      payload.periodoInicial = formatProcessoRangeDate(period[0]);
      payload.periodoFinal = formatProcessoRangeDate(period[1]);
    }

    return payload;
  }

  function updateRoute(params) {
    if (!params) {
      return;
    }

    const { cnpjs, periodoInicial, periodoFinal } = params;
    const queryParams = {};

    if (periodoInicial) {
      queryParams.periodoInicial = periodoInicial;
    }

    if (periodoFinal) {
      queryParams.periodoFinal = periodoFinal;
    }

    if (hasFiliais && cnpjs) {
      queryParams.cnpjs = cnpjs;
    }

    setSearchParams(queryParams);
  }

  function setFieldsByQueryParams() {
    const periodoInicial = searchParams.get('periodoInicial');
    const periodoFinal = searchParams.get('periodoFinal');
    const cnpjs = searchParams.getAll('cnpjs');

    let cnpjsByQuery;
    let periodoByQuery;

    if (periodoInicial && periodoFinal) {
      periodoByQuery = [
        moment(periodoInicial, 'MM-YYYY'),
        moment(periodoFinal, 'MM-YYYY'),
      ];
    }

    if (cnpjs) {
      typeof cnpjs === 'string'
        ? (cnpjsByQuery = [cnpjs])
        : (cnpjsByQuery = [...cnpjs]);
    }

    form.setFieldsValue({
      period: periodoByQuery,
      cnpjs: cnpjsByQuery,
    });
  }

  function renderFlag(flag) {
    const { text, checked } = flags[flag];

    return (
      <Col xs={24} sm={24} md={12} key={flag}>
        <Form.Item name={flag} valuePropName="checked" initialValue={checked}>
          <Checkbox className="boxed-checkbox text-align-left" >
            {text || flag}
          </Checkbox>
        </Form.Item>
      </Col>
    );
  }

  return (
    <Card className="empresa-agendar-apuracao">
      <Spin spinning={fetchingFlags}>
        <SubHeader title={i18n.t('Agendar apuração')} />

        <Alert
          message="Antes de gerar uma apuração, certifique-se que os dados de
            estoque e saldo inicial estão corretos. Processos que devem ser
            feitos nos relatórios de CONTROLE_ESTOQUE e SALDO_INICIAL"
          type="info"
          showIcon
        />

        <Divider />

        <Form form={form} onFinish={onFinish}
          onValuesChange={(changedValues) => {
            if ('cnpjs' in changedValues) {
              setIsCnpjsFilled(!!changedValues.cnpjs);
            }
            if ('period' in changedValues) {
              setIsPeriodFilled(
                !!changedValues.period && changedValues.period.length === 2
              );
            }
            setFormTouched(true);
          }}
        >
          <Row className="filter-options" gutter={[16, 16]}>
            {hasFiliais && (
              <Col {...cnpjsBreakpoints}>
                <Form.Item
                  name="cnpjs"
                  required
                  rules={[
                    {
                      required: true,
                      message: i18n.t('Campo CNPJ é obrigatório'),
                    },
                  ]}
                >
                  <SelectEmpresa
                    allowSelectAll
                    selectAll
                    cnpjMatriz={empresa?.cnpj}
                  />
                </Form.Item>
              </Col>
            )}

            <Col {...periodBreakpoints}>
              <Form.Item name="period" noStyle>
                <RangePicker
                  picker="month"
                  format="MM-YYYY"
                  placeholder={[
                    i18n.t('Data de início'),
                    i18n.t('Data de término'),
                  ]}
                />
              </Form.Item>
            </Col>

            <Col {...produtoBreakpoints}>
              <Form.Item name="cProds"
              >
                <TagSelect
                  filter={{
                    type: 'string',
                    required: false,
                  }}
                  formGroup={['cProds']} // Grupo do formulário (pode ser personalizado)
                  placeholder="Informe os cProds"
                />
              </Form.Item>
            </Col>

            <Col {...actionsBreakpoints}>
              <Button
                block
                type="primary"
                htmlType="submit"
                className="btn-executar-processo uppercase"
                disabled={!isCnpjsFilled || !isPeriodFilled || !formTouched}
              >
                {i18n.t('Executar processo')}
              </Button>
            </Col>
          </Row>

          {Object.keys(flags)?.length > 0 && (
            <>
              <Divider />

              <Title level={5}>{i18n.t('Configurações')}</Title>

              <Row className="processo-options" gutter={[16, 16]}>
                {Object.keys(flags).map(renderFlag)}
              </Row>

              <Divider />
            </>
          )}
        </Form>
      </Spin>

      <ApuracaoScheduleTable
        apuracoes={apuracoes}
        loading={fetchingApuracoes}
        refetch={() => fetchApuracoes()}
        onCancel={() => fetchApuracoes()}
      />
    </Card>
  );
}

export default EmpresaAgendarApuracao;
