import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';
import debounce from 'lodash.debounce';

// External components
import { message, Select, Spin } from 'antd';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import { getProdutos } from 'app/services/ProdutoService';

// Styles
import './SelectProduto.scss';

export const SelectModeType = {
  MULTIPLE: 'multiple',
  TAGS: 'tags',
};

function SelectProduto({
  cnpjs,
  debounceTimeout,
  placeholder,
  mode,
  ...props
}) {
  const { empresa } = useEmpresa();

  // States
  const [items, setItems] = useState([]);
  const [fetching, setFetching] = useState(false);

  const fetchProdutos = debounce(async (value) => {
    setFetching(true);

    try {
      const { data } = await getProdutos({
        value,
        cnpjs,
        cnpjMatriz: empresa.cnpjMatriz,
      });
      setItems(data?.[0]?.data);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar produtos'));
    } finally {
      setFetching(false);
    }
  }, debounceTimeout);

  async function onSearch(value) {
    if (!value) {
      setItems([]);
      return;
    }

    await fetchProdutos(value);
  }

  function renderNotFoundContent() {
    if (!fetching) {
      return null;
    }

    return <Spin size="small" tip={i18n.t('Buscando...')} />;
  }

  const className = 'select-produto';

  const options = items.map((produto) => ({
    value: produto.cProd,
    label: `${produto.cProd} - ${produto.desc}`,
  }));

  return (
    <Select
      className={className}
      mode={mode}
      options={options}
      placeholder={placeholder}
      showSearch
      showArrow={false}
      filterOption={false}
      dropdownMatchSelectWidth={false}
      notFoundContent={renderNotFoundContent()}
      onSearch={onSearch}
      {...props}
    />
  );
}

SelectProduto.propTypes = {
  cnpjs: Proptypes.arrayOf(Proptypes.string),
  debounceTimeout: Proptypes.number,
  mode: Proptypes.oneOf(Object.values(SelectModeType)),
  placeholder: Proptypes.string,
};

SelectProduto.defaultProps = {
  cnpjs: [],
  debounceTimeout: 500,
  mode: SelectModeType.MULTIPLE,
  placeholder: i18n.t('Busque o produto'),
};

export default SelectProduto;
