import React from 'react';
import Proptypes from 'prop-types';

// Icons
import { FaEllipsisH } from 'react-icons/fa';

// External components
import { Button, Dropdown, Grid, Menu, Space } from 'antd';

// Styles
import './MenuActions.scss';

const { useBreakpoint } = Grid;

function MenuActions({ actions, placement, trigger, allCollapsed }) {
  const breakpoint = useBreakpoint();

  function renderContent(item) {
    const { icon, label } = item;

    return (
      <Space size={13} className="menu-actions__content">
        {icon}
        <span>{label}</span>
      </Space>
    );
  }

  function renderMenuItem(item) {
    return (
      <Menu.Item key={item.key} disabled={item.disabled} onClick={item.onClick}>
        {item.component || renderContent(item)}
      </Menu.Item>
    );
  }

  function renderMenuActionsDropdown() {
    return <Menu>{actions.map(renderMenuItem)}</Menu>;
  }

  // ant.d screen ≥ 992px
  if (breakpoint.lg && !allCollapsed) {
    return (
      <Space size={16} className="menu-actions">
        {actions.map((action) => {
          if (action.component) {
            return action.component;
          }

          return (
            <Button
              key={action.key}
              className={action.className}
              disabled={action.disabled}
              onClick={action.onClick}
            >
              {renderContent(action)}
            </Button>
          );
        })}
      </Space>
    );
  }

  return (
    <Dropdown
      overlayClassName="menu-actions-dropdown"
      overlay={renderMenuActionsDropdown()}
      trigger={trigger}
      placement={placement}
    >
      <Button className="menu-actions-button">
        <FaEllipsisH />
      </Button>
    </Dropdown>
  );
}

MenuActions.propTypes = {
  actions: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.string.isRequired,
      icon: Proptypes.element,
      label: Proptypes.node,
      disabled: Proptypes.bool,
      onClick: Proptypes.func,
    }),
  ).isRequired,
  trigger: Proptypes.arrayOf(Proptypes.string),
  placement: Proptypes.string,
  allCollapsed: Proptypes.bool,
};

MenuActions.defaultProps = {
  trigger: ['click'],
  placement: 'bottomRight',
  allCollapsed: false,
};

export default MenuActions;
