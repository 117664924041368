import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import moment from 'moment';
import FileSaver from 'file-saver';
import { useMutation } from 'react-query';

// External Components
import { Button, Card, Col, Divider, Form, message, Row, notification } from 'antd';

// Enums
import { ViewApuracaoBy, ViewApuracaoByList } from 'app/constants/Enumerators';

// Utils
import { formatProcessoRangeDate } from 'app/utils/date';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import {
  resultadosApuracao,
  resultadosApuracaoTotal,
  downloadResultados,
  downloadFicha3Unificadas,
  resetApuracao
} from 'app/services/ApuracaoService';
import {
  getArquivoDigital,
  getDownloadArquivoDigital,
} from 'app/services/ArquivoDigitalService';

// Components
import SubHeader from 'app/components/Subheader';
import ApuracaoPorCNPJ from 'app/components/ApuracaoPorCNPJ';
import ApuracaoOverview from 'app/components/ApuracaoOverview';
import ApuracaoPorProduto from 'app/components/ApuracaoPorProduto';
import ApuracaoFilterOptions from 'app/components/ApuracaoFilterOptions';
import ViewBy from 'app/components/ViewBy';
import ModalReset from 'app/components/ModalReset';

// Styles
import './EmpresaResultados.scss';

function EmpresaResultados() {
  const { empresa } = useEmpresa();
  const context = useContext(GlobalContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalResetOpen, setIsModalResetOpen] = useState(false);

  // Contexts
  const {
    groupsSelectedState: {
      groupsSelected: groupsSelectedContext,
      setGroupsSelected,
    },
  } = context;

  // States
  const [form] = Form.useForm();
  const hasFiliais = empresa?.filiais?.length > 0;

  const [currentViewApuracaoBy, setCurrentViewApuracaoBy] = useState(
    getViewApuracao(),
  );

  const [resultadoPorCNPJ, setResultadoPorCNPJ] = useState([]);
  const [resultadoPorProduto, setResultadoPorProduto] = useState([]);

  const [fetching, setFetching] = useState(false);
  const [fetchingDownload, setFetchingDownload] = useState(false);
  const [fetchingTotal, setFetchingTotal] = useState(false);
  const [fetchingEmpresas, setFetchingEmpresas] = useState(false);
  const [fetchingArquivoDigital, setFetchingArquivoDigital] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortBy, setSortBy] = useState('ressarcimento');
  const [orderBy, setOrderBy] = useState('1');

  // Totalizer
  const [totalRessarcimento, setTotalRessarcimento] = useState(0);
  const [totalComplemento, setTotalComplemento] = useState(0);
  const [totalAlertas, setTotalAlertas] = useState(0);

  useEffect(() => {
    setFieldsByQueryParams();

    if (empresa?.cnpj) {
      updateRoute(
        getRequestParams({
          limit: pageSize,
          skip: 1,
          sortBy,
          orderBy,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (fetchingEmpresas) {
      const params = getRequestParams();
      fetchResultadosApuracaoTotal(params);
    }
  }, [fetchingEmpresas]);

  useEffect(() => {
    // buscar resultados somente após carregar <SelectEmpresas />
    if (fetchingEmpresas) {
      const params = {
        ...getRequestParams({
          limit: pageSize,
          skip: currentPage,
          sortBy,
          orderBy,
        }),
        groups: groupsSelectedContext,
      };

      fetchResultadosApuracao(params);
    }
  }, [
    currentViewApuracaoBy,
    groupsSelectedContext,
    fetchingEmpresas,
    currentPage,
    pageSize,
    sortBy,
    orderBy,
  ]);

  const resetApuracaoMutation = useMutation(
    (payload) => resetApuracao({ cnpjMatriz: empresa.cnpjMatriz, payload }),
    {
      onSuccess: () => {
        fetchApuracao()
      },
      onError: () => {
        notification.error({
          message: i18n.t('Erro ao apagar a apuração'),
        });
      },
    },
  );

  function openResetModal() {
    setIsModalResetOpen(true);
  }

  function hideResetModal() {
    setIsModalResetOpen(false);
  }

  async function onModalResetConfirmation(modalForm) {
    const periodo = modalForm.getFieldValue('periodo');
    const cnpjs = modalForm.getFieldValue('cnpjs');
    const payload = {};

    if (periodo?.length === 2) {
      payload.periodoInicio = formatProcessoRangeDate(periodo[0]);
      payload.periodoFim = formatProcessoRangeDate(periodo[1]);
    }

    if (cnpjs?.length) {
      payload.cnpjs = cnpjs
    }

    await resetApuracaoMutation.mutateAsync(payload);

    modalForm.resetFields();

    notification.info({
      message: i18n.t('Registros apagados com sucesso.'),
    });

    hideResetModal();
  }

  function fetchApuracao() {
    const params = {
      ...getRequestParams({
        limit: pageSize,
        skip: 1,
        sortBy,
        orderBy,
      }),
      groups: groupsSelectedContext,
    };

    updateRoute(params);
    fetchResultadosApuracaoTotal(params);

    if (currentPage === 1) {
      fetchResultadosApuracao(params);
    } else {
      // se não for a primeira página altera para primera página
      // assim é disparado a request pelo useEffect
      setCurrentPage(1);
    }
  }

  async function fetchResultadosApuracaoTotal(params) {
    setFetchingTotal(true);

    try {
      const { data } = await resultadosApuracaoTotal(params);

      setTotalComplemento(data?.complemento || 0);
      setTotalRessarcimento(data?.ressarcimento || 0);
      setTotalAlertas(data?.alertas || 0);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar resultados totais da apuração'));
    } finally {
      setFetchingTotal(false);
    }
  }

  async function fetchResultadosApuracao(params) {
    setFetching(true);

    try {
      const { data } = await resultadosApuracao(params);

      currentViewApuracaoBy === ViewApuracaoBy.CNPJ
        ? setResultadoPorCNPJ(data?.data || [])
        : setResultadoPorProduto(data?.data || []);

      setTotalItems(data?.pagination?.total || 0);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar resultados da apuração'));
    } finally {
      setFetching(false);
    }
  }

  async function fetchDownloadResultados(groups) {
    setFetchingDownload(true);

    const params = {
      ...getRequestParams({ limit: pageSize, skip: 1 }),
      groups,
    };

    try {
      await downloadResultados(params);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar exportação da apuração'));
    } finally {
      setFetchingDownload(false);
    }
  }

  async function downloadArquivoDigital(params) {
    const { periodo, cnpj } = params || {};

    const arquivoDigital = await fetchArquivoDigital({
      periodo,
      cnpj,
      cnpjMatriz: empresa.cnpjMatriz,
    });

    if (!arquivoDigital) {
      return;
    }

    const { raw, fileName } = arquivoDigital;

    const file = new Blob([raw], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(file, fileName);
  }

  async function fetchArquivoDigital(params) {
    setFetchingArquivoDigital(true);

    try {
      const data = await getArquivoDigital(params);
      return data;
    } catch (error) {
      message.error('Erro ao buscar arquivo digital');
    } finally {
      setFetchingArquivoDigital(false);
    }

    return null;
  }

  async function downloadAllArquivoDigital() {
    const cnpjs = form.getFieldValue('cnpjs');

    await fetchDownloadArquivoDigital(
      {
        cnpjs: hasFiliais ? cnpjs : [empresa?.cnpj],
        ...getPeriodoFilter(),
        cnpjMatriz: empresa.cnpjMatriz,
      },
      'baixarTodos',
    );
  }

  async function onDownloadFicha3Unificadas() {
    const cnpjs = form.getFieldValue('cnpjs');
    const produtos = form.getFieldValue('produtos');

    try {
      setFetchingDownload(true)

      const response = await downloadFicha3Unificadas({
        cnpjMatriz: empresa.cnpjMatriz,
        cnpjs: hasFiliais ? cnpjs : [empresa?.cnpj],
        produtos,
        ...getPeriodoFilter(),
      })
      console.log(response)
    } catch (error) {

      message.error(i18n.t('Erro ao baixar todas as Ficha 3'));
    } finally {
      setFetchingDownload(false)
    }

  }

  function getRequestParams(params = {}) {
    const cnpjs = form.getFieldValue('cnpjs');
    const produtos = form.getFieldValue('produtos');

    const payload = {
      cnpjs: hasFiliais ? cnpjs : [empresa?.cnpj],
      visaoPor: currentViewApuracaoBy,
      produtos,
      cnpjMatriz: empresa.cnpjMatriz,
      ...getPeriodoFilter(),
    };

    if (params.limit) {
      payload.limit = params.limit;
    }

    if (params.skip) {
      payload.skip = params.skip - 1;
    }

    if (params.sortBy) {
      payload.sortBy = params.sortBy;
    }

    if (params.orderBy) {
      payload.orderBy = params.orderBy === 'ascend' ? 1 : -1;
    }

    return payload;
  }

  function getPeriodoFilter() {
    const [periodoInicio, periodoFim] = form.getFieldValue('periodo') || [];
    const periodo = {};

    if (!periodoInicio || !periodoFim) {
      return periodo;
    }

    return {
      periodoInicio: formatProcessoRangeDate(periodoInicio),
      periodoFim: formatProcessoRangeDate(periodoFim),
    };
  }

  async function onChangeTable(paginate, filters, sorter) {
    let page = paginate.current;

    if (paginate.pageSize !== pageSize) {
      page = 1;
    }

    setPageSize(paginate.pageSize);
    setCurrentPage(page);

    const params = {
      ...getRequestParams({
        limit: paginate.pageSize,
        skip: page,
      }),
      groups: groupsSelectedContext,
    };

    if (sorter) {
      setSortBy(sorter.field);
      setOrderBy(sorter.order);

      params.sortBy = sorter.field;
      params.orderBy = sorter.order === 'ascend' ? 1 : -1;
    }
  }

  function filterProdutosByApuracaoSelected(apuracao) {
    const { cnpj, periodo } = apuracao;

    const fields = {};
    const periodoFilter = getPeriodoFilter();
    const cnpjsFilter = form.getFieldValue('cnpjs') || [empresa.cnpj];

    // Check if the selected apuracao is the same as the filtered one
    const isSameApuracao =
      cnpjsFilter.includes(cnpj) &&
      periodo === periodoFilter.periodoInicio &&
      periodo === periodoFilter.periodoFim;

    const isViewModeProduto = currentViewApuracaoBy === ViewApuracaoBy.PRODUTO;

    if (!isViewModeProduto) {
      // Change view mode to Produto and filtered by CNPJ and Periodo
      // When the view mode is changed the results are fetched through a UseEffect
      setCurrentViewApuracaoBy(ViewApuracaoBy.PRODUTO);

      // Clear products
      fields.produtos = [];
    }

    // Shouldn`t filter again if it`s the same apuracao
    if (isSameApuracao) {
      return;
    }

    const periodoStart = moment(
      periodo || periodoFilter.periodoInicio,
      'MMYYYY',
    );
    const periodoEnd = moment(periodo || periodoFilter.periodoFim, 'MMYYYY');

    // Set new filters
    fields.cnpjs = [cnpj];
    fields.periodo = [periodoStart, periodoEnd];
    form.setFieldsValue(fields);

    // Fetch new results
    if (isViewModeProduto) {
      fetchApuracao();
    }
  }

  function getViewApuracao() {
    const visaoPor = searchParams.get('visaoPor');

    return visaoPor || ViewApuracaoBy.CNPJ;
  }

  function updateRoute(params) {
    if (!params) {
      return;
    }

    const { visaoPor, periodoInicio, periodoFim, produtos, cnpjs } = params;
    const queryParams = {};

    setCurrentViewApuracaoBy(visaoPor);

    if (periodoInicio) {
      queryParams.periodoInicio = periodoInicio;
    }

    if (periodoFim) {
      queryParams.periodoFim = periodoFim;
    }

    if (visaoPor) {
      queryParams.visaoPor = visaoPor;
    }

    if (produtos) {
      queryParams.produtos = produtos;
    }

    if (hasFiliais && cnpjs) {
      queryParams.cnpjs = cnpjs;
    }

    setSearchParams(queryParams);
  }

  function setFieldsByQueryParams() {
    const periodoInicio = searchParams.get('periodoInicio');
    const periodoFim = searchParams.get('periodoFim');
    const visaoPor = searchParams.get('visaoPor');
    const cnpjs = searchParams.getAll('cnpjs');
    const produtos = searchParams.getAll('produtos');

    let cnpjsByQuery;
    let periodoByQuery;
    let produtosByQuery;

    setCurrentViewApuracaoBy(getViewApuracao());

    if (periodoInicio && periodoFim) {
      periodoByQuery = [
        moment(periodoInicio, 'MM-YYYY'),
        moment(periodoFim, 'MM-YYYY'),
      ];
    }

    if (cnpjs) {
      typeof cnpjs === 'string'
        ? (cnpjsByQuery = [cnpjs])
        : (cnpjsByQuery = [...cnpjs]);
    }

    if (produtos) {
      typeof produtos === 'string'
        ? (produtosByQuery = [produtos])
        : (produtosByQuery = [...produtos]);
    }

    form.setFieldsValue({
      visaoPor,
      cnpjs: cnpjsByQuery,
      periodo: periodoByQuery,
      produtos: produtosByQuery,
    });
  }

  function getCnpjs() {
    return form.getFieldValue('cnpjs');
  }

  function onChangeViewApuracaoBy(vireApuracaoBy) {
    setCurrentViewApuracaoBy(vireApuracaoBy);
    setCurrentPage(1);
  }

  function onChangeGroupFilter(groups) {
    setGroupsSelected(groups);
    setCurrentPage(1);
  }

  function renderSubheader() {
    const current = ViewApuracaoByList.find(
      (view) => currentViewApuracaoBy === view.key,
    );

    const title = i18n.t('Resultados da apuração por {{viewBy}}', {
      viewBy: current?.label,
    });

    const prefix = (
      <Button
        type="default"
        danger
        className="uppercase"
        onClick={openResetModal}
      >
        {i18n.t('Resetar apuração')}
      </Button>
    );

    const prefix2 = (
      <ViewBy
        list={ViewApuracaoByList}
        selected={currentViewApuracaoBy}
        onChange={onChangeViewApuracaoBy}
      />
    );

    return <SubHeader title={title} prefix={prefix} prefix2={prefix2} />;
  }

  function onFetchEmpresas() {
    setFetchingEmpresas(true);
  }

  function renderTotalItems() {
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} items', { totalItems })
        : i18n.t('{{totalItems}} item', { totalItems });

    return <span>{label}</span>;
  }

  async function downloadSelectedArquivoDigital(_ids) {
    const cnpjs = form.getFieldValue('cnpjs');

    await fetchDownloadArquivoDigital(
      { cnpjs, _ids, cnpjMatriz: empresa.cnpjMatriz },
      'baixarSelecionados',
    );
  }

  async function fetchDownloadArquivoDigital(params, route) {
    setFetchingArquivoDigital(true);

    try {
      await getDownloadArquivoDigital(params, route);
    } catch (error) {
      message.error('Erro ao buscar arquivo digital');
    } finally {
      setFetchingArquivoDigital(false);
    }

    return null;
  }

  return (
    <Card className="empresa-resultados">
      <ModalReset
        empresa={empresa}
        isVisible={isModalResetOpen}
        onCancel={hideResetModal}
        okText={i18n.t('Resetar apuaração')}
        questionText={i18n.t('Deseja apagar os dados da Apuração ?')}
        onOk={onModalResetConfirmation}
        hasCprods={false}
        isLoading={resetApuracaoMutation.isLoading}
      />

      {renderSubheader()}

      <Form form={form} onFinish={fetchApuracao}>
        {empresa && (
          <ApuracaoFilterOptions
            matriz={empresa}
            onFetchEmpresas={onFetchEmpresas}
            fetching={fetching}
            viewApuracaoBy={currentViewApuracaoBy}
          />
        )}
      </Form>

      <Divider />

      <Row>
        <Col flex="auto">
          <ApuracaoOverview
            overview={{ totalRessarcimento, totalComplemento, totalAlertas }}
            showAlertas={currentViewApuracaoBy === ViewApuracaoBy.CNPJ}
            loading={fetchingTotal}
          />
        </Col>
      </Row>

      {empresa && currentViewApuracaoBy === ViewApuracaoBy.CNPJ && (
        <ApuracaoPorCNPJ
          dataSource={resultadoPorCNPJ}
          matriz={empresa}
          cnpjs={getCnpjs()}
          fetching={fetching || fetchingDownload}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalItems,
            showTotal: renderTotalItems,
          }}
          onChangeTable={onChangeTable}
          onChangeGroupFilter={onChangeGroupFilter}
          onChangeDownload={fetchDownloadResultados}
          onSelectApuracao={filterProdutosByApuracaoSelected}
          downloadArquivoDigital={downloadArquivoDigital}
          downloadAllArquivoDigital={downloadAllArquivoDigital}
          downloadSelectedArquivoDigital={downloadSelectedArquivoDigital}
          fetchingArquivoDigital={fetchingArquivoDigital}
          fetchArquivoDigital={fetchArquivoDigital}
        />
      )}

      {currentViewApuracaoBy === ViewApuracaoBy.PRODUTO && (
        <ApuracaoPorProduto
          dataSource={resultadoPorProduto}
          fetching={fetching || fetchingDownload}
          pagination={{
            current: currentPage,
            pageSize,
            total: totalItems,
            showTotal: renderTotalItems,
          }}
          onChangeTable={onChangeTable}
          onChangeGroupFilter={onChangeGroupFilter}
          onChangeDownload={fetchDownloadResultados}
          onSelectApuracao={filterProdutosByApuracaoSelected}
          onDownloadFicha3Unificadas={onDownloadFicha3Unificadas}
        />
      )}
    </Card>
  );
}

export default EmpresaResultados;
