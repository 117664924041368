import React, { useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// Icons
import { ImWarning } from 'react-icons/im';

// External components
import { message, Modal, Space } from 'antd';

// Utils
import { cnpjFormat } from 'app/utils/masks';

// Services
import { remove } from 'app/services/EmpresasService';

// Styles
import './ModalRemoveEmpresa.scss';

function ModalRemoveEmpresa({
  empresa,
  isVisible,
  toggleVisible,
  afterRemove,
}) {
  const [removing, setRemoving] = useState(false);

  async function handleRemoveEmpresa() {
    const { _id } = empresa;

    setRemoving(true);

    try {
      await remove(_id);
      message.success(
        i18n.t('A empresa {{nome}} foi removida com sucesso', {
          nome: empresa?.nome,
        }),
      );

      afterRemove();
      closeModal();
    } catch (error) {
      message.error(i18n.t('Erro ao remover {{nome}}', { nome: empresa.nome }));
    } finally {
      setRemoving(false);
    }
  }

  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-confirmation"
      title={i18n.t('Atenção')}
      okText={i18n.t('Remover')}
      cancelText={i18n.t('Cancelar')}
      width={450}
      visible={isVisible}
      closable={!removing}
      maskClosable={!removing}
      confirmLoading={removing}
      cancelButtonProps={{ disabled: removing }}
      onOk={handleRemoveEmpresa}
      onCancel={closeModal}
    >
      <Space size={13}>
        <ImWarning size={30} />
        {i18n.t('Deseja realmente remover a empresa {{nome}} - {{cnpj}}?', {
          nome: empresa?.nome,
          cnpj: cnpjFormat(empresa?.cnpj),
        })}
      </Space>
    </Modal>
  );
}

ModalRemoveEmpresa.propTypes = {
  empresa: Proptypes.shape({
    _id: Proptypes.string.isRequired,
    nome: Proptypes.string.isRequired,
    cnpj: Proptypes.string.isRequired,
  }),
  isVisible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  afterRemove: Proptypes.func.isRequired,
};

ModalRemoveEmpresa.defaultProps = {
  empresa: {},
};

export default ModalRemoveEmpresa;
