import React from 'react';
import Proptypes from 'prop-types';

// External components
import { Card, Statistic } from 'antd';

// Styles
import './StatisticCard.scss';

export const StatisticType = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  DANGER: 'DANGER',
  WARNING: 'WARNING',
};

function StatisticCard({ value, prefix, suffix, type, className, ...props }) {
  const componentClasses = [
    'statistic-card',
    className || '',
    type.toLowerCase(),
  ].join(' ');

  return (
    <Card className={componentClasses}>
      <Statistic value={value} prefix={prefix} suffix={suffix} {...props} />
    </Card>
  );
}

StatisticCard.propTypes = {
  value: Proptypes.oneOfType([Proptypes.string, Proptypes.number]),
  prefix: Proptypes.oneOfType([Proptypes.string, Proptypes.element]),
  suffix: Proptypes.oneOfType([Proptypes.string, Proptypes.element]),
  type: Proptypes.oneOf(Object.values(StatisticType)),
  className: Proptypes.string,
};

StatisticCard.defaultProps = {
  type: StatisticType.DEFAULT,
  value: 0,
  prefix: '',
  suffix: '',
  className: '',
};

export default StatisticCard;
