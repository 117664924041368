import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { useQuery } from 'react-query';
import i18n from 'i18n';
import debounce from 'lodash.debounce';

// External components
import { Button, Col, Divider, Form, message, Popover, Row, Select, Table as TableAntd } from 'antd';

// Services
import { getProdutos } from 'app/services/ProdutoService';

// Components
import Table from 'app/components/Table';
import RowActionButton from 'app/components/RowActionButton';

// Styles
import './ProcessedProdutos.scss';
import moment from 'moment';

export const productsKeyFactory = {
  allFromCompany: (cnpjMatriz) => [{ scope: 'products', cnpjMatriz }],
  filteredFromCompany: (cnpjMatriz, filters = {}) => [
    { ...productsKeyFactory.allFromCompany(cnpjMatriz), ...filters },
  ],
};

function ProcessedProdutos({ matriz, onEditClick, onRemoveClick }) {
  const cnpjMatriz = matriz?.cnpjMatriz;

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [tableProductSearch, setTableProductSearch] = useState();

  // Filters
  const [productSearch, setProductSearch] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  const [hasFiliais] = useState(matriz?.filiais?.length > 0);

  const produtoBreakpoints = {
    xs: 24,
    sm: 24,
    md: 24,
    lg: hasFiliais ? 7 : 12,
  };

  const actionsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 4,
  };

  const queryKey = productsKeyFactory.filteredFromCompany(cnpjMatriz, {
    currentPage,
    pageSize,
    filteredProducts: tableProductSearch,
  });

  const skip = (currentPage - 1) * pageSize;

  const { data, isLoading } = useQuery(
    queryKey,
    () =>
      getProdutos({
        cnpjs: [cnpjMatriz],
        cnpjMatriz,
        skip,
        limit: pageSize,
        value: productSearch,
      }),
    {
      enabled: !!cnpjMatriz,
      refetchOnWindowFocus: false,
      select: (result) => result?.data?.[0],
    },
  );

  const columns = [
    {
      title: i18n.t('CPROD'),
      dataIndex: 'cProd',
    },
    {
      title: i18n.t('Alíquota Interna'),
      dataIndex: ['aliquotaInterna'],
      render: (aliquotaInterna, record) => (
        record.aliquotaInternaPeriodo ? <Popover content={getAliquotaPeriodoTable(record.aliquotaInternaPeriodo)} title="Alíquota Interna Período" trigger="hover">
        <i>{aliquotaInterna} *</i>
      </Popover> : aliquotaInterna
      ),
    },
    {
      title: i18n.t('Alíquota Redução'),
      dataIndex: ['aliquotaReducao'],
      render: (aliquotaReducao, record) => (
        record.aliquotaReducaoPeriodo ? <Popover content={getAliquotaPeriodoTable(record.aliquotaReducaoPeriodo)} title="Alíquota Redução Período" trigger="hover">
        <i>{aliquotaReducao} *</i>
      </Popover> : aliquotaReducao
      ),
    },
    {
      title: i18n.t('CEST'),
      dataIndex: 'cest',
    },
    {
      title: i18n.t('Descrição'),
      dataIndex: 'desc',
      width: 300,
    },
    {
      title: i18n.t('NCM'),
      dataIndex: 'ncm',
    },
    {
      title: i18n.t('Unidade medida'),
      dataIndex: 'unidadeMedida',
    },
    {
      title: i18n.t('Ações'),
      fixed: 'right',
      width: 80,
      render: (_, row) => (
        <RowActionButton
          onClickEdit={() => onEditClick(row)}
          onClickRemove={() => onRemoveClick(row)}
        />
      ),
    },
  ];

  function onChangeTable(paginate) {
    setPageSize(paginate.pageSize);
    setCurrentPage(paginate.current);
  }

  function renderTotalItems() {
    const totalItems = data?.pagination?.total;
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} produtos processados', {
            totalItems,
          })
        : i18n.t('{{totalItems}} produto processado', {
            totalItems,
          });

    return <span>{label}</span>;
  }

  function filterResults({ produtos }) {
    setTableProductSearch(produtos);
  }

  const debounceTimeout = 500;
  const debouncedFetchProducts = debounce(async (value, callback) => {
    setIsSearching(true);

    try {
      const { data: result } = await getProdutos({
        value,
        cnpjs: [cnpjMatriz],
      });

      callback(result?.[0]?.data);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar produtos'));
      callback([]);
    } finally {
      setIsSearching(false);
    }
  }, debounceTimeout);

  function handleSearch(newValue) {
    if (!newValue) {
      setSearchData([]);
      return;
    }

    debouncedFetchProducts(newValue, setSearchData);
  }

  function handleChange(newValue) {
    setProductSearch(newValue);
  }

  return (
    <div className="processed-produtos">
      <Form onFinish={filterResults}>
        <Row gutter={10}>
          <Col {...produtoBreakpoints}>
            <Form.Item name="produtos">
              <Select
                showSearch
                allowClear
                value={productSearch}
                loading={isSearching}
                placeholder={i18n.t('Selecione um produto')}
                options={searchData.map((d) => ({
                  value: d.cProd,
                  label: d.desc,
                }))}
                notFoundContent={i18n.t(
                  'Não foi encontrado produtos com este nome ou cProd',
                )}
                onSearch={handleSearch}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>

          <Col {...actionsBreakpoints}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="button-filtrar uppercase"
              disabled={isLoading}
            >
              {i18n.t('Filtrar')}
            </Button>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Row>
        <Col flex="auto">
          <Table
            columns={columns}
            dataSource={data?.data}
            loading={isLoading}
            scroll={{ x: '130rem' }}
            pagination={{
              current: currentPage,
              pageSize,
              total: data?.pagination?.total,
              showTotal: renderTotalItems,
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </div>
  );
}

function getAliquotaPeriodoTable(aliquotaPeriodo) {
  const columns = [
    {
      title: i18n.t('Data de Corte'),
      dataIndex: 'data',
      render: value => moment(new Date(value)).format('DD/MM/YYYY'),
    },
    {
      title: i18n.t('Valor < Data'),
      dataIndex: 'lt',
    },
    {
      title: i18n.t('Valor >= Data'),
      dataIndex: 'gte',
    },
  ]
  return (
    <TableAntd
      dataSource={aliquotaPeriodo}
      columns={columns}
      rowKey="data"
      pagination={false}
    />
  )
}

ProcessedProdutos.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    cnpjMatriz: Proptypes.string,
    filiais: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  isTabActive: Proptypes.bool,
  onEditClick: Proptypes.func,
  onRemoveClick: Proptypes.func,
};

ProcessedProdutos.defaultProps = {
  matriz: null,
  isTabActive: false,
  onEditClick: () => {},
  onRemoveClick: () => {},
};

export default ProcessedProdutos;
