import React from 'react';
import i18n from 'i18next';

// Icons
import { AiOutlineFilter } from 'react-icons/ai';

// External components
import { Button, Col, Input, Row } from 'antd';

const filterIcon = () => <AiOutlineFilter size={18} />;

const handleOnKeyDown = (event, confirm) => {
  if (event.key === 'Enter') {
    handleSearch(confirm);
  }
};

const handleSearch = (confirm) => {
  confirm();
};

const handleCancel = (setSelectedKeys, clearFilters) => {
  clearFilters();
  setSelectedKeys('');
};

const columnFilterTextProps = (dataIndex) => ({
  filterIcon,
  filterDropdown(props) {
    // eslint-disable-next-line react/prop-types
    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

    return (
      <div className="column-filter-text-dropdown column-filter-dropdown">
        <Input
          placeholder={i18n.t('Pesquisar')}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(event) => handleOnKeyDown(event, confirm)}
        />

        <Row gutter={16} className="column-filter-dropdown__actions">
          <Col span={12}>
            <Button onClick={() => handleCancel(setSelectedKeys, clearFilters)}>
              {i18n.t('Limpar')}
            </Button>
          </Col>

          <Col span={12}>
            <Button type="primary" onClick={() => handleSearch(confirm)}>
              {i18n.t('Aplicar')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  },

  onFilter(value, record) {
    if (record[dataIndex]) {
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    }
    return '';
  },
});

export default columnFilterTextProps;
