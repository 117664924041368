import { useContext } from 'react';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Constants
import { RolesEnum } from 'app/constants/AuthConstants';
import { CAT42_AUTH } from 'app/constants/LocalStorageKeysConstants';

export default function useAuth() {
  const context = useContext(GlobalContext);
  const { authState } = context;
  const { setAuth: setAuthState } = authState;

  function setAuth(data) {
    localStorage.setItem(CAT42_AUTH, JSON.stringify(data));
    setAuthState(data);
  }

  function getAuth() {
    if (authState?.accessToken) {
      return authState;
    }

    if (JSON.parse(localStorage.getItem(CAT42_AUTH))?.accessToken) {
      return JSON.parse(localStorage.getItem(CAT42_AUTH));
    }

    return null;
  }

  function logout() {
    setAuth(null);
    localStorage.removeItem(CAT42_AUTH);
  }

  if (!authState) {
    throw new Error('useAuth must be used within a GlobalProvider');
  }

  const auth = getAuth();
  const signed = !!auth;
  const isAdmin = auth?.user?.roles?.includes(RolesEnum.ADMIN);


  return {
    setAuth,
    getAuth,
    logout,
    isAdmin,
    signed,
  };
}
