import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';

// Hooks
import useAuth from 'app/hooks/useAuth';

function AdminRoute() {
  const location = useLocation();
  const { isAdmin } = useAuth();

  if (isAdmin) {
    return <Outlet />;
  }

  return <Navigate to="/" state={{ from: location }} replace />;
}

export default AdminRoute;
