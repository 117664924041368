import Api from 'app/services/Api';

// Utils
import { downloadFile } from 'app/utils/download';

const resource = 'reports';

export function getReportTypes() {
  return Api.get(`${resource}/types`);
}

export function getReportFlags() {
  return Api.get(`${resource}/flags`);
}

export function getAll(params) {
  return Api.get(`${resource}`, { params });
}

export function download(reportId) {
  return Api.get(`${resource}/${reportId}/download`, {
    responseType: 'arraybuffer',
  }).then(downloadFile);
}

export function schedule(payload) {
  const { type, ...body } = payload;
  return Api.post(`${resource}/schedule`, body, { params: { type } });
}

export function reschedule(id) {
  return Api.post(`${resource}/${id}/reschedule`);
}

export function cancelReport(reportId) {
  return Api.post(`${resource}/${reportId}/cancel`);
}
