import React, { useState, useContext } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// Icons
import { BiDownload, BiShow, BiExport } from 'react-icons/bi';
import { FiDownloadCloud } from 'react-icons/fi';

// External components
import { Button, Col, Row, Space, Spin, Tooltip } from 'antd';

// Utils
import { cnpjFormat, moneyFormat } from 'app/utils/masks';
import { formatPeriodo } from 'app/utils/date';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Components
import Table from 'app/components/Table';
import LinkButton from 'app/components/LinkButton';
import SubHeader from 'app/components/Subheader';
import ModalAlertasProcesso from 'app/components/ModalAlertasProcesso';
import ModalArquivoDigital from 'app/components/ModalArquivoDigital';
import ModalErrosArquivoDigital from 'app/components/ModalErrosArquivoDigital';
import TagFilter from 'app/components/TagFilter';

// Styles
import './ApuracaoPorCNPJ.scss';

function ApuracaoPorCNPJ({
  dataSource,
  fetching,
  fetchingArquivoDigital,
  pagination,
  onSelectApuracao,
  onChangeGroupFilter,
  onChangeTable,
  onChangeDownload,
  downloadArquivoDigital,
  downloadAllArquivoDigital,
  downloadSelectedArquivoDigital,
  fetchArquivoDigital,
}) {
  const context = useContext(GlobalContext);

  // Contexts
  const {
    groupsSelectedState: { groupsSelected },
  } = context;

  // Current selected data
  const [currentApuracao, setCurrentApuracao] = useState(null);
  const [currentArquivoDigital, setCurrentArquivoDigital] = useState(null);

  // Current selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  // Modal flags
  const [isVisibleModalAlertasApuracao, setIsVisibleModalAlertasApuracao] =
    useState(false);

  const [isVisibleModalArquivoDigital, setIsVisibleModalArquivoDigital] =
    useState(false);

  const [
    isVisibleModalErrosArquivoDigital,
    setIsVisibleModalErrosArquivoDigital,
  ] = useState(false);

  const tags = [
    // {
    //   label: i18n.t('CNPJ'),
    //   value: 'cnpj',
    // },
    {
      label: i18n.t('Periodo'),
      value: 'periodo',
    },
  ];

  const columns = [
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 180,
      render: renderCNPJ,
      visible: showColumn('cnpj'),
      sorter: true,
    },
    {
      title: i18n.t('Período'),
      dataIndex: 'periodo',
      key: 'periodo',
      width: 170,
      className: 'first-letter-capitalize',
      render: renderPeriodo,
      visible: showColumn('periodo'),
      sorter: true,
    },
    {
      title: i18n.t('Ressarcimento'),
      dataIndex: 'ressarcimento',
      key: 'ressarcimento',
      width: 200,
      render: renderMoney,
      visible: showColumn('ressarcimento'),
      sorter: true,
    },
    {
      title: i18n.t('Complemento'),
      dataIndex: 'complemento',
      key: 'complemento',
      width: 200,
      render: renderMoney,
      visible: showColumn('complemento'),
      sorter: true,
    },
    {
      title: i18n.t('Total'),
      dataIndex: 'apuracao',
      key: 'total',
      width: 200,
      render: renderTotal,
      visible: showColumn('apuracao'),
    },
    {
      title: i18n.t('Alertas'),
      dataIndex: 'alertas',
      key: 'alertas',
      width: 150,
      render: renderAlertas,
      visible: showColumn('alertas'),
    },
    {
      title: i18n.t('Arquivo digital'),
      dataIndex: 'arquivoDigital',
      key: 'arquivoDigital',
      width: 150,
      render: renderActionsArquivoDigital,
      visible: showColumn('arquivoDigital'),
    },
  ];

  function toggleModalAlertasApuracao(status) {
    setIsVisibleModalAlertasApuracao(status);
  }

  function openModalAlertasApuracao(apuracao) {
    setCurrentApuracao(apuracao);
    toggleModalAlertasApuracao(true);
  }

  function renderAlertas(alertas, row) {
    const qtdAlertas = alertas?.length;

    return (
      <LinkButton
        suffix={qtdAlertas}
        disabled={qtdAlertas === 0}
        onClick={() => openModalAlertasApuracao(row)}
      />
    );
  }

  function openModalErrosArquivoDigital(arquivoDigital) {
    setCurrentArquivoDigital(arquivoDigital);
    toggleModalErrosArquivoDigital(true);
  }

  function onDownload() {
    selectedRows.length
      ? downloadSelectedArquivoDigital(
          selectedRows.map(({ arquivoDigital }) => arquivoDigital._id),
        )
      : downloadAllArquivoDigital();
  }

  function toggleModalErrosArquivoDigital(status) {
    setIsVisibleModalErrosArquivoDigital(status);
  }

  function openModalArquivoDigital(apuracao) {
    setCurrentApuracao(apuracao);
    toggleModalArquivoDigital(true);
  }

  function toggleModalArquivoDigital(status) {
    setIsVisibleModalArquivoDigital(status);

    if (!status) {
      setCurrentApuracao(null);
    }
  }

  function renderActionsArquivoDigital(arquivoDigital, row) {
    const { regErrors = {} } = arquivoDigital || {};

    const countBlocoErros = (blocoErros) =>
      blocoErros.reduce((total, bloco) => total + bloco.errorsMsg.length, 0);

    // Count all errorsMsg in all blocos
    const qtdErros = Object.keys(regErrors).reduce(
      (total, bloco) => total + countBlocoErros(regErrors[bloco]),
      0,
    );

    return (
      <div className="actions-arquivo-digital">
        <Button type="link" onClick={() => openModalArquivoDigital(row)}>
          <Tooltip title={i18n.t('Visualizar arquivo digital')}>
            <Space size={12}>
              <BiShow size={18} />
            </Space>
          </Tooltip>
        </Button>

        <Button type="link" onClick={() => downloadArquivoDigital(row)}>
          <Tooltip title={i18n.t('Baixar arquivo digital')}>
            <Space size={12}>
              <BiDownload size={18} />
            </Space>
          </Tooltip>
        </Button>

        <LinkButton
          suffix={qtdErros}
          disabled={qtdErros === 0}
          onClick={() => openModalErrosArquivoDigital(arquivoDigital)}
          messageTooltip={i18n.t('Visualizar erros no arquivo digital')}
        />
      </div>
    );
  }

  function renderMoney(num = 0) {
    return moneyFormat('R$')(num?.toFixed(2));
  }

  function renderTotal(data, row) {
    const { ressarcimento = 0, complemento = 0 } = row || {};
    const total = ressarcimento - complemento;

    return (
      <span className={`total ${total > 0 ? 'positive' : 'negative'}`}>
        <b>{renderMoney(total)}</b>
      </span>
    );
  }

  function renderCNPJ(cnpj, row) {
    return (
      <Button
        type="link"
        className="link-text-button"
        onClick={() => onSelectApuracao(row)}
      >
        {cnpjFormat(cnpj)}
      </Button>
    );
  }

  function renderPeriodo(periodo, row) {
    if (!periodo) {
      return null;
    }

    return (
      <Button
        type="link"
        className="link-text-button"
        onClick={() => onSelectApuracao(row)}
      >
        <span className=" first-letter-capitalize">
          {formatPeriodo(periodo)}
        </span>
      </Button>
    );
  }

  function renderDetalhesHeader() {
    return (
      <SubHeader
        title={i18n.t('Detalhes')}
        prefix={
          <div className="actions">
            {renderDownloadButton()}
            {renderDownloadResultExport()}
            {renderGroupFilter()}
          </div>
        }
      />
    );
  }

  function renderDownloadButton() {
    return (
      <Tooltip
        title={
          selectedRows.length
            ? i18n.t('Baixar arquivos digitais selecionados')
            : i18n.t('Baixar todos os arquivos digitais')
        }
      >
        <Button
          className="btn-download uppercase"
          onClick={onDownload}
          disabled={!dataSource?.length || !groupsSelected.length}
        >
          <Space size={12}>
            <FiDownloadCloud size={14} />
          </Space>
        </Button>
      </Tooltip>
    );
  }

  function renderGroupFilter() {
    return (
      <TagFilter
        tags={tags}
        onChange={onChangeGroupFilter}
        title={i18n.t('Agrupar Por')}
      />
    );
  }

  function renderDownloadResultExport() {
    return (
      <Tooltip title={i18n.t('Exportar resultados')}>
        <Button
          className="btn-result-export uppercase"
          onClick={onChangeResultExport}
        >
          <Space size={14}>
            <BiExport size={16} />
          </Space>
        </Button>
      </Tooltip>
    );
  }

  function showColumn(column) {
    if (dataSource?.length) {
      return Object.keys(dataSource[0]).includes(column);
    }

    return false;
  }

  function onChangeResultExport() {
    onChangeDownload(groupsSelected);
  }

  const rowSelection = {
    onChange: (keysRows, rows) => {
      setSelectedRows(rows);
    },
  };

  function getColumns() {
    return columns.filter((column) => column.visible);
  }

  return (
    <div className="apuracao-por-cnpj">
      <Spin spinning={fetchingArquivoDigital && !isVisibleModalArquivoDigital}>
        <Row>
          <Col flex="auto">{renderDetalhesHeader()}</Col>
        </Row>

        <Row>
          <Col flex="auto">
            <Table
              rowKey={(row) => {
                const id = ['apuracao', row.cnpj, row.periodo]
                  .filter((value) => value)
                  .join('-');

                return id;
              }}
              rowSelection={groupsSelected.length && rowSelection}
              columns={getColumns()}
              dataSource={dataSource}
              isLoading={fetching}
              scroll={{ x: '100%' }}
              pagination={pagination}
              onChange={onChangeTable}
            />
          </Col>
        </Row>

        <ModalAlertasProcesso
          title={i18n.t('Detalhes de alertas da apuração')}
          alertas={currentApuracao?.alertas || []}
          isVisible={isVisibleModalAlertasApuracao}
          toggleVisible={toggleModalAlertasApuracao}
        />

        <ModalArquivoDigital
          cnpj={currentApuracao?.cnpj}
          periodo={currentApuracao?.periodo}
          isVisible={isVisibleModalArquivoDigital}
          fetching={fetchingArquivoDigital}
          toggleVisible={toggleModalArquivoDigital}
          download={downloadArquivoDigital}
          onFetch={fetchArquivoDigital}
        />

        <ModalErrosArquivoDigital
          arquivoDigital={currentArquivoDigital}
          isVisible={isVisibleModalErrosArquivoDigital}
          toggleVisible={toggleModalErrosArquivoDigital}
        />
      </Spin>
    </div>
  );
}

ApuracaoPorCNPJ.propTypes = {
  dataSource: Proptypes.arrayOf(Proptypes.shape({})),
  fetching: Proptypes.bool,
  fetchingArquivoDigital: Proptypes.bool.isRequired,
  pagination: Proptypes.shape({
    current: Proptypes.number,
    pageSize: Proptypes.number,
    total: Proptypes.number,
    showTotal: Proptypes.oneOfType([Proptypes.element, Proptypes.func]),
  }),
  onSelectApuracao: Proptypes.func,
  onChangeGroupFilter: Proptypes.func,
  onChangeTable: Proptypes.func,
  onChangeDownload: Proptypes.func,
  downloadArquivoDigital: Proptypes.func.isRequired,
  downloadAllArquivoDigital: Proptypes.func.isRequired,
  downloadSelectedArquivoDigital: Proptypes.func.isRequired,
  fetchArquivoDigital: Proptypes.func.isRequired,
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
  }).isRequired,
};

ApuracaoPorCNPJ.defaultProps = {
  dataSource: [],
  fetching: false,
  pagination: { current: 0, pageSize: 10, total: 0, showTotal: null },
  onSelectApuracao: () => null,
  onChangeGroupFilter: () => null,
  onChangeTable: () => null,
  onChangeDownload: () => null,
};

export default ApuracaoPorCNPJ;
