import * as React from 'react';
import { message, Modal, Space } from 'antd';
import { useMutation } from 'react-query';
import i18n from 'i18n';
import PropTypes from 'prop-types';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Icons
import { ImWarning } from 'react-icons/im';

export function RemoveModal({
  visible,
  produto,
  onSubmit,
  onSubmitSuccess,
  onClose,
}) {
  const { empresa } = useEmpresa();

  const removeMutation = useMutation(onSubmit, {
    onSuccess: () => {
      message.success(i18n.t('Produto removido com sucesso!'));
      onSubmitSuccess?.();
    },
    onError: () => message.error(i18n.t('Error ao remover produto!')),
  });

  function removeProduto() {
    removeMutation.mutate({ _id: produto._id, cnpjMatriz: empresa.cnpjMatriz });
    onClose();
  }

  return (
    <Modal
      className="modal-confirmation"
      title={i18n.t('Atenção')}
      okText={i18n.t('Remover')}
      cancelText={i18n.t('Cancelar')}
      width={450}
      visible={visible}
      closable={!removeMutation.isLoading}
      maskClosable={!removeMutation.isLoading}
      confirmLoading={removeMutation.isLoading}
      cancelButtonProps={{ disabled: removeMutation.isLoading }}
      onOk={removeProduto}
      onCancel={onClose}
    >
      <Space size={13}>
        <ImWarning size={30} />
        {i18n.t('Deseja realmente remover o produto {{desc}}?', {
          desc: produto?.desc,
        })}
      </Space>
    </Modal>
  );
}

RemoveModal.propTypes = {
  visible: PropTypes.bool,
  produto: PropTypes.shape({
    _id: PropTypes.string,
    desc: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

RemoveModal.defaultProps = {
  visible: false,
  produto: null,
  onSubmit: () => {},
  onSubmitSuccess: () => {},
  onClose: () => {},
};
