// https://mengshukeji.github.io/LuckysheetDocs/guide/cell.html#cell-attributes-table

export const Props = {
  CellType: 'ct',
  FontSize: 'fs',
  FontFamily: 'ff',
  FontColor: 'fc',
  BgColor: 'bg',
  TextBreak: 'tb', // text-wrap
  Bold: 'bl',
  VerticalType: 'vt', // vertical-align
};

export const CellType = {
  TEXT: { fa: '@', t: 's' },
  GENERAL: { fa: 'General', t: 'g' },
};

// Vertical align
export const VerticalType = {
  MIDDLE: 0,
  UP: 1,
  DOWN: 2,
};

// Text wrap
export const TextBreak = {
  TRUNCATION: 0,
  OVERFLOW: 1,
  WORD_WRAP: 2,
};

export const FontWeight = {
  REGULAR: 0,
  BOLD: 1,
};

export const FontFamily = {
  ARIAL: 'arial',
};
