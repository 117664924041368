import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import i18n from 'i18n';
import { Button, Card, notification, Form } from 'antd';
import useEmpresa from 'app/hooks/useEmpresa';
import SubHeader from 'app/components/Subheader';
import ProcessedRegFiscal from 'app/components/ProcessedRegFiscal';
import { get, triggerRegFiscaisReprocessing, triggerXmlReprocessing } from 'app/services/ImportService';
import { ImportStatusEnum, ImportTypesEnum } from 'app/constants/ImportTypesConstants';
import './EmpresaRegistroFiscal.scss';
import * as dateUtils from 'app/utils/date';
import ReprocessModal from 'app/components/ReprocessModal';

export const importsKeyFactory = {
  allFromCompany: (cnpjMatriz) => [{ scope: 'imports', cnpjMatriz }],
  filteredFromCompany: (cnpjMatriz, filters) => [
    { ...importsKeyFactory.allFromCompany(cnpjMatriz), ...filters },
  ],
};

const type = [ImportTypesEnum.REG_FISCAL_REPROCESS, ImportTypesEnum.SPED_REPROCESS];
const status = [ImportStatusEnum.PENDENTE, ImportStatusEnum.PROCESSANDO];
const limit = 1;

function EmpresaRegistroFiscal() {
  const [form] = Form.useForm();
  const { empresa } = useEmpresa();
  const { cnpjMatriz } = empresa || {};
  const [isRetificadoModalOpen, setIsRetificadoModalOpen] = useState(false);
  const [isXmlModalOpen, setIsXmlModalOpen] = useState(false);
  const [isRetificadoLoading, setIsRetificadoLoading] = useState(false); // Estado de carregamento para o reprocessamento retificado
  const [isXmlLoading, setIsXmlLoading] = useState(false); // Estado de carregamento para o reprocessamento XML
  const queryClient = useQueryClient();

  const queryKey = importsKeyFactory.filteredFromCompany(cnpjMatriz, {
    type,
    limit,
    status,
  });

  const { data: result } = useQuery(
    queryKey,
    () => get({ cnpjMatriz, type, status, limit }),
    { enabled: !!cnpjMatriz, refetchOnWindowFocus: false },
  );

  const isReprocessing = result?.data?.count > 0;

  const reprocessingRetificadosMutation = useMutation(
    (payload) => triggerRegFiscaisReprocessing({ cnpjMatriz, payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        setIsRetificadoLoading(false);
        notification.error({
          message: i18n.t('Erro ao iniciar reprocessamento'),
        });
      },
    },
  );

  const xmlsReprocessingMutation = useMutation(
    (payload) => triggerXmlReprocessing({ cnpjMatriz, payload }), // Adicione a função de reprocessamento de XMLs
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        setIsXmlLoading(false);
        notification.error({
          message: i18n.t('Erro ao iniciar reprocessamento de XMLs'),
        });
      },
    },
  );

  function openRetificadoModal() {
    setIsRetificadoModalOpen(true);
  }

  function hideRetificadoModal() {
    setIsRetificadoModalOpen(false);
  }

  function openXmlModal() {
    setIsXmlModalOpen(true);
  }

  function hideXmlModal() {
    setIsXmlModalOpen(false);
  }

  async function onRetificadoModalConfirmation() {
    const periodoInicio = form.getFieldValue('periodoInicio');
    const periodoFim = form.getFieldValue('periodoFim');
    const payload = {};

    if (periodoInicio) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodoInicio);
    }
    if (periodoFim) {
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodoFim);
    }

    try {
      await reprocessingRetificadosMutation.mutateAsync(payload);
    } catch (error) {
      notification.error({
        message: i18n.t('Erro ao iniciar reprocessamento dos retificados'),
      });
    } finally {
      hideRetificadoModal();
    }
  }

  async function onXmlModalConfirmation() {
    const periodoInicio = form.getFieldValue('periodoInicio');
    const periodoFim = form.getFieldValue('periodoFim');
    const payload = {};

    if (periodoInicio) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodoInicio);
    }
    if (periodoFim) {
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodoFim);
    }

    setIsXmlLoading(true);

    try {
      await xmlsReprocessingMutation.mutateAsync(payload);
    } catch (error) {
      notification.error({
        message: i18n.t('Erro ao iniciar reprocessamento de XMLs'),
      });
    } finally {
      hideXmlModal();
    }
  }

  return (
    <Card className="empresa-registro-fiscal">
      <SubHeader
        title={i18n.t('Registro fiscal')}
        prefix={
          <>
            <div style={{ display: 'flex' }}>
              {/* Botão para reprocessar retificados */}
              <Button
                type="default"
                danger
                disabled={isRetificadoLoading || isReprocessing}
                className="uppercase"
                loading={isRetificadoLoading}
                onClick={openRetificadoModal}
                style={{ marginRight: '8px' }}
              >
                {isRetificadoLoading ? i18n.t('Reprocessando...') : i18n.t('Reprocessar Retificados')}
              </Button>
              {/* Botão para reprocessar XMLs */}
              <Button
                type="default"
                danger
                disabled={isXmlLoading || isReprocessing}
                className="uppercase"
                loading={isXmlLoading}
                onClick={openXmlModal}
              >
                {isXmlLoading
                  ? i18n.t('Reprocessando...')
                  : i18n.t('Reprocessar XMLs')}
              </Button>
            </div>
            <ReprocessModal
              title={i18n.t('Reprocessar Retificados')}
              visible={isRetificadoModalOpen}
              isLoading={reprocessingRetificadosMutation.isLoading}
              onCancel={hideRetificadoModal}
              onConfirmation={onRetificadoModalConfirmation}
              form={form}
              confirmationText={i18n.t('Ao realizar este processo, todos os registros fiscais e SPEDs que foram retificados, seja pelo processo REGFISCAL_RETIFICADO ou RETIFICA_CPROD, serão restaurados ao estado inicial.')}
            />
            <ReprocessModal
              title={i18n.t('Reprocessar XMLs')}
              visible={isXmlModalOpen}
              isLoading={xmlsReprocessingMutation.isLoading}
              onCancel={hideXmlModal}
              onConfirmation={onXmlModalConfirmation}
              form={form}
              confirmationText={i18n.t('Ao realizar este processo, todos os registros fiscais que correspondam ao filtro de período serão restaurados ao seu estado original. Você pode acompanhar o progresso deste processo na tela de Importação de Registros Fiscais.')}
            />
          </>
        }
      />
      <ProcessedRegFiscal matriz={empresa} />
    </Card>
  );
}

export default EmpresaRegistroFiscal;
