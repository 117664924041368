import Api from './Api';

const resource = 'arquivos-digitais/reg1050';
/**
 * @param {Object} params
 * @param {string} params.cnpjMatriz
 * @param {string} params.cnpjs Search by cnpj from filial
 * @param {string} params.cProd Search by cProd
 * @param {string} params.periodo Search by periodo
 * @return {Promise<any>}
 */
export function getArqDig1050(params) {
  const { cnpjMatriz, cProd, cnpjs, periodoInicio, periodoFim, skip, limit } =
    params;
  return Api.get(`empresas/${cnpjMatriz}/${resource}`, {
    params: { cnpjs, cProd, periodoInicio, periodoFim, skip, limit },
  });
}

export function resetArqDig1050({ cnpjMatriz, payload }) {
  return Api.delete(`empresas/${cnpjMatriz}/${resource}`, {
    params: { ...payload },
  });
}
