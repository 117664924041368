import React, { useState } from 'react';
import i18n from 'i18n';

// External Components
import { Card, Col, Divider, Row, Spin } from 'antd';

// Styles
import './EmpresaDetalhes.scss';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Components
import Subheader from 'app/components/Subheader';
import FormEmpresa from 'app/components/FormEmpresa';
import Filiais from 'app/components/Filiais';
import FilialDetailsDrawer from 'app/components/EmpresaDetailsPanel';
import ModalRemoveEmpresa from 'app/components/ModalRemoveEmpresa';

function EmpresaDetalhes() {
  const {
    empresa,
    fetching,
    saving,
    fetchEmpresa,
    requestEdit,
    requestCreate,
  } = useEmpresa();

  const [currentFilial, setCurrentFilial] = useState(null);
  const [showFilialPanel, setShowFilialPanel] = useState(false);
  const [showModalRemoveEmpresa, setShowModalRemoveEmpresa] = useState(false);

  function onOpenFilialDetails(filial) {
    setCurrentFilial(filial);
    setShowFilialPanel(true);
  }

  function onRemoveFilial(filial) {
    setCurrentFilial(filial);
    setShowModalRemoveEmpresa(true);
  }

  function onSave() {
    setShowFilialPanel(false);
    fetchEmpresa(empresa._id);
  }

  return (
    <Card className="empresa-detalhes">
      <Spin spinning={fetching || saving}>
        {empresa && (
          <FormEmpresa
            empresa={empresa}
            saving={saving}
            requestCreate={requestCreate}
            requestEdit={requestEdit}
            onClickCreateFilial={() => onOpenFilialDetails()}
            onSuccessFiliaisImport={() => fetchEmpresa(empresa._id)}
          />
        )}

        {empresa?.filiais?.length > 0 && (
          <Row>
            <Col span={24}>
              <Divider />

              <Subheader title={i18n.t('Filiais')} />

              <Filiais
                dataSource={empresa.filiais}
                openFilialDetails={onOpenFilialDetails}
                removeFiliais={onRemoveFilial}
              />

              <ModalRemoveEmpresa
                empresa={currentFilial}
                isVisible={showModalRemoveEmpresa}
                toggleVisible={setShowModalRemoveEmpresa}
                afterRemove={() => fetchEmpresa(empresa._id)}
              />
            </Col>
          </Row>
        )}

        <FilialDetailsDrawer
          cnpjMatriz={empresa?.cnpj}
          empresa={currentFilial}
          visible={showFilialPanel}
          onSave={onSave}
          onClose={() => setShowFilialPanel(false)}
        />
      </Spin>
    </Card>
  );
}

export default EmpresaDetalhes;
