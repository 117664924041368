import {
  Props,
  CellType,
  FontFamily,
  FontWeight,
  TextBreak,
  VerticalType,
} from './constants';

export const defaultStyle = {
  [Props.CellType]: CellType.TEXT,
  [Props.FontFamily]: FontFamily.ARIAL,
  [Props.FontSize]: 8,
};

export const headerStyle = {
  ...defaultStyle,
  [Props.BgColor]: '#f3f3f3',
  [Props.FontColor]: '#383838',
  [Props.Bold]: FontWeight.BOLD,
  [Props.TextBreak]: TextBreak.WORD_WRAP,
  [Props.VerticalType]: VerticalType.DOWN,
};
