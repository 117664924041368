import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Modal } from 'antd';

// Styles
import './ModalViewJson.scss';

function ModalViewJson({ item, visible, toggleVisible }) {
  function closeModal() {
    toggleVisible(false);
  }

  return (
    <Modal
      className="modal-view-json"
      title={i18n.t('Detalhes do item')}
      visible={visible}
      width={700}
      footer={null}
      onCancel={closeModal}
    >
      <pre>{JSON.stringify(item, null, 2)}</pre>
    </Modal>
  );
}

ModalViewJson.propTypes = {
  visible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
  item: Proptypes.object,
};

ModalViewJson.defaultProps = {
  item: null,
};

export default ModalViewJson;
