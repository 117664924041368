import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Proptypes from 'prop-types';
import i18n from 'i18n';
import moment from 'moment';

// Icons
import { FiDownloadCloud } from 'react-icons/fi';
import { AiOutlineFileSearch } from 'react-icons/ai';
// import { FaTrashAlt } from 'react-icons/fa';

// External components
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Grid,
  message,
  Row,
  Space,
  Tooltip,
} from 'antd';

// Utils
import * as dateUtils from 'app/utils/date';
import { cnpjFormat } from 'app/utils/masks';

// Services
import {
  getAll,
  download,
  downloadAll,
  removeProcessedSpeds
} from 'app/services/SpedService';

// Components
import Table from 'app/components/Table';
import SelectEmpresa from 'app/components/Select/SelectEmpresa';
import ModalRemoveFile from 'app/components/ModalRemoveFile';
import ModalViewJson from 'app/components/ModalViewJson';

// Styles
import './ProcessedSped.scss';
import Subheader from '../Subheader';

const { RangePicker } = DatePicker;
const { useBreakpoint } = Grid;

function ProcessedSped({ matriz, isTabActive }) {
  const [form] = Form.useForm();
  const breakpoint = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();

  // States
  const [dataSource, setDataSource] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [currentFileNames] = useState([]);
  const [isVisibleModalRemove, setIsVisibleModalRemove] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sped, setSped] = useState(null);
  const [showModalDetalhes, setShowModalDetalhes] = useState(false);

  // Pagination
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const hasFiliais = matriz?.filiais?.length > 0;

  const cnpjsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 8,
  };

  const periodoBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: hasFiliais ? 6 : 10,
  };

  const actionsBreakpoints = {
    xs: 24,
    sm: 24,
    md: 12,
    lg: 4,
    flex: breakpoint.lg ? '20rem' : 'auto',
  };

  useEffect(() => {
    // Make the first request if it is a company without branches
    if (matriz?.cnpj && !hasFiliais) {
      fetchSpeds(getRequestParams(pageSize, currentPage));
    }
  }, [matriz]);

  useEffect(() => {
    setFieldsByQueryParams();
  }, []);

  useEffect(() => {
    if (isTabActive && matriz?.cnpj) {
      updateRoute(getRequestParams(pageSize, 1));
    }
  }, [isTabActive]);

  async function fetchSpeds(params) {
    setFetching(true);

    try {
      const { data } = await getAll(params);
      const { results, count } = data;

      setDataSource(results || []);
      setTotalItems(count || 0);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar os SPEDs processados'));
    } finally {
      setFetching(false);
    }
  }

  async function deleteSpeds(params) {
    setFetching(true);
    try {
      await removeProcessedSpeds(params);
    } catch (error) {
      throw new Error(error);
    } finally {
      setFetching(false);
    }
  }

  function getRequestParams(limit, page) {
    const cnpjs = form.getFieldValue('cnpjs');
    const periodo = form.getFieldValue('periodo');

    const payload = {
      cnpjs: hasFiliais ? cnpjs : [matriz.cnpj],
      cnpjMatriz: matriz.cnpj,
      limit,
      skip: page,
    };

    if (periodo?.length === 2) {
      payload.periodoInicio = dateUtils.formatProcessoRangeDate(periodo[0]);
      payload.periodoFim = dateUtils.formatProcessoRangeDate(periodo[1]);
    }

    return payload;
  }

  async function afterUpdateItem() {
    setCurrentPage(1);
    setSelectedRows([]);
    await fetchSpeds(getRequestParams(pageSize, 1));
  }

  async function onFinish() {
    setCurrentPage(1);
    updateRoute(getRequestParams(pageSize, 1));
    await fetchSpeds(getRequestParams(pageSize, 1));
  }

  async function downloadSped(id) {
    try {
      await download({ id, cnpjMatriz: matriz.cnpj });
    } catch (error) {
      message.error(i18n.t('Erro ao realizar o download do sped.'));
    }
  }

  async function onDownload() {
    try {
      console.log(selectedRows);
      const _ids = selectedRows.length ? selectedRows.map(({ _id }) => _id) : undefined
      await downloadAll({ cnpjMatriz: matriz.cnpj, _ids });
    } catch (error) {
      console.log(error);
      message.error(i18n.t('Erro ao realizar o download do sped.'));
    }
  }

  function updateRoute(params) {
    if (!isTabActive || !params) {
      return;
    }

    const { periodoInicio, periodoFim, cnpjs } = params;
    const queryParams = {};

    if (periodoInicio) {
      queryParams.periodoInicio = periodoInicio;
    }

    if (periodoFim) {
      queryParams.periodoFim = periodoFim;
    }

    if (hasFiliais && cnpjs) {
      queryParams.cnpjs = cnpjs;
    }

    setSearchParams(queryParams);
  }

  function setFieldsByQueryParams() {
    const periodoInicio = searchParams.get('periodoInicio');
    const periodoFim = searchParams.get('periodoFim');
    const cnpjs = searchParams.getAll('cnpjs');

    let cnpjsByQuery;
    let periodoByQuery;

    if (periodoInicio && periodoFim) {
      periodoByQuery = [
        moment(periodoInicio, 'MM-YYYY'),
        moment(periodoFim, 'MM-YYYY'),
      ];
    }

    if (cnpjs) {
      typeof cnpjs === 'string'
        ? (cnpjsByQuery = [cnpjs])
        : (cnpjsByQuery = [...cnpjs]);
    }

    form.setFieldsValue({
      periodo: periodoByQuery,
      cnpjs: cnpjsByQuery,
    });
  }

  function toggleModalDetalhes(status) {
    setShowModalDetalhes(status);
  }

  function onClickViewDetalhes(current) {
    setSped(current);
    toggleModalDetalhes(true);
  }

  function renderMoreActions(_, row) {
    return (
      <Space size={2}>
        <Tooltip title={i18n.t('Visualizar')}>
          <Button
            className="view-detalhes-button"
            type="link"
            size="small"
            onClick={() => onClickViewDetalhes(row)}
          >
            <AiOutlineFileSearch size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Baixar')}>
          <Button
            className="download-sped-button"
            type="link"
            size="small"
            onClick={() => downloadSped(row._id)}
          >
            <FiDownloadCloud size={18} />
          </Button>
        </Tooltip>
      </Space>
    );
  }

  const columns = [
    {
      title: i18n.t('Arquivo'),
      dataIndex: 'fileName',
      key: 'id',
      render: (fileName, row) => (
        <Button
          className="link-text-button"
          type="link"
          onClick={() => downloadSped(row.id)}
        >
          {fileName}
        </Button>
      ),
    },
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 200,
      render: (cnpj) => cnpj && cnpjFormat(cnpj),
    },
    {
      title: i18n.t('Período'),
      dataIndex: 'periodo',
      key: 'periodo',
      width: 170,
      className: 'first-letter-capitalize',
      render: (periodo) => periodo && dateUtils.formatPeriodo(periodo),
    },
    {
      title: i18n.t('Baixar'),
      width: 110,
      fixed: 'right',
      render: renderMoreActions,
    },
  ];

  function onChangeTable(paginate) {
    setPageSize(paginate.pageSize);
    setCurrentPage(paginate.current);
    fetchSpeds(getRequestParams(paginate.pageSize, paginate.current));
  }

  const rowSelection = {
    onChange: (keysRows, rows) => {
      setSelectedRows(rows);
    },
  };

  // function openModalRemoveMultipleFiles() {
  //   const files = selectedRows.map((row) => row.fileName);

  //   setCurrentFileNames(files);
  //   toggleModalRemove(true);
  // }

  // function openModalRemoveAllFiles() {
  //   setCurrentFileNames([]);
  //   toggleModalRemove(true);
  // }

  function toggleModalRemove(status) {
    setIsVisibleModalRemove(status);
  }

  function removeFile() {
    if (!selectedRows.length) {
      return removeAllFiles();
    }

    const ids = selectedRows.map((row) => row.id);
    return deleteSpeds({ ids });
  }

  async function removeAllFiles() {
    const cnpjs = form.getFieldValue('cnpjs') || [matriz.cnpj];
    return deleteSpeds({ truncate: true, cnpjs });
  }

  // function renderDeleteSelectedButton() {
  //   return (
  //     <Row className="row-remove-row">
  //       <Col span={24}>
  //         {selectedRows.length > 0 ? (
  //           <Tooltip title="Remover apenas os SPEDS selecionados.">
  //             <Button
  //               className="btn-remove-row"
  //               icon={<FaTrashAlt size={16} />}
  //               onClick={openModalRemoveMultipleFiles}
  //             >
  //               {i18n.t('Remover selecionados')}
  //             </Button>
  //           </Tooltip>
  //         ) : (
  //           <Tooltip title="Remover todos os SPEDS de todos os CNPJs do filtro.">
  //             <Button
  //               className="btn-remove-row"
  //               icon={<FaTrashAlt size={16} />}
  //               onClick={openModalRemoveAllFiles}
  //               disabled={!dataSource?.length}
  //             >
  //               {i18n.t('Remover TODOS')}
  //             </Button>
  //           </Tooltip>
  //         )}
  //       </Col>
  //     </Row>
  //   );
  // }

  function renderTotalItems() {
    const label =
      totalItems > 1
        ? i18n.t('{{totalItems}} SPEDs processados', {
            totalItems,
          })
        : i18n.t('{{totalItems}} SPED processado', {
            totalItems,
          });

    return <span>{label}</span>;
  }

  function renderDownloadButton() {
    return (
      <Tooltip
        title={
          selectedRows.length
            ? i18n.t('Baixar SPEDs selecionados')
            : i18n.t('Baixar todos os SPEDs')
        }
      >
        <Button
          className="btn-download uppercase"
          onClick={onDownload}
          // disabled={!dataSource?.length || !groupsSelected.length}
        >
          <Space size={12}>
            <FiDownloadCloud size={14} />
          </Space>
        </Button>
      </Tooltip>
    );
  }


  function renderDetalhesHeader() {
    return (
      <Subheader
        title={i18n.t('Lista de SPEDs')}
        prefix={
          <div className="actions">
            {renderDownloadButton()}
          </div>
        }
      />
    );
  }

  return (
    <div className="processed-sped">
      <Form form={form} onFinish={onFinish}>
        <Row className="filter-options" gutter={[16, 16]}>
          {hasFiliais && (
            <Col {...cnpjsBreakpoints}>
              <Form.Item
                name="cnpjs"
                required
                rules={[
                  {
                    required: true,
                    message: i18n.t('Campo CNPJ é obrigatório'),
                  },
                ]}
              >
                <SelectEmpresa
                  allowSelectAll
                  selectAll
                  cnpjMatriz={matriz.cnpj}
                  onFetch={() =>
                    fetchSpeds(getRequestParams(pageSize, currentPage))
                  }
                />
              </Form.Item>
            </Col>
          )}

          <Col {...periodoBreakpoints}>
            <Form.Item name="periodo" noStyle>
              <RangePicker
                picker="month"
                format="MM-YYYY"
                placeholder={[
                  i18n.t('Data de início'),
                  i18n.t('Data de término'),
                ]}
              />
            </Form.Item>
          </Col>

          <Col {...actionsBreakpoints}>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="button-filtrar uppercase"
            >
              {i18n.t('Filtrar')}
            </Button>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col flex="auto">{renderDetalhesHeader()}</Col>
        </Row>

        {/* {renderDeleteSelectedButton()} */}

        <Row>
          <Col flex="auto">
            <Table
              rowSelection={rowSelection}
              rowKey="_id"
              columns={columns}
              dataSource={dataSource}
              isLoading={fetching}
              scroll={{ x: '100rem' }}
              pagination={{
                current: currentPage,
                pageSize,
                total: totalItems,
                showTotal: renderTotalItems,
              }}
              onChange={onChangeTable}
            />
          </Col>
        </Row>

        <ModalRemoveFile
          files={currentFileNames}
          isVisible={isVisibleModalRemove}
          toggleVisible={toggleModalRemove}
          afterRemove={afterUpdateItem}
          removeFile={removeFile}
        />

        <ModalViewJson
          item={sped}
          visible={showModalDetalhes}
          toggleVisible={toggleModalDetalhes}
        />
      </Form>
    </div>
  );
}

ProcessedSped.propTypes = {
  matriz: Proptypes.shape({
    cnpj: Proptypes.string,
    filiais: Proptypes.arrayOf(Proptypes.shape({})),
  }),
  isTabActive: Proptypes.bool,
};

ProcessedSped.defaultProps = {
  matriz: null,
  isTabActive: false,
};

export default ProcessedSped;
