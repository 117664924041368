import React from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// External components
import { Card, Button } from 'antd';

// Utils
import { cnpjFormat } from 'app/utils/masks';

// Components
import Table from 'app/components/Table';
import RowActionButton from 'app/components/RowActionButton';

// Styles
import './FIliais.scss';

function Filiais({ dataSource, openFilialDetails, removeFiliais }) {
  const columns = [
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 200,
      filter: true,
      render: (cnpj, row) => (
        <Button
          className="cnpj-button"
          type="link"
          onClick={() => openFilialDetails(row)}
        >
          {cnpjFormat(cnpj)}
        </Button>
      ),
    },
    {
      title: i18n.t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      width: 300,
      filter: true,
    },
    {
      title: i18n.t('Nome fantasia'),
      dataIndex: 'nomeFantasia',
      key: 'nomeFantasia',
      width: 300,
      filter: true,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (data, row) => (
        <RowActionButton
          onClickEdit={() => openFilialDetails(row)}
          onClickRemove={() => removeFiliais(row)}
        />
      ),
    },
  ];

  return (
    <Card className="filiais">
      <Table columns={columns} dataSource={dataSource} rowKey="_id" />
    </Card>
  );
}

Filiais.propTypes = {
  dataSource: Proptypes.arrayOf(Proptypes.shape({})),
  openFilialDetails: Proptypes.func,
  removeFiliais: Proptypes.func,
};

Filiais.defaultProps = {
  dataSource: [],
  openFilialDetails: () => null,
  removeFiliais: () => null,
};

export default Filiais;
