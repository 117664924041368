import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Styles
import './FileScheduleTable.scss';

// External components
import { Button, Col, message, Row, Space, Tooltip } from 'antd';

// Enums
import { ScheduleStatusEnum } from 'app/constants/ScheduleTypesConstants';

// Icons
import { FiDownloadCloud } from 'react-icons/fi';
import { AiOutlineStop, AiOutlineInfoCircle } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';

// Utils
import { formatFullDate } from 'app/utils/date';

// Components
import ScheduleStatusTag from 'app/components/ScheduleStatusTag';
import Table from 'app/components/Table';
import Subheader from 'app/components/Subheader';

function FileScheduleTable({
  files,
  refetch,
  download,
  cancel,
  loading,
  pagination,
  onCancel,
}) {
  async function requestDownload(currentFile) {
    try {
      await download(currentFile._id);
      message.success(i18n.t('Arquivo baixado com sucesso'));
    } catch (error) {
      message.error(i18n.t('Erro ao baixar arquivo'));
    }
  }

  async function requestCancel(currentFile) {
    try {
      await cancel(currentFile._id);
      message.success(i18n.t('Processamento do arquivo cancelado com sucesso'));
      onCancel?.();
    } catch (error) {
      message.error(i18n.t('Erro ao cancelar processamento de arquivo'));
    }
  }

  function renderMoreActions(row) {
    const { status, result } = row;

    const { PENDENTE, CONCLUIDO } = ScheduleStatusEnum;

    const canDownload =
      status === CONCLUIDO &&
      result &&
      (result.discartedFiles ||
        result.filesWithError ||
        result.filesWithInvalidContent ||
        result.ignoredFiles);
    const canCancel = status === PENDENTE;

    return (
      <Space size={2}>
        <Tooltip title={i18n.t('Cancelar processamento')}>
          <Button
            className="process-cancel-button"
            type="link"
            size="small"
            disabled={!canCancel}
            onClick={() => requestCancel(row)}
          >
            <AiOutlineStop size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Baixar arquivos com problemas')}>
          <Button
            className="download-relatorio-button"
            type="link"
            size="small"
            disabled={!canDownload}
            onClick={() => requestDownload(row)}
          >
            <FiDownloadCloud size={18} />
          </Button>
        </Tooltip>
      </Space>
    );
  }

  const columns = [
    {
      title: i18n.t('Nome do arquivo'),
      dataIndex: 'fileOriginalName',
      key: 'fileOriginalName',
    },
    {
      title: i18n.t('Atualizado em'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: formatFullDate,
    },
    {
      title: i18n.t('Arquivos'),
      children: [
        {
          title: (
            <Tooltip title="Arquivos que foram processados">
              <Space>
                <span>{i18n.t('Processados')}</span>
                <AiOutlineInfoCircle size={14} />
              </Space>
            </Tooltip>
          ),
          dataIndex: 'result',
          key: 'result',
          className: 'file-result',
          render: (result) => result?.processedFiles || 0,
        },
        {
          title: (
            <Tooltip title="Arquivos com extensão inválida que não são úteis pelo processo">
              <Space>
                <span>{i18n.t('Descartados')}</span>
                <AiOutlineInfoCircle size={14} />
              </Space>
            </Tooltip>
          ),
          dataIndex: 'result',
          key: 'result',
          className: 'file-result',
          render: (result) => result?.discartedFiles || 0,
        },
        {
          title: (
            <Tooltip title="Arquivos que foram ignorados devido a alguma regra não mapeada ou que não pertencem aos arquivos da rede">
              <Space>
                <span>{i18n.t('Ignorados')}</span>
                <AiOutlineInfoCircle size={14} />
              </Space>
            </Tooltip>
          ),
          dataIndex: 'result',
          key: 'result',
          className: 'file-result',
          render: (result) => result?.ignoredFiles || 0,
        },
        {
          title: (
            <Tooltip title="Arquivos com extensão válida, porém com conteúdo inválido. Por exemplo: Arquivos sem conteúdo ou com erro no fechamento das tags (caso de XMLs) ou erro na estrutura do SPED (caso dos TXTs)">
              <Space>
                <span>{i18n.t('Conteúdo inválido')} </span>
                <AiOutlineInfoCircle size={14} />
              </Space>
            </Tooltip>
          ),
          dataIndex: 'result',
          key: 'result',
          className: 'file-result',
          render: (result) => result?.filesWithInvalidContent || 0,
        },
        {
          title: (
            <Tooltip title="Arquivos que apresentaram erro durante a importação devido a algum problema no sistema. Por exemplo: algum erro no servidor">
              <Space>
                <span>{i18n.t('Erros')}</span>
                <AiOutlineInfoCircle size={14} />
              </Space>
            </Tooltip>
          ),
          dataIndex: 'result',
          key: 'result',
          className: 'file-result',
          render: (result) => result?.filesWithError || 0,
        },
      ],
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <ScheduleStatusTag status={status} />,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (data, row) => renderMoreActions(row),
    },
  ];

  return (
    <div className="file-schedule-table">
      <Subheader
        title={i18n.t('Processamentos')}
        prefix={
          <Button onClick={refetch}>
            <IoMdRefresh size={18} />
          </Button>
        }
      />

      <Row>
        <Col span={24}>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={files}
            isLoading={loading}
            scroll={{ x: '100%' }}
            data
            pagination={pagination}
          />
        </Col>
      </Row>
    </div>
  );
}

FileScheduleTable.propTypes = {
  files: Proptypes.array,
  cancel: Proptypes.func,
  download: Proptypes.func,
  loading: Proptypes.bool,
  refetch: Proptypes.func,
  pagination: Proptypes.object,
  onCancel: Proptypes.func,
};

FileScheduleTable.defaultProps = {
  files: [],
  cancel: () => null,
  download: () => null,
  loading: false,
  refetch: null,
  pagination: () => {},
  onCancel: null,
};

export default FileScheduleTable;
