import React, { useEffect } from 'react';
import Proptypes from 'prop-types';

// Helpers
import { buildLuckysheetOptions } from './helpers';

// Styles
import './Luckysheet.scss';

function Luckysheet({ options }) {
  const { columnlen, rowlen, documentName, sheetName, data } = options;

  useEffect(() => {
    buildSpreadsheet();
  }, [options]);

  function buildSpreadsheet() {
    const { luckysheet } = window;

    const luckysheetOptions = buildLuckysheetOptions({
      container: 'luckysheet',
      data,
      columnlen,
      rowlen,
      sheetName,
      documentName,
    });

    luckysheet.create(luckysheetOptions);
  }

  return <div id="luckysheet" />;
}

Luckysheet.propTypes = {
  options: Proptypes.shape({
    data: Proptypes.arrayOf(Proptypes.arrayOf(Proptypes.string)),
    columnlen: Proptypes.objectOf(Proptypes.number),
    rowlen: Proptypes.objectOf(Proptypes.number),
    sheetName: Proptypes.string,
    documentName: Proptypes.string,
  }).isRequired,
};

Luckysheet.defaultProps = {};

export default Luckysheet;
