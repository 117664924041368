import React, { useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Styles
import './ApuracaoScheduleTable.scss';

// External components
import { Button, Col, message, Modal, Row, Space, Tooltip } from 'antd';

// Enums
import { ScheduleStatusEnum } from 'app/constants/ScheduleTypesConstants';

// Icons
import { AiOutlineFileSearch, AiOutlineStop } from 'react-icons/ai';
import { IoMdRefresh } from 'react-icons/io';

// Utils
import { formatFullDate } from 'app/utils/date';

// Services
import { cancel } from 'app/services/ProcessoService';

// Components
import ModalDetalhesApuracao from 'app/components/ModalDetalhesApuracao';
import ScheduleStatusTag from 'app/components/ScheduleStatusTag';
import Table from 'app/components/Table';
import Subheader from 'app/components/Subheader';
import { ImWarning } from 'react-icons/im';

const { PENDENTE, PROCESSANDO } = ScheduleStatusEnum;

function ApuracaoScheduleTable({ apuracoes, loading, refetch, onCancel }) {
  const [apuracao, setApuracao] = useState(null);
  const [showModalDetalhesApuracao, setShowModalDetalhesApuracao] =
    useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModalDetalhesApuracao(status) {
    setShowModalDetalhesApuracao(status);
  }

  function onClickViewDetalhesApuracao(currentApuracao) {
    setApuracao(currentApuracao);
    toggleModalDetalhesApuracao(true);
  }

  async function requestCancel(currentApuracao) {
    try {
      await cancel(currentApuracao._id);
      message.success(
        i18n.t('Processamento da apuração cancelada com sucesso'),
      );
      onCancel?.();
    } catch (error) {
      message.error(i18n.t('Erro ao cancelar processamento da apuração'));
    }
  }

  function renderMoreActions(row) {
    const canCancel = [PENDENTE, PROCESSANDO].includes(row.status);

    function openModal() {
      setIsModalOpen(true);
    }

    function hideModal() {
      setIsModalOpen(false);
    }

    return (
      <Space size={2}>
        <Tooltip title={i18n.t('Visualizar detalhes')}>
          <Button
            className="view-detalhes-relatorio-button"
            type="link"
            size="small"
            onClick={() => onClickViewDetalhesApuracao(row)}
          >
            <AiOutlineFileSearch size={18} />
          </Button>
        </Tooltip>

        <Tooltip title={i18n.t('Cancelar processamento')}>
          <Button
            className="process-cancel-button"
            type="link"
            size="small"
            disabled={!canCancel}
            onClick={() => openModal(row)}
          >
            <AiOutlineStop size={18} />
          </Button>
        </Tooltip>
        <Modal
          className="modal-confirmation"
          title={i18n.t('Atenção')}
          okText={i18n.t('Sim')}
          cancelText={i18n.t('Não')}
          width={450}
          visible={isModalOpen}
          onOk={() => {
            requestCancel(row);
            hideModal();
          }}
          onCancel={hideModal}
        >
          <Space size={13}>
            <ImWarning size={30} />
            {i18n.t('Você realmente deseja cancelar esse processo?')}
          </Space>
        </Modal>
      </Space>
    );
  }

  const columns = [
    {
      title: i18n.t('Atualizado em'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: formatFullDate,
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => <ScheduleStatusTag status={status} />,
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (data, row) => renderMoreActions(row),
    },
  ];

  return (
    <div className="apuracao-schedule-table">
      <Subheader
        title={i18n.t('Processamentos')}
        prefix={
          <Button onClick={refetch}>
            <IoMdRefresh size={18} />
          </Button>
        }
      />

      <Row>
        <Col span={24}>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={apuracoes}
            isLoading={loading}
            scroll={{ x: '100%' }}
            data
          />
        </Col>
      </Row>

      <ModalDetalhesApuracao
        apuracao={apuracao}
        visible={showModalDetalhesApuracao}
        toggleVisible={toggleModalDetalhesApuracao}
      />
    </div>
  );
}

ApuracaoScheduleTable.propTypes = {
  apuracoes: Proptypes.array,
  loading: Proptypes.bool,
  refetch: Proptypes.func,
  onCancel: Proptypes.func,
};

ApuracaoScheduleTable.defaultProps = {
  apuracoes: [],
  loading: false,
  refetch: null,
  onCancel: null,
};

export default ApuracaoScheduleTable;
