import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// External components
import { Button, Col, Drawer, Form, Input, message, Row } from 'antd';

// Utils
import { isMatriz } from 'app/utils/cnpj';

// Services
import { create, edit } from 'app/services/EmpresasService';

// Components
import InputCNPJ from 'app/components/InputCNPJ';

function EmpresaDetailsPanel({ empresa, cnpjMatriz, visible, onSave, onClose }) {
  // States
  const [form] = Form.useForm();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (visible) {
      let fields = {
        _id: '',
        nome: '',
        nomeFantasia: '',
        cnpj: '',
        cnpjMatriz,
        isMatriz: false,
      };

      if (empresa) {
        fields = {
          ...fields,
          ...empresa,
        };
      }

      form.setFieldsValue(fields);
    }
  }, [visible]);

  async function handleSubmit() {
    const fields = form.getFieldsValue();

    setSaving(true);

    if (fields.cnpj) {
      fields.isMatriz = isMatriz(fields.cnpj);
    }

    if (fields._id) {
      await handleUpdate(fields);
      return;
    }

    await handleCreate(fields);
  }

  async function handleCreate(fields) {
    try {
      await create(fields);
      message.success(i18n.t('Empresa criada com sucesso'));
      onSave();
    } catch (error) {
      message.error(i18n.t('Erro ao criar empresa'));
    } finally {
      setSaving(false);
    }
  }

  async function handleUpdate(fields) {
    try {
      await edit(fields);
      message.success(i18n.t('Empresa atualizada com sucesso'));
      onSave();
    } catch (error) {
      message.error(i18n.t('Erro ao atualizar empresa'));
    } finally {
      setSaving(false);
    }
  }

  function renderFooter() {
    return (
      <Row justify="end">
        <Button
          className="save-button uppercase"
          type="primary"
          disabled={saving}
          htmlType="submit"
          onClick={handleSubmit}
        >
          {i18n.t('Salvar')}
        </Button>
      </Row>
    );
  }

  return (
    <Form
      name="empresa-detais-panel"
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
    >
      <Drawer
        className="empresa-details-panel-drawer"
        title={i18n.t('Informações da empresa')}
        placement="right"
        width={400}
        visible={visible}
        getContainer={false}
        footer={renderFooter()}
        onClose={onClose}
      >
        <Row gutter={16}>
          <Form.Item name="_id" hidden>
            <Input />
          </Form.Item>

          <Form.Item name="isMatriz" hidden>
            <Input />
          </Form.Item>

          {cnpjMatriz && (
            <Col span={24}>
              <Form.Item name="cnpjMatriz" label={i18n.t('CNPJ matriz')}>
                <InputCNPJ disabled />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item name="cnpj" label={i18n.t('CNPJ')}>
              <InputCNPJ />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="nome" label={i18n.t('Nome')}>
              <Input />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="nomeFantasia" label={i18n.t('Nome fantasia')}>
              <Input />
            </Form.Item>
          </Col>

          {!cnpjMatriz && (
            <Col span={24}>
              <Form.Item name="discordWebhook" label={i18n.t('Discord Webhook URL')}>
                <Input />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Drawer>
    </Form>
  );
}

EmpresaDetailsPanel.propTypes = {
  empresa: Proptypes.shape({
    _id: Proptypes.string,
    cnpj: Proptypes.string,
    nome: Proptypes.string,
    nomeFantasia: Proptypes.string,
    discordWebhook: Proptypes.string,
  }),
  cnpjMatriz: Proptypes.string,
  visible: Proptypes.bool.isRequired,
  onSave: Proptypes.func.isRequired,
  onClose: Proptypes.func.isRequired,
};

EmpresaDetailsPanel.defaultProps = {
  empresa: null,
  cnpjMatriz: null,
};

export default EmpresaDetailsPanel;
