import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18n from 'i18next';

// Hooks
import useForm from 'app/hooks/useForm';
import usePermissions from 'app/hooks/usePermissions';

// HOC
import withForm from 'app/hoc/withForm/withForm';

// External Components
import {
  Form,
  Card,
  Row,
  Col,
  Input,
  Select,
  message,
  Spin,
  Button,
  Switch,
} from 'antd';

// Styles
import './UsuarioDetails.scss';

// Constants
import { EDIT, CREATE } from 'app/constants/FormModeConstants';

// Services
import { get, create, edit } from 'app/services/UsuariosService';

// Components
import SubHeader from 'app/components/Subheader';

function UsuarioDetails() {
  const { form, formMode } = useForm();
  const { permissions } = usePermissions();
  const params = useParams();
  const navigate = useNavigate();

  const [isFetchingUsuarios, setIsFetchingUsuarios] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isThePasswordFilled, setIsThePasswordFilled] = useState(false);

  function getUsuarioModel() {
    return {
      name: '',
      email: '',
      roles: [],
      status: true,
      password: '',
      confirmPassword: '',
    };
  }

  useEffect(() => {
    const fields = getUsuarioModel();

    form.setFieldsValue(fields);
  }, []);

  useEffect(() => {
    const { id } = params;

    if (formMode === EDIT && id) {
      fetchUsuario(id);
    }
  }, [formMode]);

  async function fetchUsuario(id) {
    setIsFetchingUsuarios(true);

    try {
      const { data } = await get(id);
      const usuario = { ...getUsuarioModel(), ...data };
      form.setFieldsValue({ ...usuario, status: usuario.status === "ACTIVE" });
    } catch (error) {
      message.error(i18n.t('Erro ao carregar usuário'));
      navigate('/usuarios');
    } finally {
      setIsFetchingUsuarios(false);
    }
  }

  async function handleSubmit() {
    const { confirmPassword, ...fields } = form.getFieldsValue();

    setIsSaving(true);

    if (fields._id) {
      await handleUpdate(fields);
      return;
    }

    const userCreateFields = {
      ...fields,
      status: fields.status ? "ACTIVE" : "INACTIVE",
      roles: [`${fields.roles}`],
    }

    handleCreate(userCreateFields);
  }

  async function handleCreate(fields) {
    try {
      await create(fields);
      message.success(i18n.t('Usuário cadastrado com sucesso'));
      navigate(-1);
    } catch (err) {
      message.error(i18n.t('Erro ao criar usuário'));
    } finally {
      setIsSaving(false);
    }
  }

  async function handleUpdate(fields) {
    try {
      const updateFields = {
        ...fields,
        status: fields.status ? "ACTIVE" : "INACTIVE",
        roles: [`${fields.roles}`]
      }

      await edit(updateFields);
      message.success(i18n.t('Usuário atualizado com sucesso'));
      navigate(-1);
    } catch (error) {
      message.error(i18n.t('Erro ao atualizar usuário'));
    } finally {
      setIsSaving(false);
    }
  }

  function onChangePassword(event) {
    event.preventDefault();
    event.target.value
      ? setIsThePasswordFilled(true)
      : setIsThePasswordFilled(false);
  }

  function renderActions() {
    return (
      <Button
        className="save-button uppercase"
        type="primary"
        htmlType="submit"
        disabled={isFetchingUsuarios}
      >
        {formMode === CREATE ? i18n.t('Criar') : i18n.t('Atualizar')}
      </Button>
    );
  }

  function validatorPassword({ getFieldValue }) {
    return {
      validator(_, value) {
        if (!value || getFieldValue('password') === value) {
          return Promise.resolve();
        }

        return Promise.reject(
          new Error(i18n.t('As senhas digitadas não são iguais')),
        );
      },
    };
  }

  return (
    <Spin spinning={isSaving || isFetchingUsuarios}>
      <Form
        className="usuario-details"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item name="_id" hidden>
          <Input />
        </Form.Item>

        <SubHeader
          title={i18n.t('Usuário')}
          subtitle={
            formMode === CREATE
              ? i18n.t('Cadastro de Usuário')
              : i18n.t('Edição da Usuário')
          }
          prefix={renderActions()}
          backButtonVisible
        />

        <Card>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="status"
                className="switch-status"
                label={i18n.t('Ativo')}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="name"
                label={i18n.t('Nome')}
                required
                rules={[
                  { required: true, message: i18n.t('Nome é obrigatório') },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="email"
                label={i18n.t('Email')}
                required
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: i18n.t('Email válido é obrigatório'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="roles"
                className="select-input"
                label={i18n.t('Permissão')}
                required
                rules={[
                  {
                    required: true,
                    message: i18n.t('Escolha uma permissão'),
                  },
                ]}
              >
                <Select>
                  {permissions?.map((permissao) => (
                    <Select.Option key={permissao._id} value={permissao.nome}>
                      {permissao.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="password"
                label={
                  formMode === CREATE ? i18n.t('Senha') : i18n.t('Nova senha')
                }
                required={formMode === CREATE}
                hasFeedback
                rules={[
                  {
                    required: formMode === CREATE,
                    message: i18n.t('Senha é obrigatório'),
                  },
                ]}
              >
                <Input.Password onChange={onChangePassword} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="confirmPassword"
                label={
                  formMode === CREATE
                    ? i18n.t('Confirmar senha')
                    : i18n.t('Confirmar nova senha')
                }
                dependencies={['password']}
                required={formMode === CREATE || isThePasswordFilled}
                hasFeedback
                rules={[
                  {
                    required: formMode === CREATE || isThePasswordFilled,
                    message:
                      formMode === CREATE
                        ? i18n.t('Confirmar senha é obrigatório')
                        : i18n.t('Confirmar nova senha é obrigatório'),
                  },
                  validatorPassword,
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Spin>
  );
}

export default withForm(UsuarioDetails);
