import React, { useContext, useEffect, useState } from 'react';
import i18n from 'i18n';

// Styles
import './ImportPlanilhas.scss';

// External components
import { Card, Col, Row, Spin, message, Divider, Alert } from 'antd';

// Enum
import { ImportTypesEnum } from 'app/constants/ImportTypesConstants';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Hooks
import useEmpresa from 'app/hooks/useEmpresa';

// Services
import { getAll, getUploadTypes } from 'app/services/UploadService';

// Components
import { FileTypes } from 'app/components/Uploader/Uploader';
import UploadCard from 'app/components/UploadCard';
import UploadScheduleTable from 'app/components/UploadScheduleTable';
import SubHeader from 'app/components/Subheader';

function ImportPlanilhas() {
  const { empresa } = useEmpresa();

  // States
  const [uploads, setUploads] = useState([]);
  const [uploadTypes, setUploadTypes] = useState({});
  const [fetchingUploads, setFetchingUploads] = useState(false);
  const [fetchingUploadTypes, setFetchingUploadTypes] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(3);
  const [totalItems, setTotalItems] = useState(0);

  const context = useContext(GlobalContext);
  const {
    downloadTemplateState: { loadingDownloadTemplate },
  } = context;

  useEffect(() => {
    // Upload the upload list only once
    if (empresa?.cnpj && !Object.keys(uploadTypes).length) {
      fetchUploadTypes();
    }

    if (empresa?.cnpj && !uploads.length) {
      fetchUploads();
    }
  }, [empresa]);

  async function fetchUploadTypes() {
    setFetchingUploadTypes(true);

    try {
      const { data } = await getUploadTypes({ cnpj: empresa.cnpj });
      setUploadTypes(data);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar os tipos de upload'));
    } finally {
      setFetchingUploadTypes(false);
    }
  }

  async function fetchUploads() {
    setFetchingUploads(true);

    try {
      const { data } = await getAll({
        cnpjMatriz: empresa?.cnpj,
        type: ImportTypesEnum.WORKSHEET,
      });
      setUploads(data?.results);
      setTotalItems(data?.count);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar a lista de uploads'));
    } finally {
      setFetchingUploads(false);
    }
  }

  async function onSuccessSchedule() {
    fetchUploads();
  }

  function renderUploadCard(uploadType) {
    const config = {
      ...uploadTypes[uploadType],
      uploadType, // Update upload cards with new template
      filesAccepted: [FileTypes.xlsx],
      maxFileSizeInMega: 10240,
      additionalData: {
        cnpj: empresa?.cnpj,
        type: uploadType,
      },
      onSuccess: onSuccessSchedule,
    };

    const breakpoints = {
      xl: 8,
      xs: 24,
      sm: 24,
      md: 24,
      lg: 12,
    };

    return (
      <Col key={config.title} {...breakpoints}>
        <UploadCard {...config} />
      </Col>
    );
  }

  return (
    <Card className="import-planilhas">
      <Spin spinning={loadingDownloadTemplate || fetchingUploadTypes}>
        <SubHeader title={i18n.t('Importar planilhas')} />

        <Alert
          message="Antes de importar uma planilha, verifique se ela seguindo o modelo de planilha esperado para cada tipo de importação."
          type="warning"
          showIcon
        />

        <Divider />

        <Row gutter={[20, 20]}>
          {Object.keys(uploadTypes).map(renderUploadCard)}
        </Row>

        <Divider />

        <UploadScheduleTable
          uploads={uploads}
          loading={fetchingUploads}
          refetch={() => fetchUploads()}
          onCancel={() => fetchUploads()}
        />
      </Spin>
    </Card>
  );
}

export default ImportPlanilhas;
