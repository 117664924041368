import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Form, Input } from 'antd';
import InfoIconWithPopover from '../InfoIconWithPopover/InfoIconWithPopover';

function StringFilter({ formGroup, filter }) {
  const { id, label, required, info } = filter;

    // Verifica se customPopoverText possui um valor antes de renderizar o ícone e o Popover
    const renderInfoIconWithPopover = info ? (
      <InfoIconWithPopover content={info} />
    ) : null;

  return (
    <Form.Item
      name={[...formGroup, id]}
      label={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {renderInfoIconWithPopover}
          {label || id}
        </span>
      }
      rules={[{ required, message: i18n.t('Campo obrigatório') }]}
    >
      <Input />
    </Form.Item>
  );
}

StringFilter.propTypes = {
  filter: Proptypes.shape({
    id: Proptypes.string.isRequired,
    type: Proptypes.string.isRequired,
    label: Proptypes.string,
    info: Proptypes.string,
    required: Proptypes.bool,
  }).isRequired,
  formGroup: Proptypes.arrayOf(Proptypes.string),
};

StringFilter.defaultProps = {
  formGroup: [],
};

export default StringFilter;
