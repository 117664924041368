import React, { useState, useEffect } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { Modal } from 'antd';

// Components
import DynamicTable from 'app/components/DynamicTable';
import SelectAlertaProcesso from 'app/components/Select/SelectAlertaProcesso';

// Styles
import './ModalAlertasProcesso.scss';

function ModalAlertasProcesso({ alertas, title, isVisible, toggleVisible }) {
  const [alertasSelected, setAlertasSelected] = useState([]);
  const [alertaTypes, setAlertaTypes] = useState({});

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    const alerts = {};

    alertas.forEach((a) => {
      if (!alerts[a.type]) {
        alerts[a.type] = [];
      }

      alerts[a.type].push(a);
    });
    setAlertaTypes(alerts);
  }, [alertas, isVisible]);

  function closeModal() {
    setAlertaTypes({});
    setAlertasSelected([]);
    toggleVisible(false);
  }

  function onSelectAlert(type) {
    setAlertasSelected(alertaTypes[type]);
  }

  return (
    <Modal
      className="modal-alertas-processo"
      title={title}
      visible={isVisible}
      width={1000}
      footer={null}
      onCancel={closeModal}
    >
      {Object.keys(alertaTypes).length && (
        <SelectAlertaProcesso
          items={Object.keys(alertaTypes)}
          onSelect={onSelectAlert}
        />
      )}

      <DynamicTable
        dataSource={alertasSelected}
        scroll={{
          x: 1000,
        }}
      />
    </Modal>
  );
}

ModalAlertasProcesso.propTypes = {
  title: Proptypes.string,
  alertas: Proptypes.arrayOf(
    Proptypes.shape({
      type: Proptypes.string,
      msg: Proptypes.string,
    }),
  ),
  isVisible: Proptypes.bool.isRequired,
  toggleVisible: Proptypes.func.isRequired,
};

ModalAlertasProcesso.defaultProps = {
  title: i18n.t('Detalhes de alertas do processo'),
  alertas: [],
};

export default ModalAlertasProcesso;
