import React, { createContext, useState } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External components
import { message } from 'antd';

// Services
import { get, create, edit } from 'app/services/EmpresasService';

export const EmpresaContext = createContext();

function EmpresaProvider({ children }) {
  const [empresa, setEmpresa] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);

  async function fetchEmpresa(id) {
    setFetching(true);

    try {
      const { data } = await get(id);
      setEmpresa(data);
      setFetching(false);
    } catch (error) {
      message.error(i18n.t('Erro ao carregar empresa'));
      //   history.goBack('/empresas');
    }
  }

  async function requestCreate(fields) {
    setSaving(true);

    try {
      await create(fields);
      message.success(i18n.t('Empresa criada com sucesso.'));
    } catch (error) {
      message.error(
        error?.response?.data?.message || i18n.t('Erro ao criar empresa.'),
      );
    } finally {
      setSaving(false);
    }
  }

  async function requestEdit(fields) {
    setSaving(true);

    try {
      await edit(fields);
      message.success(i18n.t('Empresa atualizada com sucesso.'));
    } catch (error) {
      message.error(
        error?.response?.data?.message || i18n.t('Erro ao atualizar empresa.'),
      );
    } finally {
      setSaving(false);
    }
  }

  return (
    <EmpresaContext.Provider
      value={{
        empresa,
        setEmpresa,
        fetching,
        setFetching,
        saving,
        setSaving,
        fetchEmpresa,
        requestCreate,
        requestEdit,
      }}
    >
      {children}
    </EmpresaContext.Provider>
  );
}

export default EmpresaProvider;

EmpresaProvider.propTypes = {
  children: Proptypes.element.isRequired,
};
