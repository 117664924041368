import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { Form, Switch } from 'antd';
import InfoIconWithPopover from '../InfoIconWithPopover/InfoIconWithPopover';

function SwitchFilter({ formGroup, filter }) {
  const { id, label, required, info } = filter;

  // Verifica se customPopoverText possui um valor antes de renderizar o ícone e o Popover
  const renderInfoIconWithPopover = info ? (
    <InfoIconWithPopover content={info} />
  ) : null;

  return (
    <Form.Item
      name={[...formGroup, id]}
      label={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {renderInfoIconWithPopover}
          {label || id}
        </span>
      }
      valuePropName="checked"
      initialValue={filter.default}
      rules={[{ required, message: i18n.t('Campo obrigatório') }]}
    >
      <Switch />
    </Form.Item>
  );
}

SwitchFilter.propTypes = {
  filter: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    info: PropTypes.string,
    required: PropTypes.bool,
    default: PropTypes.bool
  }).isRequired,
  formGroup: PropTypes.arrayOf(PropTypes.string),
};

SwitchFilter.defaultProps = {
  formGroup: [],
};

export default SwitchFilter;
