import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// External components
import { Form } from 'antd';

// Constants
import { CREATE, EDIT } from 'app/constants/FormModeConstants';

// Context
import FormContext from 'app/contexts/FormContext';

/**
 * HOC `withForm`
 *
 * This HOC proposes to render component with default form functions.
 */
const withForm = (Component) => ({ ...rest }) => {
  const location = useLocation();

  // States
  const [form] = Form.useForm();
  const [formMode, setFormMode] = useState(CREATE);

  useEffect(() => {
    // Check the form mode based on pathname
    const formModeRegExp = new RegExp(`.*(${CREATE}|${EDIT}).*`);
    const currentFormMode = location.pathname.replace(formModeRegExp, '$1');

    setFormMode(currentFormMode);
  }, [location]);

  return (
    <FormContext.Provider value={{ form, formMode }}>
      <Component {...rest} />
    </FormContext.Provider>
  );
};

export default withForm;
