import Api from 'app/services/Api';

const resource = 'users';

export function getAll({ params }) {
  return Api.get(resource, { params });
}

export function get(id) {
  return Api.get(`${resource}/${id}`);
}

export function create(payload) {
  return Api.post(resource, payload);
}

export function edit({ _id, ...body }) {
  return Api.put(`${resource}/${_id}`, body);
}

export function remove(id) {
  return Api.delete(`${resource}/${id}`);
}
