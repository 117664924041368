import React from 'react';

// Icons
import { AiOutlineFilter } from 'react-icons/ai';

// External components
import { Col, Row, Switch } from 'antd';

const filterIcon = () => <AiOutlineFilter size={18} />;

const columnFilterToggleProps = (dataIndex, filters) => ({
  filterIcon,
  filterDropdown() {
    // eslint-disable-next-line react/prop-types
    // const { setSelectedKeys, selectedKeys, confirm, clearFilters } = props;

    return (
      <div className="column-filter-toggle-dropdown column-filter-dropdown">
        <Row gutter={16} className="column-filter-dropdown__content">
          {filters.map((filter) => (
            <Col span={24} key={`filter-${filter.value}`}>
              <span>{filter.text}</span> <Switch />
            </Col>
          ))}
        </Row>
      </div>
    );
  },
});

export default columnFilterToggleProps;
