export const ImportTypesEnum = {
  WORKSHEET: 'WORKSHEET',
  REG_FISCAL: 'REG_FISCAL',
  REG_FISCAL_REPROCESS: 'REG_FISCAL_REPROCESS',
  SPED: 'SPED',
  SPED_REPROCESS: 'SPED_REPROCESS',
  APURACAO: 'APURACAO',
  ARQUIVO_DIGITAL: 'ARQUIVO_DIGITAL',
};

export const ImportStatusEnum = {
  PENDENTE: 'PENDENTE',
  PROCESSANDO: 'PROCESSANDO',
};
