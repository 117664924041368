import Api from './Api';

const resource = 'produtos';

/**
 * @param {Object} params
 * @param {string} params.value Search by cProd or description
 * @param {string[]} params.cnpjs
 * @param {string} params.cnpjMatriz
 * @return {Promise<any>}
 */
export function getProdutos(params) {
  const { value, cnpjs, cnpjMatriz, skip, limit, startPeriod, endPeriod } =
    params;
  return Api.get(`empresas/${cnpjMatriz}/${resource}`, {
    params: { cnpjs, query: value, skip, limit, startPeriod, endPeriod },
  });
}

export function updateProduto({ _id, cnpjMatriz, ...updatedValues }) {
  return Api.put(`empresas/${cnpjMatriz}/${resource}/${_id}`, {
    ...updatedValues,
  });
}

export function removeProduto({ _id, cnpjMatriz }) {
  return Api.delete(`empresas/${cnpjMatriz}/${resource}/${_id}`);
}
