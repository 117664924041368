import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Icons
import { FaGoogleDrive } from 'react-icons/fa';

// External components
import { Tooltip } from 'antd';

// Styles
import './OpenGoogleDrive.scss';

function OpenGoogleDrive({ googleDriveId, googleDriveUrl, tooltipTitle }) {
  return (
    <div className="open-google-drive">
      <Tooltip title={tooltipTitle}>
        <a
          href={`${googleDriveUrl}/${googleDriveId}`}
          target="_blank"
          rel="noreferrer"
        >
          <FaGoogleDrive size={16} />
        </a>
      </Tooltip>
    </div>
  );
}

OpenGoogleDrive.propTypes = {
  googleDriveId: Proptypes.string.isRequired,
  googleDriveUrl: Proptypes.string,
  tooltipTitle: Proptypes.string,
};

OpenGoogleDrive.defaultProps = {
  tooltipTitle: i18n.t('Abrir pasta no Google Drive'),
  googleDriveUrl: process.env.REACT_APP_GOOGLE_DRIVE_URL,
};

export default OpenGoogleDrive;
