import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Proptypes from 'prop-types';
import i18n from 'i18n';
import { cnpjFormat } from 'app/utils/masks';

// External components
import { message, Select } from 'antd';

// Services
import { getEmpresasRede } from 'app/services/EmpresasService';

// Styles
import './SelectEmpresa.scss';

const { Option } = Select;

function SelectEmpresa({
  value,
  getCnpjQueryParams,
  selectAll,
  allowSelectAll,
  cnpjMatriz,
  maxTagCount,
  placeholder,
  disabled,
  onChange,
  onFetch,
  ...props
}) {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);

  const className = 'select-empresa';

  useEffect(() => {
    fetchEmpresas();
  }, [cnpjMatriz]);

  useEffect(() => {
    // Set value based initial value
    if (selectAll && empresas.length) {
      // Set select default based of query params of the url
      const cnpjs = searchParams.getAll('cnpjs');

      cnpjs.length && getCnpjQueryParams
        ? setInitialSelect(cnpjs)
        : onSelect('all');

      onFetch();
    }
  }, [empresas]);

  function fetchEmpresas() {
    setLoading(true);

    return getEmpresasRede({ params: { cnpjMatriz, includeMatriz: true } })
      .then((response) => {
        const empresasList = [];

        response?.forEach((empresa) => {
          empresasList.push(empresa);
        });

        setEmpresas(empresasList);
      })
      .catch(() => {
        message.error(i18n.t('Erro ao carregar lista de empresas'));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function setInitialSelect(cnpjs) {
    let cnpjsByQuery;

    if (cnpjs) {
      typeof cnpjs === 'string'
        ? (cnpjsByQuery = [cnpjs])
        : (cnpjsByQuery = [...cnpjs]);

      onChange(cnpjsByQuery);
    }
  }

  function onSelect(option) {
    if (option === 'all') {
      handleSelectAll();
      return;
    }

    onChange([...value, option]);
  }

  function handleSelectAll() {
    // If all are selected we must deselect
    if (value?.length === empresas.length) {
      onChange([]);
      return;
    }

    onChange(empresas.map((empresa) => empresa.cnpj));
  }

  function onDeselect(option) {
    // Keep selected only the clicked option
    if (value?.length === empresas.length) {
      onChange([option]);
    }

    onChange(value?.filter((item) => item !== option));
  }

  function renderSelectAll() {
    const isAllSelected = value?.length === empresas.length;
    const label = isAllSelected
      ? i18n.t('Remover seleção')
      : i18n.t('Selecionar todos');

    return (
      <Option key="all" value="all">
        {label}
      </Option>
    );
  }

  return (
    <Select
      {...props}
      mode="multiple"
      className={className}
      value={value}
      loading={loading}
      placeholder={placeholder}
      maxTagCount={maxTagCount}
      dropdownMatchSelectWidth={false}
      disabled={disabled}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      {allowSelectAll && renderSelectAll()}

      {empresas.map((empresa) => (
        <Option key={empresa.cnpj} value={empresa.cnpj}>
          {cnpjFormat(empresa.cnpj)}
        </Option>
      ))}
    </Select>
  );
}

SelectEmpresa.propTypes = {
  value: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.arrayOf(Proptypes.string),
  ]),
  getCnpjQueryParams: Proptypes.bool,
  selectAll: Proptypes.bool,
  allowSelectAll: Proptypes.bool,
  cnpjMatriz: Proptypes.string,
  maxTagCount: Proptypes.number,
  placeholder: Proptypes.string,
  disabled: Proptypes.bool,
  onChange: Proptypes.func,
  onFetch: Proptypes.func,
};

SelectEmpresa.defaultProps = {
  value: [],
  getCnpjQueryParams: true,
  selectAll: false,
  allowSelectAll: false,
  cnpjMatriz: null,
  maxTagCount: 1,
  disabled: false,
  placeholder: i18n.t('Selecione o CNPJ'),
  onChange: () => null,
  onFetch: () => null,
};

export default SelectEmpresa;
