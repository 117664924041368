import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18next';

// External components
import { Table as TableAntd } from 'antd';

// Components
import columnFilterTextProps from './ColumnFilterText';
import columnFilterToggleProps from './ColumnFilterToggle';

// Table styles
import './Table.scss';

function Table({
  dataSource,
  columns,
  rowKey,
  className,
  isLoading,
  scroll,
  pagination,
  ...props
}) {
  const customColumns = () =>
    columns?.map((column) => {
      if (column.filter) {
        return {
          ...column,
          ...columnFilterTextProps(column.dataIndex),
        };
      }

      if (column.toggleFilters?.length) {
        return {
          ...column,
          ...columnFilterToggleProps(column.dataIndex, column.toggleFilters),
        };
      }

      return column;
    });

  const componentClasses = ['table', className || ''].join(' ');

  return (
    <TableAntd
      className={componentClasses}
      bordered
      dataSource={dataSource}
      columns={customColumns()}
      rowKey={rowKey}
      scroll={scroll}
      loading={isLoading}
      pagination={pagination}
      locale={{
        emptyText: i18n.t('Nenhum dado cadastrado'),
      }}
      {...props}
    />
  );
}

Table.propTypes = {
  dataSource: Proptypes.oneOfType([Proptypes.array, Proptypes.func]).isRequired,
  columns: Proptypes.oneOfType([Proptypes.array, Proptypes.func]).isRequired,
  rowKey: Proptypes.oneOfType([Proptypes.string, Proptypes.func]),
  className: Proptypes.string,
  isLoading: Proptypes.bool,
  scroll: Proptypes.shape({}),
  pagination: Proptypes.shape({
    position: Proptypes.arrayOf(Proptypes.string),
  }),
};

Table.defaultProps = {
  rowKey: 'uid',
  className: '',
  isLoading: false,
  scroll: {
    x: 1300,
  },
  pagination: {
    position: ['bottomCenter'],
  },
};

export default Table;
