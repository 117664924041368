import React, { useContext } from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// External components
import { Radio, Space } from 'antd';

// Styles
import './ViewBy.scss';

function ViewBy({ title, selected, list, onChange, ...props }) {
  const context = useContext(GlobalContext);

  // Contexts
  const {
    groupsSelectedState: { setGroupsSelected: setGroupsSelectedContext },
  } = context;

  function handleOnChange(event) {
    setGroupsSelectedContext([]);
    onChange(event.target.value);
  }

  function renderViewOption(view) {
    const { key, label } = view;

    return (
      <Radio.Button key={key} value={key}>
        {label}
      </Radio.Button>
    );
  }

  return (
    <div className="view-by">
      <Space size={12}>
        <span>{title}</span>

        <Radio.Group value={selected} onChange={handleOnChange} {...props}>
          {list.map(renderViewOption)}
        </Radio.Group>
      </Space>
    </div>
  );
}

ViewBy.propTypes = {
  title: Proptypes.oneOfType([Proptypes.element, Proptypes.string]),
  list: Proptypes.arrayOf(Proptypes.shape({})),
  selected: Proptypes.string.isRequired,
  onChange: Proptypes.func.isRequired,
};

ViewBy.defaultProps = {
  title: i18n.t('Visualizar por'),
  list: [],
};

export default ViewBy;
