import React, { useContext } from 'react';
import i18n from 'i18next';
import Proptypes from 'prop-types';

// Icons
import { BiDownload, BiShow, BiExport } from 'react-icons/bi';
import { FiDownloadCloud } from 'react-icons/fi';

// External components
import { Button, Col, message, Row, Space, Tooltip } from 'antd';
import Title from 'antd/lib/typography/Title';

// Utils
import { cnpjFormat, moneyFormat } from 'app/utils/masks';
import { formatPeriodo } from 'app/utils/date';

// Context
import { GlobalContext } from 'app/contexts/GlobalContext/GlobalContext';

// Services
import { downloadFicha3 } from 'app/services/ApuracaoService';

// Enumerators
import { ApuracaoStatus } from 'app/constants/Enumerators';

import useEmpresa from 'app/hooks/useEmpresa';

// Components
import Table from 'app/components/Table';
import TagFilter from 'app/components/TagFilter';
import LinkButton from '../LinkButton';

// Styles
import './ApuracaoPorProduto.scss';

function ApuracaoPorProduto({
  dataSource,
  fetching,
  pagination,
  onSelectApuracao,
  onChangeGroupFilter,
  onChangeTable,
  onChangeDownload,
  onDownloadFicha3Unificadas
}) {
  const context = useContext(GlobalContext);

  const { empresa } = useEmpresa();
  const { cnpjMatriz } = empresa;

  // Contexts
  const {
    groupsSelectedState: { groupsSelected },
  } = context;

  const tags = [
    {
      label: i18n.t('CNPJ'),
      value: 'cnpj',
    },
    {
      label: i18n.t('Periodo'),
      value: 'periodo',
    },
    // {
    //   label: i18n.t('Produto'),
    //   value: 'produto',
    // },
  ];

  const columns = [
    {
      title: i18n.t('CNPJ'),
      dataIndex: 'cnpj',
      key: 'cnpj',
      width: 140,
      render: renderCNPJ,
      visible: showColumn('cnpj'),
      sorter: true,
    },
    {
      title: i18n.t('Período'),
      dataIndex: 'periodo',
      key: 'periodo',
      width: 120,
      render: renderPeriodo,
      visible: showColumn('periodo'),
      sorter: true,
    },
    {
      title: i18n.t('cProd'),
      dataIndex: 'produto',
      key: 'cProd',
      width: 110,
      render: (_, row) => row.produto?.cProd,
      visible: showColumn('produto'),
      sorter: true,
    },
    {
      title: i18n.t('Produto'),
      dataIndex: 'produto.desc',
      key: 'descProd',
      width: 200,
      render: (_, row) => row.produto?.desc,
      visible: showColumn('produto'),
      sorter: true,
    },
    {
      title: i18n.t('Ressarcimento'),
      dataIndex: 'ressarcimento',
      key: 'ressarcimento',
      width: 120,
      render: renderMoney,
      visible: showColumn('ressarcimento'),
      sorter: true,
    },
    {
      title: i18n.t('Complemento'),
      dataIndex: 'complemento',
      key: 'complemento',
      width: 120,
      render: renderMoney,
      visible: showColumn('complemento'),
      sorter: true,
    },
    {
      title: i18n.t('Total'),
      dataIndex: 'apuracao',
      key: 'total',
      width: 120,
      render: renderTotal,
      visible: showColumn('apuracao'),
      sorter: true,
    },
    {
      title: i18n.t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 60,
      render: renderStatus,
      visible: showColumn('status'),
    },
    {
      title: i18n.t('Ações'),
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 60,
      render: (data, row) => (
        <Space className="actions-ficha3" size={4} align="center">
          <Button
            className="view-ficha3-button"
            type="link"
            size="small"
            onClick={() => openFicha3(row)}
          >
            <Tooltip title={i18n.t('Visualizar ficha 3')}>
              <Space size={12}>
                <BiShow size={18} />
              </Space>
            </Tooltip>
          </Button>

          <Button
            className="download-ficha-3-button"
            type="link"
            size="small"
            onClick={() => requestDownloadFicha3(row)}
          >
            <Tooltip title={i18n.t('Baixar ficha 3')}>
              <BiDownload size={18} />
            </Tooltip>
          </Button>
        </Space>
      ),
      visible: groupsSelected.length === 2,
    },
  ];

  async function requestDownloadFicha3(apuracao) {
    const { cnpj, produto, periodo } = apuracao;
    const { cProd } = produto;

    try {
      await downloadFicha3({ cnpj, cProd, periodo, cnpjMatriz });
    } catch (error) {
      message.error(i18n.t('Erro ao baixar Ficha 3'));
    }
  }

  function renderDownloadButton() {
    return (
      <Tooltip title='Baixar fichas3 unificadas'>
        <Button
          className='btn-download'
          disabled={!(groupsSelected.length === 2)}
          onClick={onDownloadFicha3Unificadas}
        >
          <Space size={14}>
            <FiDownloadCloud size={14} />
          </Space>
        </Button>
      </Tooltip >
    )
  }

  function renderDownloadResultExport() {
    return (
      <Tooltip title={i18n.t('Exportar resultados')}>
        <Button
          className="btn-result-export uppercase"
          onClick={onChangeResultExport}
        >
          <Space size={14}>
            <BiExport size={16} />
          </Space>
        </Button>
      </Tooltip>
    );
  }

  function renderMoney(num = 0) {
    return moneyFormat('R$')(num?.toFixed(2));
  }

  function renderTotal(data, row) {
    const { ressarcimento = 0, complemento = 0 } = row || {};
    const total = ressarcimento - complemento;

    return (
      <span className={`total ${total > 0 ? 'positive' : 'negative'}`}>
        <b>{renderMoney(total)}</b>
      </span>
    );
  }

  function renderCNPJ(cnpj, row) {
    return (
      <Button
        type="link"
        className="link-text-button"
        onClick={() => onSelectApuracao(row)}
      >
        {cnpjFormat(cnpj)}
      </Button>
    );
  }

  function renderPeriodo(periodo, row) {
    if (!periodo) {
      return null;
    }

    return (
      <Button
        type="link"
        className="link-text-button"
        onClick={() => onSelectApuracao(row)}
      >
        <span className=" first-letter-capitalize">
          {formatPeriodo(periodo)}
        </span>
      </Button>
    );
  }

  function renderStatus(status) {
    const hasAlertas = status === ApuracaoStatus.COM_ALERTAS;

    return hasAlertas ? (
      <LinkButton
        messageTooltip={i18n.t(
          'Este produto contém erro na ficha3 e não está sendo considerado na apuração',
        )}
        danger
      />
    ) : null;
  }

  function openFicha3(row) {
    const { cnpj, produto, periodo } = row;
    const { cProd } = produto;

    const title = [
      i18n.t('Ficha 3'),
      `CNPJ: ${cnpjFormat(cnpj)}`,
      `cProd: ${cProd}`,
      `período: ${periodo}`,
    ].join(' | ');

    const windowHeight = document.documentElement.clientHeight;
    const windowWidth = document.documentElement.clientWidth;

    window.open(
      `/ficha3/${cnpj}/${cProd}/${periodo}?cnpjMatriz=${cnpjMatriz}`,
      title,
      `toolbar=0,status=0,width=${windowWidth},height=${windowHeight}`,
    );
  }

  function onChangeResultExport() {
    onChangeDownload(groupsSelected);
  }

  function renderGroupFilter() {
    return (
      <TagFilter
        tags={tags}
        onChange={onChangeGroupFilter}
        title={i18n.t('Agrupar Por')}
      />
    );
  }

  function showColumn(column) {
    if (dataSource?.length) {
      return Object.keys(dataSource[0]).includes(column);
    }

    return false;
  }

  function getColumns() {
    return columns.filter((column) => column.visible);
  }

  return (
    <div className="apuracao-por-produto">
      <Row>
        <Col flex="auto">
          <div className="header-table-produto">
            <Title level={4}>{i18n.t('Detalhes')}</Title>
            <div className="container-result-export">
              {renderDownloadButton()}
              {renderDownloadResultExport()}
              {renderGroupFilter()}
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col flex="auto">
          <Table
            rowKey={(row) => {
              const id = ['apuracao', row.cnpj, row.periodo, row.produto?.cProd]
                .filter((value) => value)
                .join('-');

              return id;
            }}
            dataSource={dataSource}
            columns={getColumns()}
            loading={fetching}
            pagination={pagination}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </div>
  );
}

ApuracaoPorProduto.propTypes = {
  dataSource: Proptypes.arrayOf(Proptypes.shape({})),
  fetching: Proptypes.bool,
  pagination: Proptypes.shape({
    current: Proptypes.number,
    pageSize: Proptypes.number,
    total: Proptypes.number,
    showTotal: Proptypes.oneOfType([Proptypes.element, Proptypes.func]),
  }),
  onSelectApuracao: Proptypes.func,
  onChangeGroupFilter: Proptypes.func,
  onChangeTable: Proptypes.func,
  onChangeDownload: Proptypes.func,
  onDownloadFicha3Unificadas: Proptypes.func,
};

ApuracaoPorProduto.defaultProps = {
  dataSource: [],
  fetching: false,
  pagination: { current: 0, pageSize: 10, total: 0, showTotal: null },
  onSelectApuracao: () => null,
  onChangeGroupFilter: () => null,
  onChangeTable: () => null,
  onChangeDownload: () => null,
  onDownloadFicha3Unificadas: () => null
};

export default ApuracaoPorProduto;
