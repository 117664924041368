import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Context
import GlobalProvider from 'app/contexts/GlobalContext';

// Features
import Layout from 'app/components/Layout';
import Login from 'app/features/Login';

import Usuarios from 'app/features/Usuarios';
import UsuarioDetails from 'app/features/UsuarioDetails';

import Documentacao from 'app/features/Documentacao';
import Empresas from 'app/features/Empresas';
import Processos from 'app/features/Processos';

import Detalhes from 'app/features/Empresa/EmpresaDetalhes';

import ImportPlanilhas from 'app/features//Empresa/ImportPlanilhas';
import ImportSpeds from 'app/features/Empresa/ImportSpeds';
import ImportRegistroFiscal from 'app/features//Empresa/ImportRegistroFiscal';
import ImportArquivoDigital from 'app/features/Empresa/ImportArquivosDigitais';
import Sped from 'app/features//Empresa/EmpresaSped';

import RegistroFiscal from 'app/features/Empresa/EmpresaRegistroFiscal';
import Produtos from 'app/features/Empresa/EmpresaProdutos';
import Estoque from 'app/features/Empresa/EmpresaEstoque';
import ArqDig1050 from 'app/features/Empresa/EmpresaArqDig1050';
import Relatorios from 'app/features/Empresa/EmpresaReports';
import AgendarApuracao from 'app/features/Empresa/EmpresaAgendarApuracao';
import Resultados from 'app/features/Empresa/EmpresaResultados';

import Ficha3 from 'app/features/Ficha3';

// Routes
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import EmpresaRoute from './EmpresaRoute';

function AppRoutes() {
  return (
    <GlobalProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" exact element={<Login />} />

          <Route path="/" element={<PrivateRoute />}>
            <Route path="/ficha3/:cnpj/:cProd/:periodo" element={<Ficha3 />} />

            <Route path="/" element={<Layout />}>
              <Route index element={<Empresas />} />
              <Route path="documentacao/:type" element={<Documentacao />} />
              <Route path="processos" element={<Processos />} />

              <Route path="/empresa/:id" element={<EmpresaRoute />}>
                <Route index element={<Detalhes />} />
                <Route
                  path="importacoes/planilhas"
                  element={<ImportPlanilhas />}
                />
                <Route path="importacoes/sped" element={<ImportSpeds />} />
                <Route
                  path="importacoes/registros-fiscais"
                  element={<ImportRegistroFiscal />}
                />
                <Route
                  path="importacoes/arquivo-digital"
                  element={<ImportArquivoDigital />}
                />
                <Route path="sped" element={<Sped />} />
                <Route path="registro-fiscal" element={<RegistroFiscal />} />
                <Route path="produtos" element={<Produtos />} />
                <Route path="estoque" element={<Estoque />} />
                <Route path="arquivo-digital/registro-1050" element={<ArqDig1050 />} />
                <Route path="relatorios" element={<Relatorios />} />
                <Route path="agendar-apuracao" element={<AgendarApuracao />} />
                <Route path="resultados" element={<Resultados />} />
              </Route>

              <Route path="/usuarios" element={<AdminRoute />}>
                <Route index element={<Usuarios />} />
                <Route path="criar" element={<UsuarioDetails />} />
                <Route path="editar/:id" element={<UsuarioDetails />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </GlobalProvider>
  );
}

export default AppRoutes;
