import moment from 'moment';

export function getMonth(date) {
  return moment(date).format('MM');
}

export function getYear(date) {
  return moment(date).format('YYYY');
}

export function formatProcessoRangeDate(date) {
  return [getMonth(date), getYear(date)].join('');
}

export function formatPeriodo(date) {
  return moment(date, 'MMYYYY').format('MMMM [de] YYYY');
}

export function formatDate(date) {
  return moment(date).format('L');
}

export function formatFullDate(date) {
  return moment(date).format('LLL');
}
