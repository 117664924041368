import React from 'react';
import i18n from 'i18next';

// Icons
import { AiOutlineFileText, AiOutlineShoppingCart } from 'react-icons/ai';
import { BiBuilding } from 'react-icons/bi';
import {
  BsBuilding,
  BsFileBarGraph,
  BsFileEarmarkArrowUp,
} from 'react-icons/bs';
import { FiUsers, FiUploadCloud } from 'react-icons/fi';
import { VscFileCode, VscServerProcess, VscBook } from 'react-icons/vsc';
import { GrSchedules, GrDocumentPerformance } from 'react-icons/gr';
import { MdOutlineInventory } from 'react-icons/md';

export const routes = [
  {
    key: 'empresas',
    path: '/',
  },
  {
    key: 'usuarios',
    path: '/usuarios',
  },
  {
    key: 'doc-regras-gerais',
    path: '/documentacao/regras-gerais',
  },
  {
    key: 'doc-fluxo-sistema',
    path: '/documentacao/fluxo-sistema',
  },
  {
    key: 'doc-importacoes',
    path: '/documentacao/importacoes',
  },
  {
    key: 'doc-relatorios',
    path: '/documentacao/relatorios',
  },
  {
    key: 'doc-apuracao',
    path: '/documentacao/apuracao',
  },
  {
    key: 'processos',
    path: '/processos',
  },
  {
    key: 'empresa-details',
    path: '/empresa/:id',
    context: 'empresa',
  },
  {
    key: 'empresa-importacoes-planilhas',
    path: '/empresa/:id/importacoes/planilhas',
    context: 'empresa',
  },
  {
    key: 'empresa-importacoes-sped',
    path: '/empresa/:id/importacoes/sped',
    context: 'empresa',
  },
  {
    key: 'empresa-importacoes-registros-fiscais',
    path: '/empresa/:id/importacoes/registros-fiscais',
    context: 'empresa',
  },
  {
    key: 'empresa-importacoes-arquivo-digital',
    path: '/empresa/:id/importacoes/arquivo-digital',
    context: 'empresa',
  },
  {
    key: 'empresa-sped',
    path: '/empresa/:id/sped',
    context: 'empresa',
  },
  {
    key: 'empresa-registro-fiscal',
    path: '/empresa/:id/registro-fiscal',
    context: 'empresa',
  },
  {
    key: 'empresa-produtos',
    path: '/empresa/:id/produtos',
    context: 'empresa',
  },
  {
    key: 'empresa-estoque',
    path: '/empresa/:id/estoque',
    context: 'empresa',
  },
  {
    key: 'empresa-arquivo-digital-1050',
    path: '/empresa/:id/arquivo-digital/registro-1050',
    context: 'empresa',
  },
  {
    key: 'empresa-relatorios',
    path: '/empresa/:id/relatorios',
    context: 'empresa',
  },
  {
    key: 'empresa-agendar-apuracao',
    path: '/empresa/:id/agendar-apuracao',
    context: 'empresa',
  },
  {
    key: 'empresa-resultados',
    path: '/empresa/:id/resultados',
    context: 'empresa',
  },
];

export const rootMenu = [
  {
    // key: 'documentacao',
    label: i18n.t('Documentação'),
    icon: <VscBook size={16} />,
    children: [
      {
        key: 'doc-fluxo-sistema',
        label: i18n.t('Fluxo do sistema'),
        icon: <VscBook size={16} />,
      },
      {
        key: 'doc-importacoes',
        label: i18n.t('Importações'),
        icon: <VscBook size={16} />,
      },
      {
        key: 'doc-relatorios',
        label: i18n.t('Relatórios'),
        icon: <VscBook size={16} />,
      },
      {
        key: 'doc-apuracao',
        label: i18n.t('Apuração'),
        icon: <VscBook size={16} />,
      },
      {
        key: 'doc-regras-gerais',
        label: i18n.t('Regras gerais'),
        icon: <VscBook size={16} />,
      },
    ],
  },
  {
    key: 'processos',
    label: i18n.t('Processos'),
    icon: <VscServerProcess size={16} />,
  },
  {
    key: 'empresas',
    label: i18n.t('Empresas'),
    icon: <BsBuilding size={16} />,
  },
];

export const adminMenu = [
  {
    key: 'usuarios',
    label: i18n.t('Usuários'),
    icon: <FiUsers size={16} />,
  },
];

export const empresaMenu = [
  {
    key: 'empresa-details',
    label: i18n.t('Detalhes'),
    icon: <BiBuilding size={16} />,
  },
  {
    key: 'empresa-importacoes',
    label: i18n.t('Importações'),
    icon: <FiUploadCloud size={16} />,
    children: [
      {
        key: 'empresa-importacoes-planilhas',
        label: i18n.t('Planilhas'),
        icon: <BsFileEarmarkArrowUp size={16} />,
      },
      {
        key: 'empresa-importacoes-sped',
        label: i18n.t('SPEDs'),
        icon: <BsFileEarmarkArrowUp size={16} />,
      },
      {
        key: 'empresa-importacoes-registros-fiscais',
        label: i18n.t('Registros fiscais'),
        icon: <BsFileEarmarkArrowUp size={16} />,
      },
      {
        key: 'empresa-importacoes-arquivo-digital',
        label: i18n.t('Arquivos digitais'),
        icon: <BsFileEarmarkArrowUp size={16} />,
      },
    ],
  },
  {
    key: 'empresa-sped',
    label: i18n.t('SPED'),
    icon: <AiOutlineFileText size={16} />,
  },
  {
    key: 'empresa-registro-fiscal',
    label: i18n.t('Registro fiscal'),
    icon: <VscFileCode size={16} />,
  },
  {
    key: 'empresa-produtos',
    label: i18n.t('Produtos'),
    icon: <AiOutlineShoppingCart size={16} />,
  },
  {
    key: 'empresa-estoque',
    label: i18n.t('Estoque importado'),
    icon: <MdOutlineInventory size={16} />,
  },
  {
    key: 'empresa-arquivos-digitais',
    label: i18n.t('Arq. Digitais importados'),
    icon: <AiOutlineFileText size={16} />,
    children: [
      {
        key: 'empresa-arquivo-digital-1050',
        label: i18n.t('Registro 1050'),
        icon: <AiOutlineFileText size={16} />,
      },
    ],
  },
  {
    key: 'empresa-relatorios',
    label: i18n.t('Relatórios'),
    icon: <BsFileBarGraph size={16} />,
  },
  {
    key: 'empresa-agendar-apuracao',
    label: i18n.t('Agendar apuração'),
    icon: <GrSchedules size={16} />,
  },
  {
    key: 'empresa-resultados',
    label: i18n.t('Resultados'),
    icon: <GrDocumentPerformance size={16} />,
  },
];
