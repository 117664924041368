import React from 'react';
import Proptypes from 'prop-types';
import i18n from 'i18n';

// External component
import { Button, Space } from 'antd';

// Styles
import './TabDetalhesActions.scss';

// Components
import UploadFiliais from 'app/components/UploadFiliais';
import MenuActions from 'app/components/MenuActions';

function TabDetalhesActions({
  empresa,
  fetching,
  saving,
  onSuccessFiliaisImport,
  onClickCreateFilial,
}) {
  function renderMoreActions() {
    const isLoading = saving || fetching;

    const menuActions = [
      {
        key: 'import-filiais',
        className: 'import-filiais-button',
        component: (
          <UploadFiliais
            key="upload-filiais"
            cnpjMatriz={empresa.cnpj}
            disabled={isLoading}
            onSuccess={onSuccessFiliaisImport}
          />
        ),
      },
      {
        key: 'create-filiais',
        className: 'create-filiais-button',
        label: <span className="uppercase">{i18n.t('Adicionar filiais')}</span>,
        disabled: isLoading,
        onClick: onClickCreateFilial,
      },
    ];

    return <MenuActions actions={menuActions} disabled={saving || fetching} />;
  }

  const label = empresa._id ? i18n.t('Atualizar') : i18n.t('Criar');

  return (
    <Space size={16} className="tab-detalhes-actions">
      {empresa._id && renderMoreActions()}

      <Button
        className="save-button uppercase"
        type="primary"
        htmlType="submit"
        loading={saving}
        disabled={fetching}
      >
        {label}
      </Button>
    </Space>
  );
}

TabDetalhesActions.propTypes = {
  empresa: Proptypes.shape({
    _id: Proptypes.string,
    cnpj: Proptypes.string,
  }).isRequired,
  fetching: Proptypes.bool.isRequired,
  saving: Proptypes.bool.isRequired,
  onSuccessFiliaisImport: Proptypes.func.isRequired,
  onClickCreateFilial: Proptypes.func.isRequired,
};

TabDetalhesActions.defaultProps = {};

export default TabDetalhesActions;
