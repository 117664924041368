import React from 'react';
import Proptypes from 'prop-types';

// External Components
import { Tag } from 'antd';

// Enums
import { ScheduleStatusConfig } from 'app/constants/ScheduleTypesConstants';

function ScheduleStatusTag({ status }) {
  const { color = 'default', icon } = ScheduleStatusConfig[status] || {};

  return (
    <Tag color={color} icon={icon}>
      {status}
    </Tag>
  );
}

ScheduleStatusTag.propTypes = {
  status: Proptypes.oneOfType([Proptypes.string, Proptypes.element]).isRequired,
};

export default ScheduleStatusTag;
